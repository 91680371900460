<template>
  <footer class="footer">
    <b-container>
      <div class="d-flex">
        <div class="flex-grow-1 footer__title text-semi-bold">
          {{ $store.state.selectedOrderItems.length }}
          Item{{ $store.state.selectedOrderItems.length > 1 ? 's' : '' }} selected
        </div>
        <div>
          <b-button variant="outline-light" @click="cancelSelections"> Cancel </b-button>
          <b-button disabled variant="outline-light" class="ml-2-5" @click="handleClickRefund">
            Refund
          </b-button>
          <b-button
            variant="outline-light"
            class="ml-2-5"
            @click="() => this.$bvModal.show('jobs-status-selector-modal')"
          >
            Update Status
          </b-button>
          <b-button
            variant="outline-light"
            class="ml-2-5"
            @click="() => this.$bvModal.show('jobs-location-selector-modal')"
          >
            Assign Jobs to a Location
          </b-button>
        </div>
      </div>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'JobBatchEditFooter',
  methods: {
    cancelSelections() {
      this.$store.commit('CLEAR_SELECTED_ORDER_ITEMS');
    },
    handleClickRefund() {
      this.setSelectedRefundType('partial');
      this.$bvModal.show('jobs-refund-request-modal');
    },
  },
};
</script>

<style scoped>
.footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 72px;
  padding: 18px 0;
  background-color: white;
  box-shadow: 0 -2px 12px 0 rgba(25, 28, 33, 0.08);
  z-index: 3;
}
.footer__title {
  line-height: 36px;
  vertical-align: middle;
}
</style>
