<template>
  <div>
    <div class="table-title-wrapper">
      <h6 class="text-uppercase rd-title" v-if="showTitle">Prescriptions</h6>
      <a @click="resetPrescription" v-show="selectable && selectedPrescription">Reset</a>
    </div>
    <b-button variant="icon-text-transparent" class="mb-2 ml-2" @click="showPrescriptionModal">
      <span class="icon">
        <i class="di-create-18" />
      </span>
      <span class="text">Add prescription</span>
    </b-button>
    <ul class="list-unstyled rd-list prescription-list">
      <PrescriptionListItem
        v-for="prescription in orderedPrescriptions"
        :key="prescription.id"
        :prescription="prescription"
        :customer="customer"
        :selectable="selectable"
        :collapsible="collapsible"
        :expanded="expanded"
        :showProvider="showProvider"
        :editOnPagePrescription="editOnPagePrescription"
        :showFile="showFile"
        @edit="onEdit"
        @deleted="onDeleted"
      />
    </ul>
    <hr />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PrescriptionListItem from './PrescriptionListItem.vue';

export default {
  name: 'PrescriptionList',
  components: { PrescriptionListItem },
  props: {
    customer: Object,
    showTitle: Boolean,
    selectable: Boolean,
    collapsible: Boolean,
    expanded: Boolean,
    showProvider: Boolean,
    showFile: Boolean,
    editOnPagePrescription: Function,
  },
  computed: {
    ...mapState({
      selectedPrescription: state => state.jobsModule.selectedPrescription,
    }),
    orderedPrescriptions() {
      const prescriptions = this._.get(this.customer, 'prescriptions.data');
      return this._.orderBy(prescriptions, ['created_at'], ['desc']);
    },
  },
  created() {
    if (this.selectedPrescription) {
      const selectedPrescription = this._.find(this.orderedPrescriptions, {
        id: this.selectedPrescription.id,
      });
      this.$store.commit('jobsModule/SET_SELECTED_PRESCRIPTION', selectedPrescription);
    }
  },
  methods: {
    resetPrescription() {
      this.$store.commit('jobsModule/SET_SELECTED_PRESCRIPTION', null);
      this.$store.commit('jobsModule/SET_SELECTED_PRESCRIPTION_TYPE', null);
    },
    onEdit(e) {
      this.$emit('edit', {
        customer: {
          id: this.customer.id,
          full_name: this.customer.full_name,
        },
        prescription: e.prescription,
      });
    },
    showPrescriptionModal() {
      this.$emit('new', {
        customer: {
          id: this.customer.id,
          full_name: this.customer.full_name,
        },
      });
    },
    onDeleted(id) {
      this.$root.$emit('prescriptionListMutated');
      this.$emit('deleted', id);
    },
  },
};
</script>
