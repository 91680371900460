const initialState = {
  commentModalTitle: '',
  fetchCommentsCallback: null,
  fetchOrderCallback: null,
  removeCommentCallback: null,
  createCommentsCallback: null,
  commentModalParent: '',
  isOnlineOrder: false,
  selectedUpdateNote: null,
  selectedNoteOrder: null,
};

const mutations = {
  SHOW_COMMENT_MODAL(state, payload) {
    state.commentModalTitle = payload.title;
    state.commentModalParent = payload.parent;
    state.createCommentsCallback = payload.createCommentsCallback;
    state.fetchCommentsCallback = payload.fetchCommentsCallback;
    state.fetchOrderCallback = payload.fetchOrderCallback;
    state.removeCommentCallback = payload.removeCommentCallback;
    state.isOnlineOrder = payload.isOnlineOrder;
    payload.vm.$bvModal.show('comments-modal');
  },
  SET_SELECTED_UPDATE_NOTE: (state, payload) => {
    state.selectedUpdateNote = payload;
  },
  SET_SELECTED_NOTE_ORDER: (state, payload) => {
    state.selectedNoteOrder = payload;
  },
};

const actions = {};

const moduleGetters = {};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
