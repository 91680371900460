<template>
  <div>
    <div class="rd-deep-1 expanded">
      <b-container fluid>
        <b-row>
          <b-col cols="4">
            <div class="rdd1-part--with-icon">
              <div class="rdd1-part rdd1-part__icon" v-if="collapsible">
                <b-button
                  variant="icon"
                  @click="visible = !visible"
                  class="btn-icon--collapse"
                  :class="visible ? '' : 'collapsed'"
                >
                  <i class="di-arrow-down-12 when-closed"></i>
                  <i class="di-arrow-up-12 when-opened"></i>
                </b-button>
              </div>
              <div class="rdd1-part__content">
                <div class="font-weight-medium">Profile</div>
                <div>
                  <small class="text-muted">
                    {{ customerDetails.created_at | moment('from', 'now') }}
                  </small>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-collapse :visible="visible || !collapsible">
      <div class="rd-deep-2">
        <div v-if="isLoading" class="mb-3 text-center">
          <b-spinner />
        </div>
        <b-container v-else fluid>
          <b-row class="center-block">
            <b-col cols="6" v-show="customerDetails.email">
              <b-row>
                <b-col cols="3">
                  <span class="text-medium mr-3">Email</span>
                </b-col>
                <b-col cols="9">
                  {{ customerDetails.email }}
                </b-col>
              </b-row>
            </b-col>
            <b-col offset="1" cols="5" v-show="customerDetails.mobile">
              <b-row>
                <b-col cols="4">
                  <span class="text-medium mr-3">Mobile</span>
                </b-col>
                <b-col cols="8">
                  {{ customerDetails.mobile }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="6" v-show="customerDetails.dob">
              <b-row>
                <b-col cols="3">
                  <span class="text-medium mr-3">D.O.B.</span>
                </b-col>
                <b-col cols="9">
                  {{ customerDetails.dob | moment('DD MMM YYYY') }} ({{
                    $moment().diff(customerDetails.dob, 'years')
                  }})
                </b-col>
              </b-row>
            </b-col>
            <b-col offset="1" cols="5" v-show="customerDetails.gender">
              <b-row>
                <b-col cols="4">
                  <span class="text-medium mr-3">Gender</span>
                </b-col>
                <b-col cols="8">
                  {{ customerDetails.gender }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2" v-show="customerDetails.phone">
            <b-col cols="10">
              <b-row>
                <b-col cols="2">
                  <span class="text-medium mr-3">Phone</span>
                </b-col>
                <b-col cols="10">
                  {{ customerDetails.phone }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2" v-show="customerDetails.occupation">
            <b-col cols="9">
              <b-row>
                <b-col cols="2">
                  <span class="text-medium">Occupation</span>
                </b-col>
                <b-col cols="10">
                  {{ customerDetails.occupation }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="9">
              <b-row>
                <b-col cols="2">
                  <span class="text-medium mr-3">Marketing</span>
                </b-col>
                <b-col cols="10">
                  {{ marketingStatus }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="12">
              <div class="rdd2__footer d-flex flex-row justify-content-end">
                <b-button variant="icon-text-transparent" @click="editCustomer">
                  <span class="icon mr-2">
                    <i class="di-profile-18" />
                  </span>
                  <span class="text">Edit</span>
                </b-button>
                <b-button
                  :disabled="$wait.is(`${customerDetails.email}-login-as-customer`)"
                  variant="icon-text-transparent ml-4"
                  @click="loginAsCustomer"
                >
                  <span class="icon mr-2">
                    <i class="di-arrow-right-12" />
                  </span>
                  <span class="text">
                    <b-spinner
                      small
                      v-if="$wait.is(`${customerDetails.email}-login-as-customer`)"
                      class="mr-2"
                    />
                    Login to e-commerce as customer
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import apiCustomers from '../api/customers';

export default {
  name: 'CustomersProfileMeili',
  props: {
    customer: Object,
    collapsible: Boolean,
    expanded: Boolean,
  },
  data() {
    return {
      visible: this.expanded,
      customerDetails: {},
      isLoading: true,
    };
  },
  computed: {
    marketingStatus() {
      let status = '';
      switch (this.customerDetails.new_opt_in) {
        case 'ALL':
          status = 'All Marketing & Health Issues';
          break;
        case 'HEALTH':
          status = 'Only Health Issues';
          break;
        case 'NONE':
          status = 'None';
          break;
        default:
          status = '';
          break;
      }
      return status;
    },
  },
  mounted() {
    this.isLoading = true;
    apiCustomers
      .getCustomer(this.customer.id)
      .then(response => {
        this.customerDetails = response.data.data;
        this.$emit('setCustomerDetais', this.customerDetails);
      })
      .catch(({ response }) => {
        this.$store.dispatch('showErrorAlert', response.data.error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    onDeleted(e) {
      this.$emit('deleted', e);
    },
    ...mapMutations('customer', {
      setSelectedCustomer: 'SET_SELECTED_CUSTOMER',
    }),
    editCustomer() {
      this.setSelectedCustomer(this.customerDetails);
      this.$bvModal.show('new-customer-modal');
    },
    async loginAsCustomer() {
      try {
        this.$wait.start(`${this.customerDetails.email}-login-as-customer`);
        const redirectURL = await apiCustomers.generateECommerceLoginURL(this.customerDetails.id);
        window.open(redirectURL, '_blank');
        this.$store.dispatch(
          'showSuccessAlert',
          'Authorization link is generated successfully, you will redirect soon...',
        );
      } catch {
        this.$store.dispatch(
          'showErrorAlert',
          'Failed to create token; this customer may not have an e-commerce account.',
        );
      } finally {
        this.$wait.end(`${this.customerDetails.email}-login-as-customer`);
      }
    },
  },
};
</script>
