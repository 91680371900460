const isExpired = expiry => {
  return !!expiry && Date.now() >= expiry;
};
const storeKey = 'go';
export default {
  get(key) {
    const collection = JSON.parse(localStorage.getItem(storeKey) || '{}');
    if (!collection[key]) {
      return false;
    }

    const { value, expiry } = collection[key];
    return !isExpired(expiry) && value;
  },
  set(key, value, timeout) {
    const collection = JSON.parse(localStorage.getItem(storeKey) || '{}');
    const item = {
      value,
      expiry: timeout || undefined,
    };

    localStorage.setItem(storeKey, JSON.stringify({ ...collection, [key]: item }));
  },
  del(key) {
    const collection = JSON.parse(localStorage.getItem(storeKey) || '{}');

    if (collection[key]) {
      delete collection[key];
      localStorage.setItem(storeKey, JSON.stringify(collection));
    }
  },
  clear() {
    localStorage.removeItem(storeKey);
  },
};
