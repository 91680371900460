<template>
  <div :class="{ 'glasses-group': isGlassesGroup }" class="order-group">
    <h6 class="text-uppercase order-group-heading">{{ group.name }}</h6>
    <ul class="list-unstyled rd-list">
      <li v-for="orderItem in group.order_items" :key="orderItem.id">
        <OrderItemListDetail
          :order="order"
          :item="orderItem"
          :orderStatus="order.dispense_status_id"
          :invoice="order.vend_invoice_number"
        >
        </OrderItemListDetail>
      </li>
    </ul>
    <b-row v-if="isGlassesGroup">
      <b-col>
        <div class="group-actions" v-if="!isDraft">
          <b-button
            :disabled="!isValidForOrderLenses"
            @click="openOrderLensModal"
            class="btn-white-outline-light mr-3"
            v-if="this.prescription && !isOnlyFilePrescription"
          >
            Order Lenses
            <b-spinner v-if="isOrderLensesLoading" small />
          </b-button>
          <b-button
            v-if="isOnlineOrder || hasSelections"
            variant="outline-secondary"
            class="mr-3"
            @click="editSelectionModal()"
          >
            Edit Selections
          </b-button>
          <b-button v-else variant="primary" class="mr-3" @click="openEditJobModal(1)">
            Confirm Selections
          </b-button>

          <b-button
            @click="cancelShamirOrder"
            class="btn-white-outline-light mr-3"
            v-if="this.shamirCancel"
          >
            Cancel Lens Order
            <b-spinner v-if="isShamirCancelLoading" small />
          </b-button>

          <b-button
            @click="cancelHoyaOrder"
            class="btn-white-outline-light mr-3"
            v-if="this.isHoyaOrderCancellable"
          >
            Cancel Hoya Lens Order
            <b-spinner v-if="isHoyaCancelLoading" small />
          </b-button>

          <ErrorMessage
            type="tooltip"
            class="mt-1"
            v-if="!isValidForOrderLenses && this.prescription"
          >
            No lenses match this prescription
            <div>
              {{ lensMatchWarning }}
            </div>
            <div>
              {{ lensDiameterWarning }}
            </div>
          </ErrorMessage>

          <div class="mt-2 rd-deep-1" v-if="!checkHoyaOrderStatus">
            To be able to reorder the lenses, please cancel the existing order first by contacting
            Hoya at
            <a href="mailto:hapl_platinum@hoya.com"> hapl_platinum@hoya.com </a>
          </div>

          <div class="mt-2 rd-deep-1" v-if="!checkShamirOrderStatus">
            To be able to reorder the lenses, please cancel the order first
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ErrorMessage from '@/components/ErrorMessage.vue';
import apiOrders from '@/api/orders';
import apiOutlets from '@/api/outlets';
import OrderItemListDetail from './OrderItemListDetail.vue';

export default {
  name: 'OrderItemGroup',
  components: {
    OrderItemListDetail,
    ErrorMessage,
  },
  props: {
    order: Object,
    group: Object,
  },
  data() {
    return {
      isOrderLensesLoading: false,
      isLabelLoading: false,
      lensMatchWarning: null,
      lensDiameterWarning: null,
      checkHoyaOrderStatus: true,
      checkShamirOrderStatus: true,
      isShamirCancelLoading: false,
      isHoyaCancelLoading: false,
      orderableStatusShamir: [-1, 12],
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.auth.user,
    }),
    isGlassesGroup() {
      return this.group.type === 'set';
    },
    isHoyaOrderCancellable() {
      if (!this.glasses?.hoya_status || this.glasses?.hoya_status.length === 0) {
        return false;
      }

      return this.glasses.hoya_status.is_cancellable;
    },
    shamirCancel() {
      const shamir_status = this._.get(this.glasses, 'shamir_status');
      const canceledStatusIDs = [0, 1, 2, 3, 100, 103];
      if (
        shamir_status &&
        shamir_status.length > 0 &&
        canceledStatusIDs.includes(shamir_status[0].statusID)
      ) {
        return true;
      }

      return false;
    },
    glasses() {
      const glassesKey = ['glasses', 'lenses-only'];
      return this.group.order_items.find(item => {
        const typeKey = this._.get(item, 'region_product.product.type.parent_type.key');
        return glassesKey.includes(typeKey);
      });
    },
    frameSet() {
      if (this.group.name === 'Others' && this.group.type === 'set') {
        return this.group.order_items;
      }
      return null;
    },
    pd() {
      return this._.get(this.glasses, 'additional_info.data.pd.detail');
    },
    pdPresenter() {
      return this._.get(this.glasses, 'additional_info.pdPresenter');
    },
    prescription() {
      return this._.get(this.glasses, 'additional_info.data.prescription');
    },
    isOnlyFilePrescription() {
      const standardDisplay = this._.get(
        this.prescription,
        'detail.standardised_prescription_display_array',
      );
      if (
        standardDisplay &&
        standardDisplay.left === 'L Plano DS' &&
        standardDisplay.right === 'R Plano DS' &&
        (this.prescription.add_int === null || this.prescription.add_int === 0) &&
        (this.prescription.add_near === null || this.prescription.add_near === 0) &&
        (this.prescription.left_int_add === null || this.prescription.left_int_add === 0) &&
        (this.prescription.right_int_add === null || this.prescription.right_int_add === 0) &&
        (this.prescription.right_near_add === null || this.prescription.right_near_add === 0) &&
        (this.prescription.left_near_add === null || this.prescription.left_near_add === 0) &&
        this.prescription.files &&
        this.prescription.files.length > 0
      ) {
        return true;
      }
      return false;
    },
    selectedLensDiameters() {
      let diameters = this.glasses.region_product.lens_addon_combination.find(
        l => l.id === this.glasses.additional_info.data.lens_addon_combination.id,
      );

      if (typeof diameters === 'undefined') {
        diameters = this.glasses.additional_info.data.lens_addon_combination.lenses.lens_diameter;
      } else {
        diameters = diameters.lenses.lens_diameter;
      }

      if (!diameters || diameters.length <= 1) return diameters;

      const matchedDiameters = diameters.filter(
        d =>
          (this.prescription.detail.left_sph > d.greater_than &&
            this.prescription.detail.left_sph < d.lower_than) ||
          (this.prescription.detail.right_sph > d.greater_than &&
            this.prescription.detail.right_sph < d.lower_than),
      );

      return matchedDiameters;
    },
    isOnlineOrder() {
      return this.order.channel_id === 4;
    },
    lenses() {
      return this._.get(this.glasses, 'additional_info.data.lens_addon_combination.lenses');
    },
    frameSizeId() {
      return this._.get(this.frame, 'sizeId');
    },
    isValidForOrderLenses() {
      const pdHasError = this._.get(this.pdPresenter, 'error');
      const isPdValid = this.pdPresenter && !pdHasError;

      return (
        isPdValid &&
        !!this.lenses &&
        !!this.prescription &&
        !!this.frameSizeId &&
        this.isDiameterValid()
      );
    },
    frame() {
      return this.getSizeAndColor('frame');
    },
    leftArm() {
      return this.getSizeAndColor('leftArm');
    },
    rightArm() {
      return this.getSizeAndColor('rightArm');
    },
    productName() {
      const productName = this._.get(this.glasses, 'region_product.product.name', '');
      const segments = productName.split('/');
      return this._.get(segments, '0').trim();
    },
    productOptions() {
      const productName = this._.get(this.glasses, 'region_product.product.name', '');
      const segments = productName.split('/');
      return segments.slice(1).join('/');
    },
    isPrescriptionRequired() {
      return this.glasses?.region_product?.requires_prescription;
    },
    hasSelections() {
      if (this.isPrescriptionRequired) {
        return !!(this.prescription && this.pd && this.lenses);
      }
      return !!(this.frame && this.leftArm && this.rightArm);
    },
    hasItem() {
      return this.group.order_items.some(i => i.quantity > -1);
    },
    isDraft() {
      const payment_status = this._.get(this.order, 'payment_status_id');
      if (payment_status === 'parked') {
        return true;
      }
      return false;
    },
  },
  methods: {
    editSelectionModal() {
      if (
        this.glasses.shamir_status.length > 0 &&
        !this.orderableStatusShamir.includes(this.glasses.shamir_status[0].statusID)
      ) {
        this.checkShamirOrderStatus = false;
        this.isOrderLensesLoading = false;
        return;
      }

      this.openEditJobModal(5);
    },

    async openOrderLensModal() {
      this.isOrderLensesLoading = true;

      if (
        Object.keys(this.glasses.hoya_status).length > 0 &&
        Number(this._.get(this.glasses.hoya_status, 'orderStatus')) >= 0 &&
        Number(this._.get(this.glasses.hoya_status, 'orderStatus')) < 9
      ) {
        const {
          data: {
            data: { orderStatus },
          },
        } = await apiOrders.getOrderHoyaStatus(this.glasses.id);

        if (Number(orderStatus) >= 0 && Number(orderStatus) < 9) {
          this.isOrderLensesLoading = false;
          this.checkHoyaOrderStatus = false;
          return;
        }
      }

      try {
        const {
          data: { data: supplier },
        } = await apiOrders.getSupplier(
          this.glasses.additional_info.data.lens_addon_combination.lenses.supplier_id,
          this._.get(this.glasses, 'id'),
        );

        const {
          data: {
            data: { count },
          },
        } = await apiOrders.getOrderLensCount(
          this.glasses.order_id,
          this.glasses.id,
          this.glasses.region_product.product_id,
          supplier.id,
        );

        const {
          data: {
            data: { supplier_contacts: contacts },
          },
        } = await apiOutlets.getOutlet(this.currentUser.outlet.id);

        if (
          this.glasses.shamir_status.length > 0 &&
          !this.orderableStatusShamir.includes(this.glasses.shamir_status[0].statusID)
        ) {
          this.checkShamirOrderStatus = false;
          this.isOrderLensesLoading = false;
          return;
        }

        this.$store.commit('orderLensModal/SHOW_ORDER_LENS_MODAL', {
          order: this.order,
          item: this.glasses,
          customer: this.order.customer,
          invoiceNumber: this.order.vend_invoice_number,
          supplier,
          count,
          defaultContact: contacts[0],
          frameSizeId: this.frameSizeId,
          vm: this,
        });
      } catch (error) {
        this.$store.dispatch('showErrorAlert', error.response.data.message);
      }

      this.isOrderLensesLoading = false;
    },
    openEditJobModal(stepNumber) {
      this.$store.dispatch('jobsModule/resetJobEditModule');
      const { lenses } = this;

      if (lenses) {
        lenses.id = this._.get(this.glasses, 'additional_info.data.lens_addon_combination.id');
      }

      this.$store.commit('jobsModule/SET_SELECTED_JOB', {
        frame: this.frame,
        leftArm: this.leftArm,
        rightArm: this.rightArm,
        prescription: this.prescription,
        pd: {
          detail: this.pd,
          pdPresenter: this.pdPresenter,
        },
        lenses,
        item: this.glasses,
        order: this.order,
        productName: this.productName,
        productOptions: this.productOptions,
      });

      this.$nextTick(() => {
        this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', stepNumber);
      });
    },
    cancelHoyaOrder() {
      if (
        !this.glasses?.hoya_status ||
        this.glasses?.hoya_status.length === 0 ||
        !this.isHoyaOrderCancellable ||
        !this.glasses?.hoya_status.orderId
      ) {
        return false;
      }

      const confirmationMessage = `Are you sure you want to cancel the Hoya order?`;
      const confirmationOptions = {
        title: 'Please confirm',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelVariant: 'outline-secondary',
        cancelTitle: 'No',
        hideHeaderClose: false,
        centered: true,
        headerClass: 'border-bottom p-3',
        bodyClass: 'px-4 py-2',
        footerClass: 'bg-white py-2 justify-content-center',
      };

      this.$bvModal.msgBoxConfirm(confirmationMessage, confirmationOptions).then(isConfirmed => {
        if (isConfirmed) {
          this.isHoyaCancelLoading = true;
          apiOrders
            .cancelHoyaOrder(this.glasses?.hoya_status.orderId)
            .then(() => {
              this.isHoyaCancelLoading = false;
              this.$store.dispatch('showSuccessAlert', 'Hoya order cancelled successfully');
              this.$root.$emit('reloadExpandedJob', this.glasses.order_id);
            })
            .catch(error => {
              this.isHoyaCancelLoading = false;
              this.$store.dispatch('showErrorAlert', error.response.data.error);
            });
        }
      });
    },
    cancelShamirOrder() {
      const confirmationMessage = `Are you sure you want to cancel the Shamir order?`;
      const confirmationOptions = {
        title: 'Please confirm',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelVariant: 'outline-secondary',
        cancelTitle: 'No',
        hideHeaderClose: false,
        centered: true,
        headerClass: 'border-bottom p-3',
        bodyClass: 'px-4 py-2',
        footerClass: 'bg-white py-2 justify-content-center',
      };

      this.$bvModal.msgBoxConfirm(confirmationMessage, confirmationOptions).then(isConfirmed => {
        if (isConfirmed) {
          this.isShamirCancelLoading = true;
          apiOrders
            .cancelShamirOrder(this.glasses.id)
            .then(() => {
              this.isShamirCancelLoading = false;
              this.$root.$emit('reloadExpandedJob', this.glasses.order_id);
            })
            .catch(() => {
              this.isShamirCancelLoading = false;
              this.$store.dispatch('showErrorAlert', 'Error cancelling order');
            });
        }
      });
    },
    getSizeAndColor(type) {
      const types = {
        frame: {
          component: 'Frame',
          color: 'frame_colour',
          size: 'frame_size',
        },
        leftArm: {
          component: 'Temple Left',
          color: 'arm_colour',
          size: 'arm_size',
        },
        rightArm: {
          component: 'Temple Right',
          color: 'arm_colour',
          size: 'arm_size',
        },
      };

      if (this.frameSet) {
        let frameData;
        if (type === 'frame') {
          frameData = this.frameSet.filter(item => {
            return item.region_product.product.name.includes('Frame');
          });
        } else if (type === 'leftArm' || type === 'rightArm') {
          frameData = this.frameSet.filter(item =>
            item.region_product.product.name.includes('Arms'),
          );
        }

        const attributes = frameData[0].region_product.product;

        return {
          color: attributes.attribute[types[type].color],
          sizeId: attributes.attribute[types[type].size],
        };
      }
      return this._.get(
        this.glasses,
        `additional_info.data.customize.component.${types[type].component}`,
      );
    },
    isDiameterValid() {
      this.lensDiameterWarning = '';
      if (!this.selectedLensDiameters || this.selectedLensDiameters.length === 0) return true;
      const diameterValues = this.selectedLensDiameters.map(d => d.value);
      const pdValue =
        this.pd.binocular_dist_pd ||
        this.pd.left_monocular_dist_pd + this.pd.right_monocular_dist_pd;

      // eslint-disable-next-line no-restricted-syntax
      for (const value of diameterValues) {
        switch (this.frameSizeId) {
          case 'm':
            if (value === 70 && pdValue < 58) {
              this.lensDiameterWarning =
                '70mm lens and medium frame size combinations require minimum 58mm pd';
            }
            if (value === 65 && pdValue < 61) {
              this.lensDiameterWarning =
                '65mm lens and medium frame size combinations require minimum 61mm pd';
            }
            if (value === 75 && pdValue < 53) {
              this.lensDiameterWarning =
                '75mm lens and medium frame size combinations require minimum 53mm pd';
            }
            break;
          case 'l':
            if (value === 70 && pdValue < 64) {
              this.lensDiameterWarning =
                '70mm lens and medium frame size combinations require minimum 64mm pd';
            }
            if (value === 65 && pdValue < 69) {
              this.lensDiameterWarning =
                '70mm lens and medium frame size combinations require minimum 69mm pd';
            }
            if (value === 75 && pdValue < 59) {
              this.lensDiameterWarning =
                '75mm lens and medium frame size combinations require minimum 59mm pd';
            }
            break;
          default:
            break;
        }
      }
      return !this.lensDiameterWarning;
    },
  },
  created() {
    if (!this.frameSizeId) {
      this.lensMatchWarning = 'Frame size required';
    } else {
      const pdHasError = this._.get(this.pdPresenter, 'error');

      if (pdHasError) {
        this.lensMatchWarning = this.pdPresenter.message;
      }
    }
  },
};
</script>
