<template>
  <b-modal
    id="file-modal"
    v-model="showModal"
    size="lg"
    modal-class="modal-file"
    header-class="header"
    footer-class="footer"
    centered
    @hidden="handleHidden"
  >
    <template #modal-title>
      <span class="file-name">
        {{ selectedFile.file_name }}
      </span>
      <span class="file-date text-muted">
        {{ (selectedFile.updated_at || selectedFile.created_at) | moment('from', 'now') }}
      </span>
    </template>
    <template #default>
      <div class="image-container" v-if="isImage(selectedFile)">
        <vue-magnifier :src="selectedFile.full_path" :srcLarge="selectedFile.full_path" />
        <div class="my-3">{{ selectedFile.caption }}</div>
      </div>
      <div class="pdf-container" v-if="isPDF(selectedFile)">
        <iframe
          :src="selectedFile.full_path + '#view=FitH'"
          frameBorder="0"
          scrolling="auto"
          height="100%"
          width="100%"
        ></iframe>
        <div class="my-3">{{ selectedFile.caption }}</div>
      </div>
    </template>
    <template #modal-footer>
      <a
        :href="selectedFile.full_path"
        :download="selectedFile.file_name"
        ref="downloadLink"
        target="_blank"
        class="d-none"
      />
      <b-button v-if="editOnPagePrescription" variant="outline-secondary" @click="editPrescription"
        >Edit Prescription</b-button
      >
      <b-button variant="outline-secondary" @click="downloadFile"> Download </b-button>
      <b-button variant="danger" @click="removeConfirm" :disabled="isLoading">
        <b-spinner class="text-center" v-if="isLoading" small />
        <span v-else>Remove File</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import apiFiles from '@/api/files';
import VueMagnifier from '@/components/vue-magnifier.vue';

export default {
  data() {
    return {
      showModal: false,
      isLoading: false,
      defaultPdfThumbnail: require('../../assets/document.png'), // eslint-disable-line
    };
  },
  components: {
    VueMagnifier,
  },
  props: {
    editOnPagePrescription: Function,
    prescription: Object,
  },
  computed: {
    ...mapState({
      selectedFile: state => state.selectedFile,
      selectedPrescription: state => state.selectedPrescription,
      selectedCustomer: state => state.customer.selectedCustomer,
    }),
  },
  methods: {
    ...mapMutations({
      setSelectedPrescription: 'SET_SELECTED_PRESCRIPTION',
      setSelectedCustomer: 'customer/SET_SELECTED_CUSTOMER',
      removeCustomerFile: 'customer/REMOVE_CUSTOMER_FILE',
      removePrescriptionFile: 'customer/REMOVE_PRESCRIPTION_FILE',
    }),
    isImage(file) {
      return ['jpg', 'jpeg', 'png', 'bmp'].includes(file.file_name.split('.').pop().toLowerCase());
    },
    isPDF(file) {
      return ['pdf'].includes(file.file_name.split('.').pop().toLowerCase());
    },
    downloadFile() {
      this.$refs.downloadLink.click();
    },
    removeConfirm() {
      this.$bvModal
        .msgBoxConfirm(`Are you sure to remove ${this.selectedFile.file_name}?`, {
          title: 'Please confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelVariant: 'outline-secondary',
          cancelTitle: 'No',
          hideHeaderClose: false,
          centered: true,
          headerClass: 'border-bottom p-3',
          bodyClass: 'px-4 py-2',
          footerClass: 'bg-white py-2 justify-content-center',
        })
        .then(isConfirmed => {
          if (isConfirmed) {
            this.isLoading = true;
            apiFiles
              .deleteFile(this.selectedFile.id)
              .then(() => {
                this.$store.dispatch('showSuccessAlert', 'File successfully removed!');
                this.removeFile(this.selectedFile.id);
                this.showModal = false;
              })
              .catch(error => {
                this.$store.dispatch('showErrorAlert', error.response.data.error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
    },
    // TODO: this will refactor after customer files endpoint completed
    removeFile(fileId) {
      // if come from prescription card
      if (this.selectedPrescription) {
        this.removePrescriptionFile({ prescription: this.selectedPrescription, fileId });
        return;
      }

      // if come from customer profile files section
      if (this.selectedCustomer) {
        const isCustomerFile = this.selectedCustomer.files.some(f => f.id === fileId);
        // if current file belongs to customer
        if (isCustomerFile) {
          this.removeCustomerFile({ customer: this.selectedCustomer, fileId });
        } else {
          // if current file belongs to customer prescription
          const prescription = this.selectedCustomer.prescriptions.data.find(p =>
            p.files.find(f => f.id === fileId),
          );

          if (prescription) {
            this.removePrescriptionFile({ prescription, fileId });
          }
        }
      }
    },
    handleHidden() {
      this.setSelectedPrescription(null);
      this.setSelectedCustomer(null);
      this.showModal = false;
      this.$bvModal.hide('file-modal');
    },
    editPrescription() {
      this.editOnPagePrescription(this.selectedPrescription.id);
    },
  },
};
</script>

<style>
.pdf-container {
  height: 100vh;
}
</style>
