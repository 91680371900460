<template>
  <b-modal id="print-modal" size="xl">
    <template v-slot:modal-header="{ close }">
      <b-container>
        <div class="modal-title">
          <h3 class="text-bold text-center">Print Labels</h3>
          <button type="button" aria-label="Close" class="close" @click="close">
            <i class="di-remove-10"></i>
          </button>
        </div>
      </b-container>
    </template>
    <div>
      <div class="container">
        <h5 class="mb-4">Available Labels</h5>
        <section>
          <div class="info-card-header">
            <b-row class="align-items-center" style="height: 100%">
              <b-col cols="6">
                <span class="info-card-label">Job Tray Label</span>
              </b-col>
              <b-col cols="6">
                <div class="info-card-action">
                  <!--TODO integrate this-->
                  <span>Created a month ago</span>
                  <button class="btn btn-primary ml-3" @click="print()">Print</button>
                </div>
              </b-col>
            </b-row>
          </div>
        </section>
      </div>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <div class="flex-fill m-0">
        <div class="container">
          <div class="d-flex">
            <b-button class="mr-3" variant="outline-light" @click="cancel()">Close</b-button>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'PrintModal',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      selectedOrderId: state => state.selectedOrderId,
    }),
  },
  methods: {
    print() {
      axios
        .get(`${this.newAppApiUrl}/staff/job-tray-signed-url/${this.selectedOrderId}`)
        .then(({ data: signedUrl }) => {
          window.open(signedUrl, '_blank');
        });
    },
  },
};
</script>
