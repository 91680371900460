<template>
  <div class="d-flex order-item">
    <div id="order-item-detail" class="flex-grow-1">
      <div class="rd-deep-1">
        <b-container fluid>
          <b-row>
            <b-col cols="4" class="d-flex align-items-center">
              <div class="rdd1-part__content d-flex align-items-center">
                <div class="font-weight-medium">Shipment Status</div>
              </div>
            </b-col>
            <b-col cols="3" class="d-flex align-items-center">
              <b-dropdown
                class="status-dropdown"
                menu-class="dropdown-menu--status"
                toggle-class="btn--badge-pill"
                no-caret
              >
                <template v-slot:button-content> @ {{ order.shipment_company }} </template>
              </b-dropdown>
            </b-col>
            <b-col cols="3" class="d-flex align-items-center">
              <b-dropdown
                class="status-dropdown"
                menu-class="dropdown-menu--status"
                toggle-class="btn--badge-pill"
                no-caret
                :variant="getStatusVariant(order.shipment_status)"
              >
                <template v-slot:button-content>
                  {{ shipmentStatus }}
                </template>
              </b-dropdown>
            </b-col>
            <b-col cols="2" class="d-flex align-items-center">
              <b-button
                variant="info"
                @click="getShipmentLabel"
                v-if="order.shipment_tracking_id"
                size="sm"
              >
                <span>Get Label</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderItemShipmentDetail',
  props: {
    order: Object,
  },
  computed: {
    shipmentStatus() {
      if (this.order.shipment_status === 'not_created') {
        return 'Not Created';
      }
      return 'Created';
    },
  },
  methods: {
    async getShipmentLabel() {
      if (this.order.shipment?.label_pdf_url) {
        window.open(this.order.shipment?.label_pdf_url, '_blank');
      }
    },
  },
};
</script>
