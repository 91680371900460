<template>
  <li>
    <div class="rd-deep-1 expanded">
      <b-container fluid>
        <b-row>
          <b-col cols="4">
            <div class="d-flex align-items-end h-100">
              <div class="font-weight-medium">{{ lens.lenses.supplier.name }}</div>
            </div>
          </b-col>
          <b-col cols="7">
            <div class="d-flex align-items-center h-100">
              <div class="font-weight-medium">{{ lens.lenses.name }}</div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="rdd1-part rdd1-part__icon rdd1-part__radio" v-if="selectable">
        <b-form-radio v-model="$store.state.jobsModule.selectedLens" :value="lens"> </b-form-radio>
      </div>
    </div>
    <div class="rd-deep-2">
      <b-container fluid>
        <b-row>
          <b-col cols="4">
            <h6 class="text-uppercase">Thickness</h6>
            <div>{{ lens.lenses.display_thickness }}</div>
          </b-col>
          <b-col cols="4">
            <h6 class="text-uppercase">Lens Coating(s)</h6>
            <div>{{ lens.lenses.coating }}</div>
            <div v-if="lens.coating">
              <hr />
              {{ lens.coating.display_name }}
            </div>
          </b-col>

          <b-col cols="4" v-if="lens.lenses.lens_color">
            <h6 class="text-uppercase">Color</h6>
            <div>{{ lens.lenses.lens_color.name }}</div>
          </b-col>

          <b-col cols="4" v-if="lens.tint">
            <h6 class="text-uppercase">Tint</h6>
            <div>{{ lens.tint.display_name }}</div>
          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="lens.invalid_error_msg">
          <b-col cols="12">
            <span
              class="text-danger text-decoration-underline font-weight-bold"
              style="text-decoration: underline"
            >
              Warning
            </span>
            <p class="small text-danger">{{ lens.invalid_error_msg }}</p>
          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="checkWarningMessage">
          <b-col cols="12">
            <p class="warning mr-2 mt-3">{{ lens.lenses.warning_message }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </li>
</template>

<script>
export default {
  name: 'LensListItem',
  props: {
    lens: Object,
    selectable: Boolean,
    collapsible: Boolean,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    checkWarningMessage() {
      return this._.get(this.lens, 'lenses.warning_message');
    },
  },
};
</script>
