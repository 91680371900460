import axios from 'axios';

export default {
  getMe() {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/me`);
  },
  putMe(data) {
    return axios.put(`user/${data.id}`, data);
  },
  resetPassword(data) {
    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/auth/password/reset`, data);
  },
  updatePassword(data) {
    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/auth/password/change`, data);
  },
};
