<template>
  <div class="row--6px">
    <b-col cols="8">
      <b-form-group :label="label">
        <b-form-input
          name="right_horiz_near_prism"
          type="number"
          v-model="editableValue"
          @keyup="onChange"
          v-disable-increment
        />
      </b-form-group>
    </b-col>
    <b-col cols="4">
      <b-form-checkbox
        switch
        class="no-label"
        v-model="sign"
        :value="-1"
        :unchecked-value="1"
        @change="onChange"
      >
        <span v-if="sign === -1">{{ checkText }}</span>
        <span v-if="sign === 1">{{ uncheckText }}</span>
      </b-form-checkbox>
    </b-col>
  </div>
</template>

<script>
export default {
  name: 'PrismInput',
  data() {
    return {
      editableValue: Math.abs(this.value) || null,
      sign: this.value < 0 ? -1 : 1,
    };
  },
  props: {
    label: String,
    value: Number,
    checkText: String,
    uncheckText: String,
  },
  methods: {
    onChange() {
      this.$nextTick(() => {
        this.$emit('input', this.editableValue * this.sign);
      });
    },
  },
  watch: {
    value() {
      this.editableValue = Math.abs(this.value);
      this.sign = this.value < 0 ? -1 : 1;
    },
  },
  directives: {
    disableIncrement: {
      bind(el) {
        // Disable mouse wheel increment/decrement
        el.addEventListener('wheel', e => {
          e.preventDefault();
        });

        // Disable arrow key increment/decrement
        el.addEventListener('keydown', e => {
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
          }
        });
      },
    },
  },
};
</script>
