<template>
  <ValidationObserver v-slot="{ passes }">
    <b-modal
      id="new-pd-modal"
      size="xl"
      ref="newPdModal"
      @ok="$emit('ok')"
      @show="onShow"
      @hide="onHide"
    >
      <template v-slot:modal-header="{ close }">
        <b-container>
          <div class="modal-title">
            <h3 class="text-bold text-center">{{ isEdit ? 'Edit' : 'New' }} PD</h3>
            <h6 class="text-uppercase text-center">{{ customer && customer.full_name }}</h6>
            <button type="button" aria-label="Close" class="close" @click="close">
              <i class="di-remove-10" />
            </button>
          </div>
        </b-container>
      </template>
      <div class="container">
        <ul class="text-danger" v-if="errors.length">
          <li v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
        </ul>
        <h5 class="mb-4">PD details</h5>
        <b-form>
          <b-form-group label="Show fields" label-for="show-fields" class="flex-fill">
            <b-form-radio-group
              id="show-fields"
              v-model="complex"
              :options="showFields"
              buttons
              button-variant="white"
              class="custom-radio-button"
            />
          </b-form-group>
          <div class="divider mt-2 mb-4"></div>
          <div v-if="complex">
            <h6 class="text-uppercase mb-3">Binocular</h6>
            <b-row>
              <b-col cols="4">
                <ValidationProvider
                  vid="binocular_distance_complex"
                  name="Distance"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Distance" label-for="binocular-distance">
                    <b-form-input
                      id="binocular-distance"
                      v-model="form.binocular_dist_pd"
                      type="number"
                      :autofocus="complex"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="binocular_intermediate"
                  name="Intermediate"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Intermediate" label-for="binocular-intermediate">
                    <b-form-input
                      id="binocular-intermediate"
                      v-model="form.binocular_int_pd"
                      type="number"
                      min="10"
                      max="99"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="binocular_reading"
                  name="Reading"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Reading" label-for="binocular-reading">
                    <b-form-input
                      id="binocular-reading"
                      v-model="form.binocular_reading_pd"
                      type="number"
                      min="10"
                      max="99"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <h6 class="text-uppercase mb-3">Monocular Right</h6>
            <b-row>
              <b-col cols="4">
                <ValidationProvider
                  vid="right_monocular_distance"
                  name="Distance"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Distance" label-for="right-monocular-distance">
                    <b-form-input
                      id="right-monocular-distance"
                      v-model="form.right_monocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="right_monocular_intermediate"
                  name="Intermediate"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Intermediate" label-for="right-monocular-intermediate">
                    <b-form-input
                      id="right-monocular-intermediate"
                      v-model="form.right_monocular_int_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="right_monocular_reading"
                  name="Reading"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Reading" label-for="right-monocular-reading">
                    <b-form-input
                      id="right-monocular-reading"
                      v-model="form.right_monocular_reading_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <h6 class="text-uppercase mb-3">Monocular Left</h6>
            <b-row>
              <b-col cols="4">
                <ValidationProvider
                  vid="left_monocular_distance"
                  name="Distance"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Distance" label-for="left-monocular-distance">
                    <b-form-input
                      id="left-monocular-distance"
                      v-model="form.left_monocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="left_monocular_intermediate"
                  name="Intermediate"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Intermediate" label-for="left-monocular-intermediate">
                    <b-form-input
                      id="left-monocular-intermediate"
                      v-model="form.left_monocular_int_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="left_monocular_reading"
                  name="Reading"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:10||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Reading" label-for="left-monocular-reading">
                    <b-form-input
                      id="left-monocular-reading"
                      v-model="form.left_monocular_reading_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </div>
          <div v-if="!complex">
            <b-row>
              <b-col cols="4">
                <ValidationProvider
                  vid="binocular_distance"
                  name="Binocular distance"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:40|maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Binocular distance" label-for="binocular-distance">
                    <b-form-input
                      id="binocular-distance"
                      v-model="form.binocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :autofocus="!complex"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="right_monocular"
                  name="Right monocular"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:20|maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Right monocular" label-for="right-monocular">
                    <b-form-input
                      id="right-monocular"
                      v-model="form.right_monocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="4">
                <ValidationProvider
                  vid="left_monocular"
                  name="Left monocular"
                  mode="lazy"
                  :rules="`${isRequired ? 'required' : ''}|min_value:20||maxValue:100|halfValue`"
                  v-slot="validationContext"
                >
                  <b-form-group label="Left monocular" label-for="left-monocular">
                    <b-form-input
                      id="left-monocular"
                      v-model="form.left_monocular_dist_pd"
                      type="number"
                      @mousewheel.native="$event.preventDefault()"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </div>
          <div class="divider mt-2 mb-4"></div>
          <b-button variant="icon" v-b-toggle.height>
            <i class="di-arrow-down-12 when-closed" />
            <i class="di-arrow-up-12 when-opened" />
          </b-button>
          <h5 class="d-inline-block mb-4 ml-2">Height</h5>
          <b-collapse id="height">
            <b-row>
              <b-col cols="6">
                <h6 class="text-uppercase mb-3">Right</h6>
              </b-col>
              <b-col cols="6">
                <h6 class="text-uppercase mb-3">Left</h6>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Multifocal" label-for="right-multifocal">
                  <b-form-input
                    id="right-multifocal"
                    v-model="form.right_height_multifocal"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Multifocal" label-for="left-multifocal">
                  <b-form-input
                    id="left-multifocal"
                    v-model="form.left_height_multifocal"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Desk" label-for="right-desk">
                  <b-form-input
                    id="right-desk"
                    v-model="form.right_height_desk"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Desk" label-for="left-desk">
                  <b-form-input
                    id="left-desk"
                    v-model="form.left_height_desk"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Bifocal" label-for="right-bifocal">
                  <b-form-input
                    id="right-bifocal"
                    v-model="form.right_height_bifocal"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Bifocal" label-for="left-bifocal">
                  <b-form-input
                    id="left-bifocal"
                    v-model="form.left_height_bifocal"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Aspheric single vision" label-for="right-aspheric">
                  <b-form-input
                    id="right-aspheric"
                    v-model="form.right_height_aspheric"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Aspheric single vision" label-for="left-aspheric">
                  <b-form-input
                    id="left-aspheric"
                    v-model="form.left_height_aspheric"
                    type="number"
                    @mousewheel.native="$event.preventDefault()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-collapse>
          <div class="divider mt-2 mb-4"></div>
          <b-row>
            <b-col cols="4">
              <b-form-group label="Frame size" label-for="frame-size">
                <b-form-radio-group
                  id="frame-size"
                  v-model="form.frame_size"
                  :options="$store.state.sizes"
                  name="frame_size"
                  buttons
                  button-variant="primary"
                  class="custom-radio-tag"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Arm size" label-for="arm-size">
                <b-form-radio-group
                  id="arm-size"
                  v-model="form.arm_size"
                  :options="$store.state.sizes"
                  name="arm_size"
                  buttons
                  button-variant="primary"
                  class="custom-radio-tag"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider mb-3"></div>
          <!--        <div class="box box--info box--icon mb-4">
            <div class="box-icon">
              <i class="di-information-10"/>
            </div>
            <div class="box-header">
              <div class="box-header__title">
                <p class="text-semi-bold mb-2">Source options</p>
                <p>Quisque quis velit ex. Integer lacinia vehicula neque at dapibus.</p>
              </div>
            </div>
          </div>-->
          <ValidationProvider
            vid="source"
            name="Source"
            mode="lazy"
            rules="required"
            v-slot="validationContext"
          >
            <b-form-group label="Source" label-for="source">
              <b-form-radio-group
                id="source"
                v-model="form.source_id"
                :options="$store.getters.pdSourceOptions"
                name="source"
                buttons
                button-variant="primary"
                class="custom-radio-tag"
              />
              <b-form-invalid-feedback class="d-block">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Outlet" label-for="outlet">
                <b-form-select
                  id="outlet"
                  :options="$store.getters.outletOptions"
                  v-model="form.outlet_id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Notes" label-for="notes">
            <b-form-textarea id="notes" v-model="form.note" rows="8" />
          </b-form-group>
        </b-form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <div class="flex-fill m-0">
          <div class="container">
            <div class="d-flex justify-content-end">
              <b-button variant="outline-secondary" @click="cancel()">Cancel</b-button>
              <b-button
                variant="primary"
                @click="passes(submit)"
                class="ml-3"
                :disabled="isLoading"
              >
                <b-spinner v-if="isLoading" small />
                {{ isEdit ? 'Save' : 'Create' }} PD
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import apiPds from '../api/pds';

export default {
  name: 'NewPdModal',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    customer: Object,
    pd: Object,
    isOnPageEdit: Boolean,
    lensType: Object,
  },
  data() {
    return {
      showFields: [
        {
          value: false,
          text: 'Simple',
        },
        {
          value: true,
          text: 'Complex',
        },
      ],
      complex: false,
      form: {
        binocular_dist_pd: null,
        binocular_int_pd: null,
        binocular_reading_pd: null,
        right_monocular_dist_pd: null,
        right_monocular_int_pd: null,
        right_monocular_reading_pd: null,
        left_monocular_dist_pd: null,
        left_monocular_int_pd: null,
        left_monocular_reading_pd: null,
        right_height_multifocal: null,
        right_height_desk: null,
        right_height_bifocal: null,
        right_height_aspheric: null,
        left_height_multifocal: null,
        left_height_desk: null,
        left_height_bifocal: null,
        left_height_aspheric: null,
        frame_size: null,
        arm_size: null,
        source_id: null,
        outlet_id: this.selectedOutletId,
        note: null,
      },
      errors: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      activeJobEditStep: state => state.jobsModule.activeJobEditStep,
    }),
    ...mapState('auth', {
      selectedOutletId: state => state.selectedOutletId,
      selectedPrescriptionType: state => state.jobsModule.selectedPrescriptionType,
    }),
    ...mapGetters('jobsModule', ['isPrescriptionRequired', 'selectedPrescriptionType']),
    isEdit() {
      return this.pd && !!this.pd.id;
    },
    isRequired() {
      return !(
        this.form.binocular_dist_pd ||
        this.form.right_monocular_dist_pd ||
        this.form.left_monocular_dist_pd ||
        this.form.binocular_dist_pd ||
        this.form.binocular_int_pd ||
        this.form.binocular_reading_pd ||
        this.form.right_monocular_dist_pd ||
        this.form.right_monocular_int_pd ||
        this.form.right_monocular_reading_pd ||
        this.form.left_monocular_dist_pd ||
        this.form.left_monocular_int_pd ||
        this.form.left_monocular_reading_pd
      );
    },
  },
  methods: {
    onHide() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onShow() {
      this.$nextTick(() => {
        if (this.isEdit) {
          this.form = { ...this.pd, note: this.formatNote(this.pd.note) };
          this.complex = !!(
            this.form.binocular_int_pd ||
            this.form.binocular_reading_pd ||
            this.form.right_monocular_int_pd ||
            this.form.right_monocular_reading_pd ||
            this.form.left_monocular_int_pd ||
            this.form.left_monocular_reading_pd
          );
        } else {
          this.form.outlet_id = this.selectedOutletId;
        }
      });
    },
    formatNote(noteText) {
      if (!noteText) return '';

      return noteText.replace(/. - /g, '\r\n- ');
    },
    submit() {
      this.isLoading = true;
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.form[key] === '' ? null : this.form[key];
      });

      let lensTypeId = null;
      if (this.lensType) {
        lensTypeId = this._.get(this.lensType, 'id');
      }
      if (this.isEdit) {
        apiPds
          .putPd(this.pd.id, this.form, this.selectedPrescriptionType, lensTypeId)
          .then(response => {
            this.$nextTick(() => {
              this.$refs.newPdModal.hide('ok');
              this.$store.dispatch('showSuccessAlert', 'PD has been successfully edited!');
              this.$store.commit('jobsModule/SET_SELECTED_PD', {
                detail: response.data.data,
                pdPresenter: response.data.data.pdPresenter,
              });
              if (this.customer) {
                this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 5);
              } else {
                this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 4);
              }
              this.$root.$emit('newPdAdded');
            });
          })
          .catch(({ response }) => {
            this.errors = response.data.error;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.customer) {
          apiPds
            .postCustomerPd(this.customer.id, this.form)
            .then(() => {
              this.$nextTick(() => {
                this.$refs.newPdModal.hide('ok');
                this.$store.dispatch('showSuccessAlert', 'New PD has been successfully created!');
                this.$root.$emit('newPdAdded');
              });
            })
            .catch(({ response }) => {
              this.errors = response.data.error;
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          apiPds
            .postGuestPd(this.form, this._.get(this.lensType, 'id') || 3)
            .then(response => {
              this.$nextTick(() => {
                this.$refs.newPdModal.hide('ok');
                this.$store.dispatch('showSuccessAlert', 'New PD has been successfully created!');
                this.$root.$emit('newPdAdded');
                this.$store.commit('jobsModule/SET_SELECTED_PD', {
                  detail: response.data.data,
                  pdPresenter: response.data.data.pdPresenter,
                });
                this.$emit('pdDataSent', response.data.data);
                this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 4);
              });
            })
            .catch(({ response }) => {
              this.errors = response.data.error;
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
  },
};
</script>
