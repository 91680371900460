import Vue from 'vue';
import apiCustomer from '@/api/customers';

const initialState = {
  customers: [],
  selectedCustomer: null,
  selectedCustomerNewsletter: null,
};

const moduleGetters = {
  getCustomerFiles: state => id => {
    return state.customers.find(c => c.id === id).files || [];
  },
  getCustomerPrescriptionFiles: state => id => {
    return state.customers.find(c => c.id === id).prescriptions.data.flatMap(p => p.files);
  },
  getCustomerAllFiles:
    (_, { getCustomerFiles }) =>
    id => {
      return getCustomerFiles(id);
    },
};

const mutations = {
  SET_CUSTOMERS(state, data) {
    state.customers = data;
  },
  SET_CUSTOMER_FILES(state, { customerId, files }) {
    const customer = state.customers.find(c => c.id === customerId);
    Vue.set(customer, 'files', files);
  },
  SET_SELECTED_CUSTOMER(state, customer) {
    state.selectedCustomer = customer;
  },
  SET_SELECTED_CUSTOMER_NEWSLETTER(state, customer) {
    state.selectedCustomerNewsletter = customer;
  },
  UPDATE_CUSTOMER_IN_CUSTOMERS(state, customer) {
    const index = state.customers.findIndex(c => c.id === customer.id);
    Vue.set(state.customers, index, customer);
  },
  REMOVE_CUSTOMER_FILE(_, { customer, fileId }) {
    const fileIndex = customer.files.findIndex(f => f.id === fileId);
    Vue.delete(customer.files, fileIndex);
  },
  REMOVE_PRESCRIPTION_FILE(_, { prescription, fileId }) {
    const fileIndex = prescription.files.findIndex(f => f.id === fileId);
    Vue.delete(prescription.files, fileIndex);
  },
};

const actions = {
  getCustomerFiles({ commit }, customerId) {
    apiCustomer.getCustomerFiles(customerId).then(response => {
      commit('SET_CUSTOMER_FILES', { customerId, files: response.data.data });
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  mutations,
  actions,
};
