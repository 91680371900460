import axios from 'axios';

export default {
  postCustomerPrescription(customerId, data = {}) {
    return axios.post(`/v4/users/${customerId}/prescriptions`, data);
  },
  postGuestPrescription(data = {}) {
    return axios.post(`/v4/users/prescriptions/storeAnonymous`, data);
  },
  getCustomerPrescription(customerId) {
    return axios.get(
      `${process.env.VUE_APP_NEW_API_URL}/staff/customers/${customerId}/prescriptions`,
      {
        params: {
          with: 'source,staff',
        },
      },
    );
  },
  putPrescription(prescriptionId, data = {}) {
    return axios.put(`/v4/prescriptions/${prescriptionId}`, data);
  },
  deletePrescription(prescriptionId) {
    return axios.delete(`/prescription/${prescriptionId}`);
  },
  deletePrescriptionFile(customerId, prescriptionId, fileId) {
    return axios.delete(`/v4/users/${customerId}/prescriptions/${prescriptionId}/files/${fileId}`);
  },
  getTypes() {
    return axios.get('/v4/prescription-types');
  },
  getSources() {
    return axios.get('/prescription-source', {
      params: {
        type: 'external',
      },
    });
  },
  sendEmail(prescriptionId) {
    return axios.post(`/prescription/${prescriptionId}/email`);
  },
};
