import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import numeral from 'numeral';
import createPersistedState from 'vuex-persistedstate';
import dayjs from 'dayjs';
import storage from '../support/storage';
import jobsModule from './modules/jobs-module';
import addressModal from './modules/address-modal';
import paymentModal from './modules/payment-modal';
import commentModal from './modules/comment-modal';
import orderLensModal from './modules/order-lens-modal';
import auth from './modules/auth';
import customerModule from './modules/customer';
import apiRegions from '../api/regions';
import apiPds from '../api/pds';
import apiOutlets from '../api/outlets';
import apiOrders from '../api/orders';
import apiPrescriptions from '../api/prescriptions';
import apiLenses from '../api/lenses';
import apiCustomers from '../api/customers';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'filters',
      paths: ['jobFilters'],
    }),
  ],
  state: {
    alertSecs: 5,
    alertCountDown: 0,
    alertVariant: null,
    alertContent: null,
    sizes: ['XS', 'S', 'M', 'L'],
    regions: [],
    pdSources: [],
    prescriptionSources: [],
    outlets: [],
    orderItemStatuses: [],
    orderPaymentStatuses: [],
    orderDispenseStatuses: [],
    selectedOrderItems: [],
    selectedOrderId: null,
    selectedPrescription: null,
    selectedFile: null,
    prescriptionTypes: [],
    spheres: [],
    cylinders: [],
    dialogOpened: false,
    temp: {},
    createShippingOrderModalKey: 0,
    jobs: [],
    notable: {},
    lensSuppliers: [],
    staffs: [],
    jobFilters: {},
  },
  modules: {
    jobsModule,
    addressModal,
    paymentModal,
    commentModal,
    orderLensModal,
    auth,
    customer: customerModule,
  },
  mutations: {
    SET_ALERT_COUNT_DOWN(state, number = null) {
      state.alertCountDown = number === null ? state.alertSecs : number;
    },
    SET_ALERT_VARIANT(state, variant) {
      state.alertVariant = variant;
    },
    SET_ALERT_CONTENT(state, text) {
      state.alertContent = text;
    },
    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
    SET_PD_SOURCES(state, sources) {
      state.pdSources = sources;
    },
    SET_OUTLETS(state, outlets) {
      state.outlets = outlets;
    },
    SET_ORDER_ITEM_STATUSES(state, statuses) {
      state.orderItemStatuses = statuses;
    },
    SET_ORDER_PAYMENT_STATUSES(state, statuses) {
      state.orderPaymentStatuses = statuses;
    },
    SET_ORDER_DISPENSE_STATUSES(state, statuses) {
      state.orderDispenseStatuses = statuses;
    },
    SET_PRESCRIPTION_TYPES(state, types) {
      state.prescriptionTypes = types;
    },
    SET_PRESCRIPTION_SOURCES(state, sources) {
      state.prescriptionSources = sources;
    },
    SET_SPHERES(state) {
      const min = -18;
      const max = 12;
      const spheres = [
        {
          value: null,
          text: 'None',
        },
      ];

      for (let i = max; i >= min; i -= 0.25) {
        spheres.push({
          value: i,
          text: numeral(i).format('0.00'),
        });
      }

      state.spheres = spheres;
    },
    SET_CYLINDERS(state) {
      const min = -6;
      const max = 0;
      const cylinders = [
        {
          value: null,
          text: 'None',
        },
      ];

      for (let i = max; i >= min; i -= 0.25) {
        cylinders.push({
          value: Number(i),
          text: numeral(i).format('0.00'),
        });
      }
      state.cylinders = cylinders;
    },
    SET_SELECTED_PRESCRIPTION(state, prescription) {
      state.selectedPrescription = prescription;
    },
    SET_SELECTED_FILE(state, file) {
      state.selectedFile = file;
    },
    SET_TEMP_SELECTED_PD(state, pd) {
      state.temp.selectedPd = pd;
    },
    SET_TEMP_REFUND_TYPE(state, type) {
      Vue.set(state.temp, 'refundType', type);
    },
    ADD_SELECTED_ORDER_ITEM(state, item) {
      state.selectedOrderItems.push(item);
    },
    REMOVE_SELECTED_ORDER_ITEM(state, item) {
      state.selectedOrderItems = state.selectedOrderItems.filter(x => x.id !== item.id);
    },
    CLEAR_SELECTED_ORDER_ITEMS(state) {
      state.selectedOrderItems = [];
    },
    SET_SELECTED_ORDER_ID(state, orderId) {
      state.selectedOrderId = orderId;
    },
    INCREASE_CREATE_SHIPPING_ORDER_MODAL_KEY(state) {
      state.createShippingOrderModalKey += 1;
    },
    SET_JOBS(state, jobs) {
      state.jobs = jobs.map(job => {
        // eslint-disable-next-line
        job._showDetails = false;
        return job;
      });
    },
    SET_JOBS_MEILI(state, jobs) {
      state.jobs = jobs.map(job => {
        return job;
      });
    },
    UPDATE_JOB_IN_JOBS(state, job) {
      const index = state.jobs.findIndex(stateJob => stateJob.id === job.id);
      const targetJob = state.jobs[index];
      if (targetJob._showDetails) {
        Vue.set(state.jobs, index, { ...job, _showDetails: targetJob._showDetails });
      } else {
        Vue.set(state.jobs, index, { ...job, _showDetails: true });
      }
    },

    SET_LENS_SUPPLIERS(state, suppliers) {
      state.lensSuppliers = suppliers;
    },
    SET_STAFFS(state, staffs) {
      state.staffs = staffs;
    },
    ADD_JOB_FILTER(state, { name, query }) {
      Vue.set(state.jobFilters, name, query);
    },
    REMOVE_JOB_FILTER(state, name) {
      Vue.delete(state.jobFilters, name);
    },
  },
  actions: {
    showSuccessAlert({ commit }, text, countDown = null) {
      commit('SET_ALERT_COUNT_DOWN', countDown);
      commit('SET_ALERT_VARIANT', 'success');
      commit('SET_ALERT_CONTENT', text);
    },
    showErrorAlert({ commit }, text, countDown = null) {
      commit('SET_ALERT_COUNT_DOWN', countDown);
      commit('SET_ALERT_VARIANT', 'danger');
      commit('SET_ALERT_CONTENT', text);
    },
    async setRegions({ commit }) {
      let regions = storage.get('regions');
      if (!regions) {
        const response = await apiRegions.getRegions();
        regions = response.data.data;
        storage.set('regions', regions, dayjs().add(1, 'week').valueOf());
      }
      commit('SET_REGIONS', regions);
    },
    async setPdSources({ commit }) {
      let pdSources = storage.get('pdSources');
      if (!pdSources) {
        const response = await apiPds.getSources();
        pdSources = response.data.data;
        storage.set('pdSources', pdSources, dayjs().add(1, 'week').valueOf());
      }
      commit('SET_PD_SOURCES', pdSources);
    },
    async setOutlets({ commit }) {
      let outlets = storage.get('outlets');
      if (!outlets) {
        const response = await apiOutlets.getOutlets();
        outlets = response.data.data;
        storage.set('outlets', outlets, dayjs().add(1, 'day').valueOf());
      }
      commit('SET_OUTLETS', outlets);
    },
    async setOrderItemStatuses({ commit }) {
      let orderItemStatuses = storage.get('orderItemStatuses');
      if (!orderItemStatuses) {
        const response = await apiOrders.getOrderItemStatuses();
        orderItemStatuses = response.data.data;
        storage.set('orderItemStatuses', orderItemStatuses, dayjs().add(1, 'week').valueOf());
      }
      commit('SET_ORDER_ITEM_STATUSES', orderItemStatuses);
    },
    async setOrderPaymentStatuses({ commit }) {
      let orderPaymentStatuses = storage.get('orderPaymentStatuses');
      if (!orderPaymentStatuses) {
        const response = await apiOrders.getOrderPaymentStatuses();
        orderPaymentStatuses = response.data.data;
        storage.set('orderPaymentStatuses', orderPaymentStatuses, dayjs().add(1, 'week').valueOf());
      }
      commit('SET_ORDER_PAYMENT_STATUSES', orderPaymentStatuses);
    },
    async setOrderDispenseStatuses({ commit }) {
      let orderDispenseStatuses = storage.get('orderDispenseStatuses');
      if (!orderDispenseStatuses) {
        const response = await apiOrders.getOrderDispenseStatuses();
        orderDispenseStatuses = response.data.data;
        storage.set(
          'orderDispenseStatuses',
          orderDispenseStatuses,
          dayjs().add(1, 'week').valueOf(),
        );
      }
      commit('SET_ORDER_DISPENSE_STATUSES', orderDispenseStatuses);
    },
    async setPrescriptionTypes({ commit }) {
      let prescriptionTypes = storage.get('prescriptionTypes');
      if (!prescriptionTypes) {
        const response = await apiPrescriptions.getTypes();
        prescriptionTypes = response.data.data.map(type => ({
          value: type.id,
          text: type.name,
        }));
        storage.set('prescriptionTypes', prescriptionTypes, dayjs().add(1, 'week').valueOf());
      }
      commit('SET_PRESCRIPTION_TYPES', prescriptionTypes);
    },
    async setPrescriptionSources({ commit }) {
      let prescriptionSources = storage.get('prescriptionSources');
      if (!prescriptionSources) {
        const response = await apiPrescriptions.getSources();
        prescriptionSources = response.data.data.map(source => ({
          value: source.id,
          text: source.name,
          abbr: source.slug,
          color: source.origin === 'dresden' ? 'primary' : 'purple',
        }));
        storage.set('prescriptionSources', prescriptionSources, dayjs().add(1, 'week').valueOf());
      }
      commit('SET_PRESCRIPTION_SOURCES', prescriptionSources);
    },
    async setLensSuppliers({ commit }) {
      let lensSuppliers = storage.get('lensSuppliers');
      if (!lensSuppliers) {
        const response = await apiLenses.getAllSuppliers();
        lensSuppliers = response.data.data;
        storage.set('lensSuppliers', lensSuppliers, dayjs().add(1, 'day').valueOf());
      }
      commit('SET_LENS_SUPPLIERS', lensSuppliers);
    },
    setSelectedOrderItems: ({ getters, commit }, item) => {
      if (getters.selectedOrderItemIds.includes(item.id)) {
        commit('REMOVE_SELECTED_ORDER_ITEM', item);
      } else {
        commit('ADD_SELECTED_ORDER_ITEM', item);
      }
    },
    setSelectedOrderLevel: ({ getters, commit }, { order, selected }) => {
      if (order.items) {
        order.items.forEach(item => {
          // unselect order level
          if (selected && getters.selectedOrderItemIds.includes(item.id)) {
            commit('REMOVE_SELECTED_ORDER_ITEM', item);
            // select order level
          } else if (!selected) {
            commit('ADD_SELECTED_ORDER_ITEM', { ...item, customer: order.customer });
          }
        });
      } else {
        const items = order.item_groups.map(g => g.order_items).flat();

        items.forEach(item => {
          // unselect order level
          if (selected && getters.selectedOrderItemIds.includes(item.id)) {
            commit('REMOVE_SELECTED_ORDER_ITEM', item);
            // select order level
          } else if (!selected) {
            commit('ADD_SELECTED_ORDER_ITEM', { ...item, customer: order.customer });
          }
        });
      }
    },
    async setStaffs({ commit }) {
      let staffs = storage.get('staffs');
      if (!staffs) {
        const response = await apiCustomers.getStaffs();
        staffs = response.data.data;
        storage.set('staffs', staffs, dayjs().add(1, 'day').valueOf());
      }
      commit('SET_STAFFS', staffs);
    },
  },
  getters: {
    regionOptions(state) {
      const results = state.regions.map(region => ({
        value: region.id,
        text: region.name,
      }));
      results.unshift({ value: null, text: 'All' });

      return results;
    },
    currencies(state) {
      const currencies = state.regions.map(region => region.default_currency);
      return _.uniq(currencies);
    },
    outletOptions(state) {
      return state.outlets.map(outlet => ({
        value: outlet.id,
        text: outlet.name,
      }));
    },
    orderItemStatuses(state) {
      return state.orderItemStatuses;
    },
    dispenseStatuses(state) {
      return state.orderDispenseStatuses;
    },
    orderPaymentStatuses(state) {
      return state.orderPaymentStatuses;
    },
    pdSourceOptions(state) {
      return state.pdSources.map(source => ({
        value: source.id,
        text: source.name,
        abbr: source.slug,
      }));
    },
    locationSelectorRegions(state) {
      return state.regions.map(region => ({
        id: region.id,
        name: region.name,
        countryCode: region.country_code,
      }));
    },
    selectedOrder(state) {
      const index = state.jobs.findIndex(job => job.id === state.selectedOrderId);
      return index > -1 ? state.jobs[index] : null;
    },
    selectedOrderItemIds(state) {
      return state.selectedOrderItems.map(({ id }) => id);
    },
    createShippingOrderModalKey(state) {
      return state.createShippingOrderModalKey;
    },
    selectableDispenseStatuses: ({ orderDispenseStatuses }) =>
      orderDispenseStatuses.filter(status => status.selectable),
    selectableItemStatuses: ({ orderItemStatuses }) =>
      orderItemStatuses.filter(status => status.selectable),
    selectableOutlets: ({ outlets }) => outlets.filter(outlet => outlet.is_active),
    refundType: ({ temp }) => temp.refundType,
    getStaffUsername:
      ({ staffs }) =>
      id => {
        const staff = staffs.result.find(s => s.id === id);
        if (staff) {
          return staff.username || false;
        }
        return false;
      },
    getStaffOutletName:
      ({ staffs, outlets }) =>
      id => {
        const staff = staffs.result.find(s => s.id === id);
        if (staff) {
          const outlet = outlets.find(o => o.id === staff.outlet_id);
          return outlet.name || false;
        }
        return false;
      },
  },
});
