<template>
  <b-table
    :items="customers"
    :fields="fields"
    :busy="isLoading"
    class="customers-table"
    head-variant="light"
    show-empty
    no-local-sorting
    @sort-changed="onSortChanged"
    responsive="sm"
  >
    <template v-slot:table-busy>
      <div class="text-center text-light my-2">
        <b-spinner class="align-middle" />
      </div>
    </template>
    <template v-slot:table-colgroup="scope">
      <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
    </template>
    <template v-slot:cell(name)="data">
      <b-button
        variant="icon"
        @click="data.toggleDetails"
        class="mr-3 btn-icon--collapse"
        :class="data.detailsShowing ? '' : 'collapsed'"
      >
        <i class="di-arrow-up-12" v-if="data.detailsShowing" />
        <i class="di-arrow-down-12" v-if="!data.detailsShowing" />
      </b-button>
      <span class="font-weight-medium">{{ data.item.name }}</span>
    </template>
    <template v-slot:cell(regions)="data">
      {{ getRegions(data.item.region) }}
    </template>
    <template v-slot:cell(notes)="data">
      <b-button variant="icon-text-transparent" class="p-0" @click="openCommentsModal(data.item)">
        <span class="icon" :class="{ active: data.item.notesCount > 0 }">
          <i class="di-comment-18" />
        </span>
        <span class="text">
          {{ data.item.notesCount }}
        </span>
      </b-button>
    </template>
    <template v-slot:row-details="data">
      <div class="row-details">
        <div class="rd-body">
          <CustomersProfileMeili
            :customer="data.item"
            :collapsible="true"
            :expanded="true"
            @setCustomerDetais="setCustomerDetais"
          />
          <div v-if="customerDetails">
            <PrescriptionList
              :customer="customerDetails"
              :showTitle="true"
              :selectable="false"
              :collapsible="true"
              :expanded="false"
              :showProvider="true"
              :showFile="true"
              @new="showPrescriptionModal"
              @edit="showPrescriptionEditModal"
              @deleted="onDeleted"
            />
            <PupilDistanceList
              :customer="customerDetails"
              :showTitle="true"
              :collapsible="true"
              :selectable="false"
              :expanded="false"
              :showProvider="true"
              @new="showPdModal"
              @edit="showPdEditModal"
              @deleted="onDeleted"
            />

            <FileList :customer="customerDetails" />
          </div>
        </div>
        <div class="rd-footer ml-2">
          <b-link
            class="btn btn-icon-text-transparent mr-4"
            :href="createIOClassicLink(data.item.id)"
            target="_blank"
          >
            <span class="icon">
              <i class="di-profile-18" />
            </span>
            <span class="text">View customer profile</span>
          </b-link>
          <b-button
            variant="icon-text-transparent mr-4"
            @click="$router.push({ name: 'jobs', query: { q: data.item.name } })"
          >
            <span class="icon">
              <i class="di-profile-18" />
            </span>
            <span class="text">View jobs</span>
          </b-button>
          <b-button
            variant="icon-text-transparent mr-4"
            @click="showPrescriptionModal({ customer: data.item })"
            v-if="data.item"
          >
            <span class="icon">
              <i class="di-create-18" />
            </span>
            <span class="text">Add prescription</span>
          </b-button>
          <b-button
            variant="icon-text-transparent mr-4"
            @click="showPdModal({ customer: data.item })"
            v-if="data.item"
          >
            <span class="icon">
              <i class="di-create-18" />
            </span>
            <span class="text">Add PD</span>
          </b-button>
          <b-button
            variant="icon-text-transparent mr-4"
            @click="showAddAddressModal({ customer: data.item })"
          >
            <span class="icon">
              <i class="di-create-18" />
            </span>
            <span class="text">Add Address</span>
          </b-button>
          <b-button variant="icon-text-transparent mr-4" @click="showCreatePaymentModal(data.item)">
            <span class="icon">
              <i class="di-create-18" />
            </span>
            <span class="text">Create Payment</span>
          </b-button>
          <b-button variant="icon-text-transparent mr-4" @click="openSendMessageModal(data.item)">
            <span class="icon">
              <svgicon name="send" />
            </span>
            Send a Message
          </b-button>
        </div>
      </div>
    </template>
  </b-table>
</template>

<script>
import apiNotes from '@/api/notes';
import { mapState, mapMutations } from 'vuex';
import CustomersProfileMeili from './CustomersProfileMeili.vue';
import '@/icons/send';
import PrescriptionList from './PrescriptionList.vue';
import PupilDistanceList from './PupilDistanceList.vue';
import FileList from './FileList.vue';

export default {
  name: 'CustomersMeiliTable',
  components: { CustomersProfileMeili, PrescriptionList, PupilDistanceList, FileList },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Customer',
          bold: true,
          sortable: false,
        },
        {
          key: 'regions',
          label: 'Region(s)',
          sortable: false,
        },
        {
          key: 'notes',
          label: '',
        },
      ],
      sortDesc: true,
      customerDetails: null,
    };
  },
  props: {
    customers: Array,
    isLoading: Boolean,
  },
  computed: {
    ...mapState({
      addressCustomer: state => state.addressModal.addressCustomer,
      currentUser: state => state.auth.user,
    }),
    sortDirection() {
      return this.sortDesc ? 'desc' : 'asc';
    },
  },
  methods: {
    ...mapMutations('customer', {
      setSelectedCustomer: 'SET_SELECTED_CUSTOMER',
    }),
    showPrescriptionModal(e) {
      this.$emit('show-prescription-modal', e);
    },
    showPdModal(e) {
      this.$emit('show-pd-modal', e);
    },
    setCustomerDetais(e) {
      this.customerDetails = e;
    },
    showPrescriptionEditModal(e) {
      this.$emit('show-prescription-edit-modal', e);
    },
    showPdEditModal(e) {
      this.$emit('show-pd-edit-modal', e);
    },
    getRegions(rawRegions) {
      if (!rawRegions) {
        return '';
      }
      const regions = rawRegions.map(item => item.country_code);
      const uniqueRegions = [...new Set(regions)];
      return uniqueRegions.join(', ');
    },
    onSortChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      const query = {
        sort_by: ctx.sortBy,
        sort_direction: this.sortDirection,
        page: 1,
      };
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    },
    onDeleted(e) {
      this.$emit('deleted', e);
    },
    openCommentsModal(customer) {
      const title = customer.full_name;
      const parent = 'customer';
      this.$store.commit('commentModal/SHOW_COMMENT_MODAL', {
        vm: this,
        title,
        createCommentsCallback: noteContent =>
          apiNotes.createCustomerNote(customer.id, this.currentUser.id, noteContent),
        fetchCommentsCallback: () => apiNotes.getCustomerNotes(customer.id),
        removeCommentCallback: noteId => apiNotes.deleteCustomerNote(noteId),
        parent,
      });
    },
    createIOClassicLink(customerId) {
      return `${this.ioUrl}#/customer/${customerId}`;
    },
    openSendMessageModal(customer) {
      this.setSelectedCustomer(customer);
      this.$nextTick(() => {
        this.$bvModal.show('send-message-modal');
      });
    },
  },
};
</script>
