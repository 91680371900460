<template>
  <ValidationObserver v-slot="{ passes }">
    <b-modal id="create-shipping-order-modal" size="xl" @show="onShow"
      ><template v-slot:modal-header="{ close }">
        <b-container>
          <div class="modal-title">
            <h3 class="text-bold text-center">Create Shipping Order</h3>
            <button class="close" type="button" aria-label="Close" @click="close">
              <i class="di-remove-10"></i>
            </button>
          </div>
        </b-container>
      </template>
      <div>
        <div class="container" v-if="step === 1">
          <h5 class="mb-4">Shipping Details</h5>
          <b-form ref="createShippingOrderForm">
            <section>
              <h6 class="text-uppercase mb-3">Sender</h6>
              <b-row>
                <b-col cols="6">
                  <b-form-group label="Pickup location" label-for="location">
                    <b-form-select v-model="form.sender.outlet" name="location" required="">
                      <b-form-select-option
                        v-for="outlet in outletsWithAddress"
                        :key="outlet.id"
                        :value="outlet"
                        >{{ getAddress(outlet.address) }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </section>
          </b-form>
          <div class="divider mt-2 mb-4"></div>
          <section>
            <h6 class="text-uppercase mb-3">Receiver</h6>
            <div v-if="selectedAddress">
              <b-row class="mb-4">
                <b-col cols="4"
                  ><label>Recipient</label><br /><span
                    >{{ selectedAddress.first_name }} {{ selectedAddress.last_name }}</span
                  ></b-col
                >
                <b-col cols="4"
                  ><label>Email address</label><br /><span>{{ selectedAddress.email }}</span></b-col
                >
                <b-col cols="4"
                  ><label>Phone</label><br /><span>{{ selectedAddress.mobile }}</span></b-col
                >
              </b-row>
              <b-row class="mb-4">
                <b-col cols="4"
                  ><label>Street Address</label><br /><span>{{
                    selectedAddress.address.address1
                  }}</span
                  ><br /><span v-if="selectedAddress.address.address2">{{
                    selectedAddress.address.address2
                  }}</span></b-col
                >
                <b-col cols="4"
                  ><label>Suburb</label><br /><span
                    >{{ selectedAddress.address.suburb }}
                    {{ selectedAddress.address.city }}
                    {{ selectedAddress.address.state }}
                    {{ selectedAddress.address.postcode }}</span
                  ></b-col
                >
                <b-col cols="4"
                  ><label>Country</label><br /><span>{{
                    selectedAddress.address.country
                  }}</span></b-col
                >
              </b-row>
            </div>
            <b-row>
              <b-col cols="12">
                <b-button
                  class="mr-3"
                  variant="outline-light"
                  v-if="selectedAddress"
                  style="width: 100px"
                  @click="editAddress"
                  >Edit</b-button
                >
                <b-button variant="primary" @click="showAddressModal">{{
                  selectedAddress ? 'Change Address' : 'Choose Address'
                }}</b-button>
              </b-col>
            </b-row>
          </section>
          <div class="divider mt-2 mb-4"></div>
          <section v-if="isAddressCa">
            <h6 class="text-uppercase mb-3">Order Tracking</h6>
            <b-row>
              <b-col cols="12">
                <ValidationProvider
                  vid="ca_order_tracking"
                  name="Order Tracking URL"
                  mode="lazy"
                  :rules="{
                    regex:
                      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                    required: true,
                  }"
                  v-slot="validationContext"
                >
                  <b-form-group label="Order Tracking URL" label-for="ca_order_tracking">
                    <b-form-input
                      :state="getValidationState(validationContext)"
                      id="ca_order_tracking"
                      v-model="form.ca_order_tracking"
                    ></b-form-input>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </section>
          <div class="divider mt-2 mb-4"></div>
          <section v-if="!isAddressCa">
            <h6 class="text-uppercase mb-3">Parcel</h6>
            <b-row>
              <b-col cols="6" v-if="!isAddressDomestic">
                <b-form-group label="Description" label-for="description">
                  <b-form-input
                    id="description"
                    v-model="form.description"
                    autofocus=""
                    required=""
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Delivery instructions" label-for="delivery-instructions">
                  <b-form-select
                    v-model="form.receiver.instructions"
                    name="delivery-instructions"
                    required=""
                  >
                    <b-form-select-option
                      v-for="option in deliveryInstructions"
                      :key="option.id"
                      :value="option.value"
                      >{{ option.text }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Weight (kg)" label-for="weight">
                  <b-form-input
                    name="weight"
                    v-model="form.weight.value"
                    type="number"
                    step="0.01"
                    required=""
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Volume (m3)" label-for="volume">
                  <b-form-input
                    id="volume"
                    v-model="form.volume.value"
                    type="number"
                    step="0.00001"
                    required=""
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6" v-if="!isAddressDomestic">
                <b-form-group label="Country of origin" label-for="country-of-origin">
                  <b-form-input
                    id="country-of-origin"
                    v-model="form.contents.country_of_origin"
                    required=""
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Value (AUD$)" label-for="value">
                  <b-form-input
                    name="value"
                    v-model="form.contents.value"
                    type="number"
                    required=""
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Parcel Size" label-for="parcel-size">
                  <b-form-select
                    v-model="form.parcel_size_id"
                    name="parcel-size"
                    required=""
                    :options="parcelSizes"
                    @change="parcelOnChange"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Parcel Quantity (no. of boxes)" label-for="quantity">
                  <b-form-select
                    v-model="form.parcel_quantity"
                    name="parcel-size"
                    required=""
                    :options="parcelQuantities"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </section>
        </div>
        <div class="container" v-if="step === 2">
          <CourierPicker
            :recipient="toBeSubmittedAddress"
            :order-invoice="orderInvoice"
            :parcel="form"
            :selected-outlet-address="selectedOutletAddress"
            :couriers="couriers"
            v-model="courier"
          ></CourierPicker>
          <b-alert :show="errors.length &gt; 0" variant="danger" dismissible="dismissible">
            <h5 class="alert-heading">Create Order Error</h5>
            <div v-for="(error, index) of errors" v-bind:key="index">{{ error.message }}</div>
          </b-alert>
          <b-alert
            :show="Object.keys(quoteWarnings).length &gt; 0"
            variant="warning"
            dismissible="dismissible"
          >
            <div v-if="quoteWarnings">
              <h5 class="alert-heading">Qoute Warnings</h5>
              <div v-for="(warning, index) of quoteWarnings" v-bind:key="index">{{ warning }}</div>
            </div>
          </b-alert>
        </div>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <div class="flex-fill m-0">
          <div class="container">
            <div class="d-flex">
              <b-button class="mr-3" v-show="step === 2" variant="primary" @click="step = 1"
                >Back</b-button
              >
              <b-button class="mr-3" variant="outline-light" @click="cancel()">Cancel</b-button>
              <b-button
                class="mr-3"
                variant="primary"
                v-show="step === 2"
                @click="createOrder"
                :disabled="isLoading || !courier"
              >
                <b-spinner v-if="isLoading" small=""></b-spinner><span class="ml-1">Confirm</span>
              </b-button>
              <b-button
                variant="primary"
                v-show="step === 1"
                @click="passes(getQuote)"
                :disabled="isLoading || !selectedAddress"
              >
                <b-spinner v-if="isLoading" small=""></b-spinner
                ><span class="ml-1">Get Quotes</span>
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import apiOrders from '@/api/orders';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import _ from 'lodash';
import CourierPicker from '@/views/modals/CreateShippingOrder/CourierPicker.vue';

export default {
  name: 'CreateShippingOrderModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    CourierPicker,
  },
  data() {
    const deliveryInstructions = [
      {
        id: 1,
        value: 'authority_to_leave',
        text: 'Authority to leave',
      },
      {
        id: 2,
        value: 'signature_on_delivery',
        text: 'Signature on delivery',
      },
      {
        id: 3,
        value: 'authority_to_leave_signature',
        text: 'Authority to leave signature',
      },
    ];
    const couriers = [];
    const parcelSizes = [
      {
        value: 1,
        text: 'Small Box (18cm x 13cm x 6cm)',
        length: 18,
        width: 13,
        height: 6,
        weight: '0.2',
        units: 'kg',
      },
      {
        value: 2,
        text: 'Large Box (24cm x 15cm x 6cm)',
        length: 24,
        width: 15,
        height: 6,
        weight: '0.3',
        units: 'kg',
      },
    ];
    const parcelQuantities = _.range(1, 10).map(quantity => ({
      value: quantity,
      text: `${quantity} Box`,
    }));
    return {
      quoteWarnings: {},
      errors: [],
      step: 1,
      key: 0,
      isLoading: false,
      courier: null,
      couriers,
      senderOutletId: null,
      deliveryInstructions,
      parcelSizes,
      parcelQuantities,
      form: {
        first_mile_option: 'pickup',
        description: null,
        ca_order_tracking: null,
        parcel_size_id: 2,
        parcel_quantity: 1,
        weight: {
          units: 'kg',
          value: '0.3',
        },
        volume: {
          units: 'm3',
          value: '0',
        },
        sender: {
          contact: {
            name: 'Dresden Vision',
          },
          outlet: null,
        },
        receiver: {
          contact: {
            name: null,
            email: null,
            phone: null,
          },
          address: {
            address_line1: null,
            address_line2: null,
            suburb: null,
            state_name: null,
            postcode: null,
            country: null,
          },
          instructions: deliveryInstructions[0].value,
        },
        contents: {
          description: null,
          value: null,
          country_of_origin: null,
        },
      },
    };
  },
  computed: {
    ...mapState('auth', {
      currentUser: state => state.user,
      selectedOutletId: state => state.selectedOutletId,
    }),
    ...mapState({
      outlets: state => state.outlets,
      regions: state => state.regions,
    }),
    ...mapGetters({
      selectedOrder: 'selectedOrder',
      selectedAddress: 'addressModal/selectedBillingAddress',
      allAddresses: 'addressModal/allCurrentAddresses',
    }),
    ...mapGetters('auth', ['locationSelectorOutlets']),
    outletsWithAddress() {
      return this.locationSelectorOutlets.filter(outlet => outlet.address !== null);
    },
    sortedAddresses() {
      return this._.orderBy(this.allAddresses, ['updated_at'], ['desc']);
    },
    toBeSubmittedAddress() {
      if (!this.selectedAddress) return {};

      const contact = {
        name: `${this.selectedAddress.first_name} ${this.selectedAddress.last_name}`,
        email: this.selectedAddress.email,
        phone: this.selectedAddress.mobile,
      };

      const address = {
        ...this.selectedAddress.address,
      };

      if (!this._.get(address, 'suburb')) {
        address.suburb = address.city;
      }

      return {
        contact,
        address,
      };
    },
    customer() {
      return this._.get(this.selectedOrder, 'customer');
    },
    customerId() {
      return this._.get(this.customer, 'id');
    },
    billingInfo() {
      return this._.get(this.selectedOrder, 'order_billing');
    },
    billingAddress() {
      return this._.get(this.billingInfo, 'billing_address.address');
    },
    deliveryAddresses() {
      const customerBillingAddresses = this._.get(
        this.selectedOrder,
        'customer.billing_addresses',
        [],
      );
      const customerAddresses = customerBillingAddresses.map(billing =>
        this._.get(billing, 'address'),
      );
      return this.billingAddress ? [this.billingAddress, ...customerAddresses] : customerAddresses;
    },
    orderInvoice() {
      return this._.get(this.selectedOrder, 'vend_invoice_number');
    },
    selectedOutletAddress() {
      const address = this._.get(this.form, 'sender.outlet.address');
      return address ? this.getAddress(address) : '';
    },
    isAddressDomestic() {
      return this._.get(this.selectedAddress, 'address.country') === 'AU';
    },
    isAddressCa() {
      const selectedOutletRegionId = this.outlets.find(
        outlet => outlet.id === this.selectedOutletId,
      ).region_id;

      return (
        this.regions.find(region => region.id === selectedOutletRegionId).country_code === 'CA'
      );
    },
    isUserAdmin() {
      return this.currentUser.role_id === 1;
    },
  },
  methods: {
    ...mapMutations({
      updateJob: 'UPDATE_JOB_IN_JOBS',
    }),
    getAddress(obj) {
      const segments = [
        obj.address1,
        obj.address2,
        obj.suburb,
        obj.city,
        obj.state,
        obj.country === 'AU' ? 'Australia' : obj.country,
      ].filter(segment => segment);

      return segments.join(', ');
    },
    onShow() {
      this.warnings = {};
      this.errors = [];
      this.form.ca_order_tracking = null;
      this.step = 1;
      this.form.volume.value = '0';
      this.form.weight.value = '0.3';
      this.form.parcel_size_id = 2;
      this.form.receiver.instructions = this.deliveryInstructions[0].value;
      let nextDay = this.$moment();
      do {
        nextDay = nextDay.add(1, 'days');
      } while (nextDay.weekday() >= 5 || nextDay.weekday() === 0);
      this.form.description = 'Prescription glasses';
      this.form.sender.outlet = this.locationSelectorOutlets.find(
        outlet => outlet.id === this.selectedOutletId,
      );
      this.form.contents.country_of_origin = 'Australia';
      this.form.contents.value = Math.floor(this.selectedOrder.total_amount);
    },
    async parcelOnChange() {
      const parcel = await this.parcelSizes.find(
        parcelSize => parcelSize.value === this._.get(this.form, 'parcel_size_id'),
      );
      this.form.weight.value = parcel.weight;

      this.form.weight.units = parcel.units;
    },
    showAddressModal() {
      this.$bvModal.show('addresses-picker-modal');
    },
    editAddress() {
      this.showAddAddressModal({
        ...this.selectedAddress,
        customer: this.customer,
      });
    },
    async getQuote() {
      this.couriers = [];
      this.quoteWarnings = {};
      const address = this._.get(this.toBeSubmittedAddress, 'address');
      const contact = this._.get(this.toBeSubmittedAddress, 'contact');

      if (!this.$refs.createShippingOrderForm.checkValidity()) {
        // Try focus on the error
        this.$refs.createShippingOrderForm.reportValidity();
        return;
      }

      this.isLoading = true;
      const sendleQuoteParameters = {
        sender_outlet_id: this._.get(this.form, 'sender.outlet.id'),
        delivery_suburb: this._.get(address, 'suburb'),
        delivery_postcode: this._.get(address, 'postcode'),
        delivery_country: this._.get(address, 'country'),
        weight_value: this._.get(this.form, 'weight.value'),
        weight_units: this._.get(this.form, 'weight.units'),
        volume_value: this._.get(this.form, 'volume.value'),
        volume_units: this._.get(this.form, 'volume.units'),
        first_mile_option: 'pickup',
      };

      const parcel = await this.parcelSizes.find(
        parcelSize => parcelSize.value === this._.get(this.form, 'parcel_size_id'),
      );

      const auPostQuoteParameters = {
        sender_outlet_id: this._.get(this.form, 'sender.outlet.id'),
        country: this._.get(address, 'country'),
        delivery_postcode: this._.get(address, 'postcode'),
        weight: this._.get(this.form, 'weight.value'),
        width: parcel.width,
        height: parcel.height,
        length: parcel.length,
        quantity: this._.get(this.form, 'parcel_quantity'),
        receiver: {
          name: this._.get(contact, 'name'),
          lines: [this._.get(address, 'address1'), this._.get(address, 'address2')],
          suburb: this._.get(address, 'suburb'),
          state: this._.get(address, 'state'),
          country: this._.get(address, 'country'),
          postcode: this._.get(address, 'postcode'),
          phone: this._.get(contact, 'phone'),
          email: this._.get(contact, 'email'),
        },
      };

      try {
        if (this.isAddressCa && this.form.ca_order_tracking) {
          await apiOrders.getQuoteManuelEntry({
            tracking_id: this.form.ca_order_tracking,
            sender_outlet_id: this._.get(this.form, 'sender.outlet.id'),
            cargo_company: 'canada manual entry',
            order_id: this._.get(this.selectedOrder, 'id'),
          });

          this.$store.dispatch(
            'showSuccessAlert',
            'New shipping order has been successfully created!',
          );
          this.$bvModal.hide('create-shipping-order-modal');
          this.$root.$emit('reloadExpandedJob', this.selectedOrder.id);
        } else {
          const response = await apiOrders.getQuote({
            aupost: auPostQuoteParameters,
            sendle: sendleQuoteParameters,
          });
          this.couriers = response.data.data;
        }
      } catch (error) {
        if (Array.isArray(error?.response?.data?.errors?.errors)) {
          this.quoteWarnings = error.response.data.errors.errors.map(err => `${err.message}`);
        } else {
          this.quoteWarnings = Object.entries(error.response.data.errors).map(
            ([key, value]) => `${key}: ${value}`,
          );
        }
      }
      this.courier = null;
      this.step = 2;
      this.isLoading = false;
    },
    createOrder() {
      const selectedCourier = this.couriers.find(courier => courier.product_id === this.courier);
      if (selectedCourier) {
        if (selectedCourier.product_type === 'sendle') {
          this.createOrderBySendle();
        }
        if (selectedCourier.product_type === 'aupost') {
          this.createOrderByAuPost();
        }
      }
    },
    createOrderByAuPost() {
      const selectedCourier = this.couriers.find(courier => courier.product_id === this.courier);
      const parcel = this.parcelSizes.find(
        parcelSize => parcelSize.value === this._.get(this.form, 'parcel_size_id'),
      );
      const address = this._.get(this.toBeSubmittedAddress, 'address');
      const contact = this._.get(this.toBeSubmittedAddress, 'contact');
      const productId = selectedCourier.product_id;
      const orderId = this._.get(this.selectedOrder, 'id');
      const auPostForm = {
        sender_outlet_id: this._.get(this.form, 'sender.outlet.id'),
        product_id: productId,
        order_id: orderId,
        weight: this._.get(this.form, 'weight.value'),
        width: parcel.width,
        height: parcel.height,
        length: parcel.length,
        quantity: this._.get(this.form, 'parcel_quantity'),
        receiver: {
          name: this._.get(contact, 'name'),
          lines: [this._.get(address, 'address1'), this._.get(address, 'address2')],
          suburb: this._.get(address, 'suburb'),
          state: this._.get(address, 'state'),
          country: this._.get(address, 'country'),
          postcode: this._.get(address, 'postcode'),
          phone: this._.get(contact, 'phone'),
          email: this._.get(contact, 'email'),
        },
      };
      this.isLoading = true;
      apiOrders
        .createAuPostOrder(auPostForm)
        .then(() => {
          this.$store.dispatch(
            'showSuccessAlert',
            'New shipping order has been successfully created!',
          );
          this.$bvModal.hide('create-shipping-order-modal');
          this.$root.$emit('reloadExpandedJob', this.selectedOrder.id);
        })
        .catch(error => {
          if (Array.isArray(error.response.data)) {
            const [auPostError] = error.response.data;
            if (
              typeof auPostError?.errors === 'string' &&
              typeof auPostError.message === 'string'
            ) {
              this.$store.dispatch(
                'showErrorAlert',
                `${auPostError.errors} (${auPostError.message})`,
              );
              return;
            }
          }
          if (error.response?.data?.errors?.errors) {
            this.errors = error.response.data.errors.errors;
          }
          this.$store.dispatch('showErrorAlert', error.response.data.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createOrderBySendle() {
      const address = this._.get(this.toBeSubmittedAddress, 'address');
      if (address) {
        this.form.receiver.address = {
          ...address,
          address_line1: this._.get(address, 'address1'),
          address_line2: this._.get(address, 'address2'),
          state_name: this._.get(address, 'state'),
        };
        this.form.receiver.contact = this.toBeSubmittedAddress.contact;
        this.form.contents.description = this.form.description;
      }

      this.form.sender.outlet_id = this._.get(this.form, 'sender.outlet.id');

      this.isLoading = true;
      const selectedCourier = this.couriers.find(courier => courier.product_id === this.courier);
      const toBeSubmittedForm = {
        ...this.form,
        pickup_date: selectedCourier.delivery_estimate[1],
        order_id: this.selectedOrder.id,
      };

      apiOrders
        .createSendleOrder(toBeSubmittedForm)
        .then(response => {
          this.$store.dispatch(
            'showSuccessAlert',
            'New shipping order has been successfully created!',
          );
          this.$bvModal.hide('create-shipping-order-modal');
          const orderId = this._.get(this.selectedOrder, 'id');
          const userId = this._.get(this.currentUser, 'id');
          const trackingUrl = this._.get(response, 'data.tracking_url');
          const sendleOrderId = this._.get(response, 'data.order_id');
          const note = `
            Tracking URL: ${trackingUrl}
            Label: https://app.sendle.com/dashboard/sent_orders/${sendleOrderId}/label?size=cropped
          `;
          apiOrders.postOrderNote({
            orderId,
            note,
            user_id: userId,
          });

          this.updateJob({
            ...this.selectedOrder,
            shipment_company: 'SENDLE',
            shipment_status: 'created',
            shipment_tracking_id: response.data.sendle_reference,
          });
          this.$root.$emit('reloadExpandedJob', this.selectedOrder.id);
        })
        .catch(error => {
          if (error.response?.data?.errors) {
            this.errors = Array.isArray(error.response?.data?.errors)
              ? Object.entries(error.response.data.errors).map(([key, value]) => ({
                  message: `${key}: ${value}`,
                }))
              : [{ message: error.response?.data?.errors }];
          }
          this.$store.dispatch('showErrorAlert', error.response.data.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
