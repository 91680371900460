<template>
  <v-wait for="Comments List">
    <template slot="waiting">
      <div class="text-center pb-5">
        <b-spinner small=""></b-spinner>
      </div>
    </template>
    <div class="comments-list" v-if="comments.length || coupons || orderAddress">
      <div class="single-comment" v-if="orderAddress">
        <div class="user-avatar">
          <template>I</template>
        </div>
        <div class="comment-holder">
          <div class="comment-user">
            <b>Order </b>
            <span>Address</span>
          </div>

          <div class="comment-body">
            <p>
              <b>Address:</b>
              {{
                (orderAddress.address1 ? orderAddress.address1 : ' ') +
                ' ' +
                (orderAddress.address2 ? orderAddress.address2 : '')
              }}<br />
              <b>City:</b> {{ orderAddress.city }} <br />
              <b>Country:</b> {{ orderAddress.country }}<br />
              <b>Postcode:</b> {{ orderAddress.postcode }} <br />
              <b>State:</b> {{ orderAddress.state }} <br />
              <b>Suburb:</b> {{ orderAddress.suburb }}
            </p>
          </div>
        </div>
      </div>

      <div class="single-comment" v-for="(comment, index) in comments" v-bind:key="index">
        <template>
          <div class="user-avatar">
            <img v-if="comment.user.profile_image" alt="" :src="comment.user.profile_image" />
            <template v-else>{{ comment.user.initials | uppercase }}</template>
          </div>
          <div class="comment-holder">
            <div class="comment-user">
              <b v-if="comment.user.fullName">{{ comment.user.fullName }}</b>
              <span v-if="comment.user.username">@{{ comment.user.username }}</span>
            </div>
            <div class="comment-time">
              <ToggleText>
                <template v-slot:normal-text="">
                  {{ comment.created_at | moment('from', 'now') }}
                </template>
                <template v-slot:hidden-text="">
                  {{ comment.created_at | moment('timezone', timezone, 'Do MMM YYYY, ha') }}
                </template>
              </ToggleText>
            </div>
            <div class="comment-body">
              <p>{{ comment.content }}</p>
              <FileCard :files="comment.files"></FileCard>
            </div>
            <a
              class="action-button"
              href="javascript:;"
              v-if="currentStaffId === comment.user_id"
              @click="removeComment(comment.id)"
            >
              Remove
            </a>
            <a
              class="action-button"
              href="javascript:;"
              @click="updateComment(comment)"
              v-if="
                comment.orders && comment.orders.length > 0 && currentStaffId === comment.user_id
              "
            >
              Update
            </a>
          </div>
        </template>
      </div>
    </div>
    <div class="no-comments-alert" v-else>
      <figure>
        <img src="../assets/images/typing.svg" alt="" />
      </figure>
      <h4>No comments to speak of</h4>
      <p>Why don't you start the conversation?</p>
    </div>
  </v-wait>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ToggleText from './ToggleText.vue';
import FileCard from './FileCard.vue';

export default {
  name: 'CommentsList',
  components: {
    ToggleText,
    FileCard,
  },
  data() {
    return {
      comments: [],
      orderAddress: null,
      coupons: [],
      hasCoupons: false,
    };
  },
  computed: {
    ...mapState({
      currentStaffId: state => state.auth.user.id,
      fetchCommentsCallback: state => state.commentModal.fetchCommentsCallback,
      fetchOrderCallback: state => state.commentModal.fetchOrderCallback,
      removeCommentCallback: state => state.commentModal.removeCommentCallback,
    }),
  },
  mounted() {
    this.fetchComments();
    this.$root.$on('commentsRefreshed', () => {
      this.fetchComments();
    });
  },
  methods: {
    ...mapMutations('commentModal', {
      setSelectedUpdateNote: 'SET_SELECTED_UPDATE_NOTE',
    }),
    updateComment(note) {
      this.setSelectedUpdateNote(note);
      this.$bvModal.show('comment-update-modal');
    },
    removeComment(noteId) {
      const h = this.$createElement;
      const message = h('span', {
        domProps: {
          innerHTML: 'Are you sure want to delete this comment? This action can not be undone.',
        },
      });
      this.$dialog('Delete Comment', [message]).then(value => {
        if (value) {
          this.removeCommentCallback(noteId)
            .then(() => {
              this.$store.dispatch('showSuccessAlert', 'Deleted Successfully');
              this.fetchComments();
              this.$root.$emit('commentsRefreshed');
            })
            .catch(({ response }) => {
              this.$store.dispatch('showErrorAlert', this._.get(response, 'data.error', 'Error'));
            });
        }
      });
    },
    fetchComments() {
      this.$wait.start('Comments List');
      this.fetchCommentsCallback()
        .then(response => {
          const comments = this._.get(response, 'data.data', []);
          this.comments = comments
            .map(comment => {
              if (!comment.user) {
                const systemUser = {
                  first_name: 'System',
                  last_name: '',
                  username: 'system',
                };
                /* eslint-disable */
                comment.user = systemUser;
              }
              comment.user.fullName = this.getFullName(
                comment.user.first_name,
                comment.user.last_name,
              );
              comment.user.initials = comment.user.fullName
                ? this.createLetterAvatar(comment.user.first_name, comment.user.last_name)
                : comment.user.username.slice(0, 2);
              /* eslint-enable */

              return comment;
            })
            .reverse();
        })
        .catch(response => {
          this.$store.dispatch('showErrorAlert', this._.get(response, 'data.error', 'Error'));
        })
        .finally(() => {
          this.$wait.end('Comments List');
        });
      if (this.fetchOrderCallback) {
        this.fetchOrderCallback().then(response => {
          this.$wait.start('Comments List');
          if (response.data.data.order_billing) {
            this.orderAddress = this._.get(
              response,
              'data.data.order_billing.billing_address.address',
            );
          }
          this.coupons = this._.get(response, 'data.data.coupons.data');
          if (this.coupons) {
            this.hasCoupons = true;
          }
          this.$wait.end('Comments List');
        });
      }
    },
  },
};
</script>
