import _ from 'lodash';

const initialState = {
  orderLensData: null,
  frameSizeId: null,
  order: null,
};

const mutations = {
  SHOW_ORDER_LENS_MODAL(state, data) {
    state.orderLensData = data;
    state.frameSizeId = _.get(data, 'frameSizeId');
    state.order = _.get(data, 'order');
    data.vm.$bvModal.show('order-lens-modal');
  },
};

const actions = {};

const moduleGetters = {};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
