export default function parseMustache(str = '', data = {}) {
  return str?.replace(/{{\s?([^}]*)\s?}}/g, (tag, match) => {
    const [notation, fallback] = match.split('|').map(m => m.trim().toLowerCase());

    const nodes = notation.split('.');
    let current = JSON.parse(JSON.stringify(data));

    for (let i = 0; i < nodes.length; i += 1) {
      if (current[nodes[i]]) {
        current = current[nodes[i]];
      } else {
        if (fallback) {
          current = fallback;
        } else {
          current = tag;
        }
        break;
      }
    }

    return typeof current === 'string' ? current : fallback || tag;
  });
}
