<template>
  <div>
    <v-wait for="Lens List"
      ><template slot="waiting">
        <div class="text-center mb-3">
          <b-spinner></b-spinner>
        </div>
      </template>
      <div class="table-title-wrapper">
        <h6 class="text-uppercase rd-title" v-if="showTitle">Available Lenses</h6>
        <a @click="resetLens" v-if="selectable">Reset</a>
      </div>
      <ul class="list-unstyled rd-list lens-list">
        <LensListItem
          v-for="lens in availableLenses"
          :key="lens.id"
          :lens="lens"
          :selectable="selectable"
          :collapsible="collapsible"
          v-model="$store.state.selectedLens"
          @edit="onEdit"
          @deleted="onDeleted"
        ></LensListItem>
      </ul>
      <ErrorMessage v-if="availableLenses.length === 0" type="box">{{ errorMessage }}</ErrorMessage>
    </v-wait>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ErrorMessage from '@/components/ErrorMessage.vue';
import apiLenses from '../api/lenses';
import LensListItem from './LensListItem.vue';

export default {
  name: 'LensList',
  components: {
    LensListItem,
    ErrorMessage,
  },
  props: {
    customer: Object,
    prescription: Object,
    showTitle: Boolean,
    selectable: Boolean,
    collapsible: Boolean,
  },
  data() {
    return {
      availableLenses: [],
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      job: state => state.jobsModule.selectedJobData,
      selectedLens: state => state.jobsModule.selectedLens,
      selectedPrescriptionType: state => state.jobsModule.selectedPrescriptionType,
      selectedPrescription: state => state.jobsModule.selectedPrescription,
      selectedPd: state => state.jobsModule.selectedPd,
      selectedFrameData: state => state.jobsModule.selectedFrameData,
    }),
  },
  mounted() {
    this.$wait.start('Lens List');
    const params = {
      region_product_id: this.job.item.region_product_id,
      prescription_id: this.selectedPrescription.id,
      prescription_type: this.selectedPrescriptionType,
      pd_id: this.selectedPd.detail.id,
      frame_size: this.selectedFrameData.frameSize,
    };

    apiLenses
      .getLensAddonCombination(params)
      .then(response => {
        this.$wait.end('Lens List');
        if (this._.get(response.data, 'invalid_error_msg')) {
          this.errorMessage = this._.get(response.data, 'invalid_error_msg');
        } else {
          const datas = Object.assign([], response.data);

          const shamirLenses = datas.filter(lens => lens.lenses.supplier.name.includes('Shamir'));
          const shamirLongLenses = shamirLenses.filter(lens =>
            lens.lenses.name.toLowerCase().includes('long'),
          );
          const shamirMediumOtherLenses = shamirLenses.filter(
            lens =>
              !lens.lenses.name.toLowerCase().includes('long') &&
              lens.lenses.name.toLowerCase().includes('medium'),
          );
          const shamirOtherLenses = shamirLenses.filter(
            lens =>
              !lens.lenses.name.toLowerCase().includes('long') &&
              !lens.lenses.name.toLowerCase().includes('medium'),
          );
          const hoyaLenses = datas.filter(lens => lens.lenses.supplier.name.includes('Hoya'));
          const hoyaLongLenses = hoyaLenses.filter(lens =>
            lens.lenses.name.toLowerCase().includes('long'),
          );

          const hoyaMediumOtherLenses = hoyaLenses.filter(
            lens =>
              !lens.lenses.name.toLowerCase().includes('long') &&
              lens.lenses.name.toLowerCase().includes('medium'),
          );

          const hoyaOtherLenses = hoyaLenses.filter(
            lens =>
              !lens.lenses.name.toLowerCase().includes('long') &&
              !lens.lenses.name.toLowerCase().includes('medium'),
          );

          const otherLenses = datas.filter(
            lens =>
              !lens.lenses.supplier.name.includes('Shamir') &&
              !lens.lenses.supplier.name.includes('Hoya'),
          );

          this.availableLenses = hoyaLongLenses
            .concat(hoyaOtherLenses)
            .concat(hoyaMediumOtherLenses)
            //Shamir Lens
            .concat(shamirMediumOtherLenses)
            .concat(shamirOtherLenses)
            .concat(shamirLongLenses)

            .concat(otherLenses)

            .filter(lens => !lens.invalid_error_msg)
            .filter(lens => !lens.lenses.supplier.name.includes('Zeiss'));

          let checkZeiss = false;
          let checkHoya = false;

          if (this.availableLenses.length >= 2) {
            if (
              this.availableLenses.filter(lens => lens.lenses.supplier.name.includes('Zeiss'))
                .length > 0
            ) {
              checkZeiss = true;
            }

            if (
              this.availableLenses.filter(lens => lens.lenses.supplier.name.includes('Hoya'))
                .length > 0
            ) {
              checkHoya = true;
            }

            if (checkZeiss && checkHoya) {
              this.availableLenses = this.availableLenses.filter(
                lens => !lens.lenses.supplier.name.includes('Zeiss'),
              );
            }
          } else if (this.availableLenses.length === 0) {
            this.errorMessage = 'No lens available';
          }

          const existingLens = this._.find(this.availableLenses, { lens_id: this.job.lenses.id });
          this.$store.commit('jobsModule/SET_SELECTED_LENS', existingLens || this.job.lenses);
          this.$store.commit('jobsModule/SET_SELECTED_LENSCOATING', this.availableLenses);
        }
      })
      .catch(() => {
        // this.errors = response.data.error;
        // this.$store.dispatch('showErrorAlert', response.statusText);
        this.$wait.end('Lens List');
      });
  },
  methods: {
    resetLens() {
      this.$store.commit('jobsModule/SET_SELECTED_LENS', null);
    },
    onEdit(e) {
      this.$emit('edit', {
        customer: {
          id: this.customer.id,
          full_name: this.customer.full_name,
        },
        prescription: e.prescription,
      });
    },
    onClick() {
      this.$emit('new', {
        customer: {
          id: this.customer.id,
          full_name: this.customer.full_name,
        },
      });
    },
    onDeleted(e) {
      this.$emit('deleted', e);
    },
  },
};
</script>
