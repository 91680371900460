<template>
  <div class="main-content-wrapper">
    <div class="pagination-info">
      Page <span class="font-weight-medium">{{ meta.current_page }}</span> showing
      <span class="font-weight-medium">{{ meta.from }} - {{ meta.to }}</span> of
      <span class="font-weight-medium">{{ meta.total | numeral('0a') }}</span> {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TablePaginationInfo',
  data() {
    const { page } = this.$route.query;
    return {
      page: page || 1,
    };
  },
  props: {
    meta: Object,
    description: String,
  },
  methods: {
    onChange(page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
  },
  watch: {
    $route(to) {
      this.page = to.query.page || 1;
    },
  },
};
</script>
