<template>
  <b-modal
    id="order-link-modal"
    size="xl"
    hide-footer=""
    @show="onShow"
    centered
    header-class="padding-top-0 padding-bottom-0 modal-custom-header"
  >
    <template #modal-header="{ close }">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 10px;
        "
      >
        <div style="flex: 1"></div>
        <b-button @click="close()" variant="link" class="p-0">
          <span class="d-inline-block" aria-hidden="true" style="font-size: 30px; color: #4a4f4a"
            >&times;</span
          >
        </b-button>
      </div>
    </template>

    <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #f4f6f9;
      "
    >
      <p style="font-size: 22px; margin-bottom: 0px">
        Search for the order number you want to link with
      </p>
      <p style="font-size: 22px; margin-bottom: 0px">
        <span style="font-weight: 500">{{ vendId() }}</span>
      </p>

      <div style="display: flex; width: 100%; justify-content: center; align-items: center">
        <b-form-group class="mb-0">
          <b-form-input
            v-model="search"
            placeholder="Search"
            size="lg"
            @keyup.enter="onSubmit"
            style="background: #fff; width: 400px"
          >
          </b-form-input>
        </b-form-group>
        <b-button
          @click="onSubmit"
          class="mt-3 mb-3"
          style="width: 100px; margin-left: 10px; background: #2b82fc; height: 45px"
          variant="primary"
          >Search</b-button
        >
      </div>
    </div>

    <div style="padding: 10px">
      <div v-if="isLoading" class="text-center mt-3">
        <b-spinner />
      </div>
      <div v-if="orders.length > 0">
        <b-table :items="orders" :fields="fields" responsive="sm">
          <template v-slot:cell(vend_invoice_number)="row">
            <b-link
              :href="`/jobs?q=${row.item.vend_invoice_number}`"
              target="_blank"
              style="color: #212721"
            >
              <div style="display: flex; justify-content: start; align-items: center">
                {{ row.item.vend_invoice_number }}
                <div style="margin-left: 15px">
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 14.8529C1.0875 14.8529 0.734375 14.7061 0.440625 14.4123C0.146875 14.1186 0 13.7654 0 13.3529V2.35294C0 1.94044 0.146875 1.58731 0.440625 1.29356C0.734375 0.999811 1.0875 0.852936 1.5 0.852936H6V2.35294H1.5V13.3529H12.5V8.85294H14V13.3529C14 13.7654 13.8531 14.1186 13.5594 14.4123C13.2656 14.7061 12.9125 14.8529 12.5 14.8529H1.5ZM5.0625 10.8529L4 9.79044L11.4375 2.35294H9V0.852936H14V5.85294H12.5V3.41544L5.0625 10.8529Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </b-link>
          </template>

          <template v-slot:cell(full_name)="row">
            <a
              v-if="row && row.item.customer && row.item.customer_id"
              :href="createIOClassicLinkCustomer(row.item.customer_id)"
              target="_blank"
              class="font-weight-medium"
              style="text-decoration: none; color: #000"
            >
              <span v-html="customerName(row)" />
            </a>
            <span v-else v-html="customerName(row)" class="font-weight-medium" />
          </template>

          <template v-slot:cell(email)="row">
            <span v-html="customerEmail(row)" />
          </template>

          <template v-slot:cell(mobile)="row">
            <span v-html="customerMobile(row)" />
          </template>

          <template #cell(actions)="row">
            <b-button @click="linkOrder(row.item.id)" variant="primary" style="border-radius: 44px"
              >Link Order</b-button
            >
          </template>
          <!-- Add more custom slots as needed -->
        </b-table>

        <!-- Pagination controls -->
        <b-pagination
          v-if="totalPages > 1"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="mt-3"
          align="center"
        ></b-pagination>
      </div>

      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 40px;
          margin-bottom: 200px;
        "
        v-if="orders.length === 0 && !isLoading"
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 52px;
            width: 52px;
            border-radius: 100px;
            border: 1px solid #dadeda;
          "
        >
          <svg
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.6956 23.6245L15.0484 15.9772C14.3656 16.5235 13.5804 16.9559 12.6927 17.2745C11.8051 17.5932 10.8606 17.7525 9.85917 17.7525C7.37838 17.7525 5.2788 16.8933 3.56045 15.175C1.8421 13.4566 0.982929 11.357 0.982929 8.87624C0.982929 6.39545 1.8421 4.29587 3.56045 2.57752C5.2788 0.859175 7.37838 0 9.85917 0C12.34 0 14.4395 0.859175 16.1579 2.57752C17.8762 4.29587 18.7354 6.39545 18.7354 8.87624C18.7354 9.87767 18.5761 10.8222 18.2575 11.7098C17.9388 12.5974 17.5064 13.3826 16.9602 14.0654L24.6415 21.7468C24.8919 21.9972 25.0171 22.3044 25.0171 22.6686C25.0171 23.0327 24.8805 23.3514 24.6074 23.6245C24.357 23.8748 24.0384 24 23.6515 24C23.2646 24 22.9459 23.8748 22.6956 23.6245ZM9.85917 15.0213C11.5661 15.0213 13.0171 14.4239 14.2119 13.229C15.4068 12.0341 16.0043 10.5832 16.0043 8.87624C16.0043 7.16927 15.4068 5.71835 14.2119 4.52347C13.0171 3.32859 11.5661 2.73115 9.85917 2.73115C8.1522 2.73115 6.70128 3.32859 5.5064 4.52347C4.31152 5.71835 3.71408 7.16927 3.71408 8.87624C3.71408 10.5832 4.31152 12.0341 5.5064 13.229C6.70128 14.4239 8.1522 15.0213 9.85917 15.0213Z"
              fill="#8F939A"
            />
          </svg>
        </div>

        <div style="margin-top: 15px">
          <p style="color: #8f939a; font-size: 14px">Search by order number</p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import apiOrders from '@/api/orders';

export default {
  name: 'OrderLinkModal',
  data() {
    return {
      isLoading: false,
      search: null,
      orderId: null,
      orders: [],
      fields: [
        { key: 'vend_invoice_number', label: 'Order Number', sortable: false },
        { key: 'full_name', label: 'Customer', sortable: false },
        { key: 'email', label: 'Email Address', sortable: false },
        { key: 'mobile', label: 'Mobile Number', sortable: false },
        { key: 'actions', label: '' },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      totalPages: 0,
    };
  },
  computed: {
    ...mapGetters({
      selectedOrder: 'selectedOrder',
    }),
  },
  watch: {
    currentPage: 'onPageChange',
  },
  methods: {
    vendId() {
      const vend_invoice_number = this._.get(this.selectedOrder, 'vend_invoice_number');
      return vend_invoice_number;
    },
    onShow() {
      this.orderId = this._.get(this.selectedOrder, 'id');
      this.orders = [];
      this.search = null;
      this.currentPage = 1;
      this.isLoading = false;
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
      this.orders = [];
      this.fetchData();
    },
    onSubmit() {
      this.currentPage = 1;
      this.orders = [];
      this.fetchData();
    },
    customerName(row) {
      const { first_name, last_name } = row.value;

      if (first_name || last_name) {
        return `${first_name} ${last_name}`;
      }
      // Guest
      const { customer_first_name = '', customer_last_name = '' } = row.item.order_billing || {};
      let name = '';
      if (customer_first_name || customer_last_name) {
        name += `${customer_first_name} ${customer_last_name}<br />`;
      }
      name += '(Guest)';

      return name;
    },
    customerEmail(row) {
      const email = this._.get(row, 'item.customer.email');
      if (email) {
        return email;
      }
      // Guest
      const { customer_email = '' } = row.item.order_billing || {};
      let guestEmail = '';
      if (customer_email) {
        guestEmail = customer_email;
      }
      return guestEmail;
    },
    customerMobile(row) {
      const mobile = this._.get(row, 'item.customer.mobile');
      if (mobile) {
        return mobile;
      }
      // Guest
      const { customer_mobile = '' } = row.item.order_billing || {};
      let guestMobile = '';
      if (customer_mobile) {
        guestMobile = customer_mobile;
      }
      return guestMobile;
    },
    createIOClassicLinkCustomer(customerId) {
      return `${this.ioUrl}#/customer/${customerId}`;
    },
    fetchData() {
      if (this.search === null) {
        this.$store.dispatch('showErrorAlert', 'Search is null');
        return;
      }
      this.isLoading = true;
      apiOrders
        .getOrders({
          q: this.search,
          page: this.currentPage,
          per_page: this.perPage,
        })
        .then(response => {
          this.orders = response.data.data;
          this.totalRows = response.data.meta.total;
          this.totalPages = response.data.meta.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        })
        .catch(error => {
          this.$store.dispatch('showErrorAlert', error.response.data.message);
        });
    },
    linkOrder(selectedOrderId) {
      if (this.orderId === null) {
        this.$store.dispatch('showErrorAlert', 'Order ID is null tryagain with refresh');
        return;
      }
      this.isLoading = true;
      apiOrders
        .orderLink(this.orderId, {
          related_order_id: selectedOrderId,
        })
        .then(() => {
          this.$store.dispatch('showSuccessAlert', 'Order Linked Successfully');
          apiOrders.getOrder(this.orderId).then(({ data: { data: job } }) => {
            this.$store.commit('UPDATE_JOB_IN_JOBS', job);
          });
          this.$bvModal.hide('order-link-modal');
          this.isLoading = false;
        })
        .catch(error => {
          this.$store.dispatch('showErrorAlert', error.response.data.message);
        });
    },
  },
};
</script>
