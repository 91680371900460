import axios from 'axios';

export default {
  createOrderNote($orderId, $form) {
    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/staff/orders/${$orderId}/notes`, $form);
  },
  updateOrderNote($orderId, $noteId, $form) {
    return axios.put(
      `${process.env.VUE_APP_NEW_API_URL}/staff/orders/${$orderId}/notes/${$noteId}`,
      $form,
    );
  },
  updateOrderNoteReceipt($orderId, $noteId, $form) {
    return axios.put(
      `${process.env.VUE_APP_NEW_API_URL}/staff/orders/${$orderId}/note-receipt/${$noteId}`,
      $form,
    );
  },
  createOrderItemNote($orderId, $userId, $note) {
    return axios.post(`${process.env.VUE_APP_API_URL}/orders-item/${$orderId}/note`, {
      user_id: $userId,
      note: $note,
    });
  },
  getOrderNotes($orderId, $commentItemLevel) {
    const action =
      $commentItemLevel === 'order'
        ? axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/orders/${$orderId}/notes`)
        : axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/order-items/${$orderId}/notes`);
    return action;
  },
  deleteOrderNote($orderId, $noteId, $commentItemLevel) {
    const action =
      $commentItemLevel === 'order'
        ? axios.delete(`${process.env.VUE_APP_API_URL}/orders/${$orderId}/note/${$noteId}`)
        : axios.delete(`${process.env.VUE_APP_API_URL}/orders-item/${$orderId}/note/${$noteId}`);
    return action;
  },
  getCustomerNotes(customerId) {
    return axios
      .get(`${process.env.VUE_APP_API_URL}/user-note`, {
        params: {
          user_id: customerId,
          with: 'creator',
        },
      })
      .then(result => {
        // Old API (user_notes table) treats user_id as the note "about" that user / customer
        // New API (notes table) treats user_id as the one
        // who created the note (same as creator_id in old API)
        if (result.data.data) {
          const originalNotes = result.data.data;
          const transformResult = { ...result };
          transformResult.data.data = originalNotes.map(note => ({
            ...note,
            user_id: note.creator_id,
            user: note.creator,
          }));
        }
        return result;
      });
  },
  createCustomerNote(customerId, staffId, content) {
    return axios.post(`${process.env.VUE_APP_API_URL}/user-note`, {
      content,
      user_id: customerId,
      creator_id: staffId,
    });
  },
  deleteCustomerNote(noteId) {
    return axios.delete(`${process.env.VUE_APP_API_URL}/user-note/${noteId}`);
  },
};
