import axios from 'axios';
import { encode } from 'url-safe-base64';

export default {
  getCustomers(params = {}) {
    return axios.get('v4/users', {
      params: {
        customer: 1,
        ...params,
        sort_by: 'updated_at',
        sort_direction: 'desc',
      },
    });
  },
  getMeiliCustomers(params = {}) {
    return axios.get(`${process.env.VUE_APP_API_V4_URL}/v4/get-users`, {
      params: {
        customer: 1,
        ...params,
        sort_by: 'updated_at',
        sort_direction: 'desc',
      },
    });
  },
  postCustomer(data = {}) {
    return axios.post('/user', {
      ...data,
    });
  },
  updateCustomer(customerId, data) {
    return axios.put(`${process.env.VUE_APP_NEW_API_URL}/staff/customers/${customerId}`, data);
  },
  getCustomer(customerId) {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/customers/${customerId}`);
  },
  getCustomerFiles(customerId) {
    return axios.get(`/customer/${customerId}/files`);
  },
  getStaffs(params = {}) {
    return axios.get('/user', {
      params: {
        staff: true,
        limit: 100,
        ...params,
      },
    });
  },
  sendMessage(customerId, data) {
    return axios.post(
      `${process.env.VUE_APP_NEW_API_URL}/staff/customers/${customerId}/messages`,
      data,
    );
  },
  async generateECommerceLoginURL(customerId, region) {
    const { data } = await axios.post(
      `${process.env.VUE_APP_WC_API_URL}/customerLogin/${customerId}`,
    );

    // Convert JSON string to Uint8Array
    const jsonUint8Array = new TextEncoder().encode(JSON.stringify(data));

    // Convert Uint8Array to string containing only characters in Latin1 range
    const latin1String = String.fromCharCode.apply(null, jsonUint8Array);

    // Encode Latin1 string using btoa
    const encodedData = btoa(latin1String);
    let url = `${process.env.VUE_APP_E_COMMERCE_URL}`;

    const availableRegions = ['au', 'nz', 'ca'];
    if (region && availableRegions.includes(region.toLowerCase())) {
      url = `${url}/${region.toLowerCase()}`;
    }

    return `${url}?cLogin=${encodedData}`;
  },
  getReferralCheckCount(customerId) {
    return axios.get(`${process.env.VUE_APP_WC_API_URL}/referral/check/${customerId}`, {
      headers: {
        Authorization: `Bearer xcn5lwXIyS9Jxb9cdjU3lXjZyYrw7ENjUWRDKnfmsy8vQm0GlaPzVst4H0FYKS1gUOrWu1vUyWRvaUuB`,
      },
    });
  },

  defineReferral(referralUserID, inviterUserID) {
    return axios.post(
      `${process.env.VUE_APP_WC_API_URL}/referral/define/${referralUserID}/${inviterUserID}`,
    );
  },

  undefineReferral(referralUserID) {
    return axios.delete(`${process.env.VUE_APP_WC_API_URL}/referral/undefine/${referralUserID}`);
  },

  getNewsletterUsers(data) {
    return axios.post(`${process.env.VUE_APP_WC_API_URL}/campaigns/newsletter/user/list`, data);
  },

  updateNewsletter(data) {
    return axios.post(`${process.env.VUE_APP_WC_API_URL}/campaigns/newsletter/user/update`, data);
  },
};
