<template>
  <div>
    <ValidationObserver v-slot="{ passes }">
      <div class="table-title-wrapper">
        <h6 class="text-uppercase rd-title">Files</h6>
        <div class="mb-3 text-center" v-if="isLoading">
          <b-spinner />
        </div>
        <b-button variant="icon-text-transparent" @click="handleAddFile">
          <span class="icon">
            <i class="di-create-18" />
          </span>
          <ValidationProvider
            name="File"
            rules="ext:jpeg,jpg,png,bmp,pdf,doc,docx|fileSize:10024"
            ref="fileValidator"
          >
            <input
              type="file"
              class="d-none"
              ref="fileInput"
              multiple="multiple"
              @change="handleChangeFile"
            />
            <span class="text">Add File</span>
          </ValidationProvider>
        </b-button>
      </div>
      <ul class="list-unstyled rd-list pupil-distance-list">
        <CustomerFileCard :customer="customer" />
      </ul>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import axios from 'axios';
import { mapActions } from 'vuex';
import CustomerFileCard from './CustomerFileCard.vue';

export default {
  name: 'FileList',
  components: {
    CustomerFileCard,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      file: null,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      getCustomerFiles: 'customer/getCustomerFiles',
    }),
    handleAddFile() {
      this.$refs.fileInput.click();
    },
    async handleChangeFile(e) {
      this.isLoading = true;
      this.fileError = '';
      this.file = null;
      const { valid } = await this.$refs.fileValidator.validate(e);

      if (valid) {
        const formData = new FormData();
        for (let i = 0; i < this.$refs.fileInput.files.length; i++) {
          const file = this.$refs.fileInput.files[i];
          formData.append(`files[${i}]`, file);
        }

        axios
          .post(`${process.env.VUE_APP_API_V4_URL}/customer/${this.customer.id}/files`, formData)
          .then(() => {
            this.isLoading = false;
            this.getCustomerFiles(this.customer.id);
          });
      } else {
        this.fileError = 'File must e an image, pdf or doc/docx';
      }
    },
  },
};
</script>
