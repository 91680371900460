<template>
  <div class="main-content-wrapper">
    <b-pagination
      v-model="page"
      :total-rows="totalRows"
      :per-page="meta.per_page"
      align="center"
      use-router
      hide-goto-end-buttons
      first-number
      last-number
      limit="7"
      class="text-semi-bold mb-0"
      @change="onChange"
    >
      <template v-slot:prev-text>
        <span><i class="di-arrow-left-12"></i> Previous</span>
      </template>
      <template v-slot:next-text>
        <span>Next <i class="di-arrow-right-12"></i></span>
      </template>
      <template v-slot:ellipsis-text>
        <span class="seperator">..</span>
      </template>
    </b-pagination>
  </div>
</template>

<script>
export default {
  name: 'TablePagination',
  data() {
    const { page } = this.$route.query;
    return {
      page: page || 1,
    };
  },
  props: {
    meta: Object,
    totalRows: Number,
  },
  methods: {
    onChange(page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
    refreshPage() {
      this.page = this.$route.query.page;
    },
  },
  watch: {
    $route(to) {
      this.page = to.query.page || 1;
    },
  },
};
</script>
