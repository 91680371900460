/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449S19.748 19 12.015 19C4.835 19 0 11.551 0 11.551S4.446 5 12.015 5C19.709 5 24 11.551 24 11.551zM17 12a5 5 0 10-10 0 5 5 0 0010 0z"/>'
  }
})
