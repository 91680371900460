<template>
  <div class="d-flex order-item">
    <div id="order-item-detail" class="flex-grow-1">
      <div class="rd-deep-1" style="margin-bottom: 10px">
        <b-container fluid>
          <b-row>
            <b-col cols="4" class="d-flex align-items-start">
              <div class="rdd1-part__content d-flex align-items-center">
                <div class="font-weight-medium">Total Payment: ${{ totalPayment() }}</div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="payment-details-section">
        <b-container fluid>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              class="payment-item"
              v-for="payment in payments"
              :key="payment.id"
            >
              <div class="payment-type">
                {{ payment.historical_payment_type_name }}
              </div>
              <div class="payment-amount font-weight-medium">${{ payment.amount }}</div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<style scoped>
.payment-details-section {
  margin-bottom: 20px;
}

.payment-item {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  transition: transform 0.2s ease-in-out;
  margin-right: 10px;
}
</style>
<script>
export default {
  name: 'OrderPayments',
  props: {
    order: Object,
  },
  computed: {
    payments() {
      if (this.order.payments) {
        return this.order.payments;
      }
      return null;
    },
  },
  methods: {
    totalPayment() {
      let total = 0;
      for (let i = 0; i < this.payments.length; i++) {
        total += this.payments[i].amount;
      }
      return Number(total).toFixed(2);
    },
  },
};
</script>
