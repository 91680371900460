<template lang="pug">
  b-modal#addresses-picker-modal(size='xl' @show="onShow" @hide="onHide")
    template(v-slot:modal-header='{ close }')
      b-container
        .modal-title
          h3.text-bold.text-center Choose Address
          button.close(type='button' aria-label='Close' @click='close')
            i.di-remove-10
    div
      .container
        h5.mb-4 Shipping Details
        section
          b-row
            b-col(cols='12')
              b-button.mb-3(block variant='icon-text-transparent'
              size="lg" class="well btn-xl"
              :disabled="!addressCustomer"
              @click='showAddAddressModal({customer: addressCustomer})')
                span.icon
                  i.di-create-18
                span.text Add Address
          b-row
            b-col(cols='12')
              RadioCard(v-for="address in sortedAddresses" :key='address.id'
              v-model.number="selectedAddressId"
              :value="address.id"
              :label="address.address.type")
                b-row
                  b-col(cols='6')
                    h6.text-uppercase.mb-3 Address
                    p.lh-md
                        span {{ address.address.address1 }}
                        br
                        span(v-if='address.address.address2')
                          | {{ address.address.address2 }}
                          br
                        span
                          | {{ address.address.suburb }} {{ address.address.city }}
                          | {{ address.address.state }} {{ address.address.postcode }}
                        br
                        span {{ address.address.country }}
                  b-col(cols='6')
                    h6.text-uppercase.mb-3 Contact
                    b-container.p-0
                        b-row.mb-2
                            b-col(cols="3")
                                span.text-medium Name
                            b-col(cols="9")
                                span {{ address.first_name }} {{ address.last_name }}
                        b-row.mb-2
                            b-col(cols="3")
                                span.text-medium Email
                            b-col(cols="9")
                                span {{ address.email }}
                        b-row.mb-2
                            b-col(cols="3")
                                span.text-medium Phone
                            b-col(cols="9")
                                span {{ address.mobile }}
    template(v-slot:modal-footer='{ cancel }')
      .flex-fill.m-0
        .container
          .d-flex
            b-button.mr-3(variant='outline-light' @click='cancel') Cancel
            b-button.mr-3(variant='primary' @click='confirm')
              | Confirm
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RadioCard from '@/components/RadioCard.vue';
// TODO Standardize address string

export default {
  name: 'AddressesPicker',
  components: {
    RadioCard,
  },
  data() {
    return {
      selectedAddressId: null,
    };
  },
  computed: {
    ...mapState({
      addressCustomer: state => state.addressModal.addressCustomer,
    }),
    ...mapGetters({
      allAddresses: 'addressModal/allCurrentAddresses',
    }),
    sortedAddresses() {
      return this._.orderBy(this.allAddresses, ['updated_at'], ['desc']);
    },
  },
  methods: {
    onShow() {
      this.selectedAddressId = this.$store.state.addressModal.selectedBillingAddressId;
    },
    onHide() {
      this.$store.commit('addressModal/HIDE_NEW_ADDRESS_FORM');
    },
    confirm() {
      this.$store.commit('addressModal/SET_SELECTED_BILLING_ADDRESS_ID', this.selectedAddressId);
      this.$bvModal.hide('addresses-picker-modal');
    },
  },
};
</script>
