<template lang="pug">
  .courier-selection
    .courier-header.custom-control.custom-checkbox--courier
      input.custom-control-input(:id='labelFor' v-if="!disabled" @change="$emit('change', $event.target.value)"
      type='radio' :value='value' :checked="model === value" :name='name')
      template(v-if="disabled") 
        label {{ label }}
      label.custom-control-label.custom-control-label--courier(:for='labelFor' v-else)
        | {{ label }}
      span.header-label(v-if="!disabled") {{ headerLabel }}
    .courier-body
      slot
</template>

<script>
// TODO change class name

export default {
  name: 'RadioCard',
  model: {
    prop: 'model',
    event: 'change',
  },
  props: ['headerLabel', 'label', 'model', 'value', 'name', 'disabled'],
  data() {
    return {
      labelFor: Math.random().toString(36).substr(2, 5),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.lh-md {
  line-height: 22px;
}

.courier-selection {
  background-color: $gray-125;
  border-radius: 4px;
  margin-bottom: 18px;
}

.courier-header {
  border-bottom: solid 1px #dce0e6;
  height: 64px;
  padding: 20px 24px;

  label {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: normal;
    color: $gray-950;
  }
}

.header-label {
  position: absolute;
  right: 24px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: normal;
  color: $gray-950;
}

.courier-body {
  min-height: 120px;
  padding: 24px;
}

.custom-control-label--courier {
  left: 42px;

  &::before,
  &::after {
    top: 0;
    left: -42px;
  }
}
</style>
