import apiAuth from '@/api/auth';
import storage from '../../support/storage';

const initialState = {
  user: {},
  selectedOutletId: null,
  selectedRegionId: null,
};

const defaultOutletName = 'Newtown';

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  CLEAR_USER(state) {
    state.user = {};
  },
  SET_SELECTED_OUTLET_ID(state, id) {
    state.selectedOutletId = id;
  },
  SET_SELECTED_REGION_ID(state, id) {
    state.selectedRegionId = id;
  },
  SET_DEFAULT_OUTLET(state, defaultOutlet) {
    state.user.outlet = defaultOutlet;
  },
};

const actions = {
  async setUser({ rootState, commit }) {
    let user = storage.get('me');
    if (!user) {
      const response = await apiAuth.getMe();
      user = response.data.data;
      storage.set('me', user);
    }
    commit('SET_USER', user);

    if (!user.outlet) {
      const defaultOutlet = rootState.outlets.find(r => r.name === defaultOutletName);
      commit('SET_DEFAULT_OUTLET', defaultOutlet);
    }
    commit('SET_SELECTED_OUTLET_ID', user.outlet.id);
    commit('SET_SELECTED_REGION_ID', user.outlet.region_id);
  },
  updateUser({ commit }, data) {
    return apiAuth.putMe(data).then(response => {
      const user = response.data.data;
      commit('SET_USER', user);
      storage.set('me', user);
    });
  },
};

const moduleGetters = {
  currentUserIsAdmin: ({ user: { role_id: roleId } }) => roleId === 1,
  currentUserIsBeta: ({ user: { is_beta_user: beta } }) => !!beta,
  selectedOutlet(state, getters, rootState) {
    if (!state.selectedOutletId) {
      return 'Unknown';
    }
    const selectedOutlet = rootState.outlets.find(outlet => outlet.id === state.selectedOutletId);
    return selectedOutlet || 'Unknown';
  },
  currentRegion({ selectedRegionId }, getters, { regions }) {
    return regions.find(region => region.id === selectedRegionId);
  },
  locationSelectorOutlets({ selectedRegionId }, getters, { outlets }) {
    return outlets
      .filter(outlet => outlet.region_id === selectedRegionId && outlet.is_active)
      .map(outlet => ({
        id: outlet.id,
        abbreviation: outlet.abbreviation,
        name: outlet.name,
        address: outlet.address,
      }));
  },
  userFullName: ({ user }) => `${user.first_name} ${user.last_name}`,
};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
