<template>
  <ValidationObserver v-slot="{ passes }">
    <b-modal
      id="newsletter-update-modal"
      size="xl"
      ref="newCustomerModal"
      @show="onShow"
      @hide="onHide"
    >
      <template v-slot:modal-header="{ close }">
        <b-container>
          <div class="modal-title">
            <h3 class="text-bold text-center">Update Newsletter</h3>
            <button type="button" aria-label="Close" class="close" @click="close">
              <i class="di-remove-10"></i>
            </button>
          </div>
        </b-container>
      </template>
      <div class="container">
        <ul class="text-danger" v-if="errors.length">
          <li v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
        </ul>
        <b-form ref="newCustomerForm">
          <section>
            <h6 class="text-uppercase mb-3">Contact Details</h6>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Marketing Opt-in"
                  label-for="marketing-opt-in"
                  class="flex-fill"
                >
                  <b-form-radio-group
                    id="marketing-opt-in"
                    v-model="form.new_opt_in"
                    :options="optIns"
                    @change="listenChangeNewOptIn"
                    buttons
                    button-variant="white"
                    class="custom-radio-button"
                  />
                </b-form-group>

                <b-form-group label="Channels" label-for="marketing-opt-in" class="flex-fill">
                  <b-form-checkbox
                    v-model="form.channel_sms"
                    class="custom-checkbox-button"
                    name="check-button"
                  >
                    Sms
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="form.channel_phone"
                    class="custom-checkbox-button"
                    name="check-button"
                  >
                    Phone
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="form.channel_email"
                    class="custom-checkbox-button"
                    name="check-button"
                  >
                    Email
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </section>
        </b-form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <div class="flex-fill m-0">
          <div class="container">
            <div class="d-flex justify-content-end">
              <b-button variant="outline-light" @click="cancel()">Cancel</b-button>
              <b-button
                variant="primary"
                @click="passes(submit)"
                class="ml-3"
                :disabled="isLoading"
              >
                <b-spinner v-if="isLoading" small />
                <span v-else>Confirm</span>
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import apiCustomers from '@/api/customers';

export default {
  name: 'UpdateNewsletterModal',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ['customer'],
  data() {
    return {
      genders: ['Male', 'Female'],
      optIns: [
        { text: 'All Offers, News and Health', value: 'ALL' },
        { text: 'Only Health Subjects', value: 'HEALTH' },
        { text: 'None', value: 'NONE' },
      ],
      form: {
        new_opt_in: 'ALL',
        channel_phone: true,
        channel_sms: true,
        channel_email: true,
      },
      errors: [],
      isLoading: false,
      firstInit: false,
    };
  },
  computed: {
    ...mapState('customer', ['selectedCustomerNewsletter']),
  },
  methods: {
    ...mapMutations('customer', {
      setSelectedCustomerNewsletter: 'SET_SELECTED_CUSTOMER_NEWSLETTER',
    }),
    onShow() {
      if (this.selectedCustomerNewsletter) {
        this.form.new_opt_in = this.selectedCustomerNewsletter.send_type;
        this.form.channel_phone = this.selectedCustomerNewsletter.send_phone;
        this.form.channel_sms = this.selectedCustomerNewsletter.send_sms;
        this.form.channel_email = this.selectedCustomerNewsletter.send_email;
        this.firstInit = true;
      }
    },
    onHide() {
      this.isLoading = false;
      this.form = this.$options.data.apply(this).form;
      this.errors = [];
      this.setSelectedCustomerNewsletter(null);
    },
    submit() {
      this.isLoading = true;
      const data = {
        send_type: this.form.new_opt_in,
        send_phone: this.form.channel_phone,
        send_sms: this.form.channel_sms,
        send_email: this.form.channel_email,
        email: this.selectedCustomerNewsletter.user_email,
        region: this.selectedCustomerNewsletter.source_country,
      };
      apiCustomers
        .updateNewsletter(data)
        .then(() => {
          this.$bvModal.hide('newsletter-update-modal');
          this.$root.$emit('newsletterUpdated', data);
          this.$store.dispatch('showSuccessAlert', 'Customer Changed Successfully');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateNewOptIn() {
      if (this.firstInit) {
        if (
          this.form.channel_sms &&
          this.form.channel_phone &&
          this.form.channel_email &&
          this.form.new_opt_in !== 'HEALTH'
        ) {
          this.form.new_opt_in = 'ALL';
        } else if (!this.form.channel_sms && !this.form.channel_phone && !this.form.channel_email) {
          this.form.new_opt_in = 'NONE';
        }
      }
    },
    listenChangeNewOptIn() {
      if (this.form.new_opt_in === 'NONE') {
        this.form.channel_sms = false;
        this.form.channel_phone = false;
        this.form.channel_email = false;
      } else if (this.form.new_opt_in === 'ALL' || this.form.new_opt_in === 'HEALTH') {
        this.form.channel_sms = true;
        this.form.channel_phone = true;
        this.form.channel_email = true;
      }
    },
  },
  watch: {
    'form.channel_sms': function (newValue) {
      this.updateNewOptIn();
    },
    'form.channel_phone': function (newValue) {
      this.updateNewOptIn();
    },
    'form.channel_email': function (newValue) {
      this.updateNewOptIn();
    },
  },
};
</script>
