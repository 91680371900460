/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7 0a1 1 0 00-1 1v5H1a1 1 0 000 2h5v5a1 1 0 102 0V8h5a1 1 0 100-2H8V1a1 1 0 00-1-1z" _fill="#fff"/>'
  }
})
