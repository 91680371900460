<template>
  <div
    id="app"
    v-if="$auth.ready()"
    class="app-holder"
    v-bind:class="{ 'modal-active': showJobEditModal }"
  >
    <router-view />
    <b-alert
      :show="alertCountDown"
      :variant="alertVariant"
      class="position-fixed fixed-top m-0 rounded-0 text-center"
      style="z-index: 2000"
      @dismissed="countDownChanged"
      @dismiss-count-down="countDownChanged"
    >
      {{ alertContent }}
    </b-alert>
    <AddressesPicker />
    <AddressForm />
    <PaymentForm />
    <SendMessageModal />
  </div>
</template>

<style>
@import 'https://use.typekit.net/xvr7mzu.css';
</style>

<script>
import AddressesPicker from '@/views/modals/AddressesPicker.vue';
import AddressForm from '@/views/modals/AddressForm.vue';
import PaymentForm from '@/views/modals/PaymentForm.vue';
import SendMessageModal from '@/views/modals/SendMessageModal.vue';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'App',
  components: {
    AddressesPicker,
    AddressForm,
    PaymentForm,
    SendMessageModal,
  },
  created() {
    // Parse user data into store
    this.$auth.ready(() => {
      if (!this.$auth.check()) {
        this.clearUser();
      }
    });
  },
  computed: {
    ...mapState({
      showJobEditModal: state => state.jobsModule.showJobEditModal,
    }),
    alertCountDown() {
      return this.$store.state.alertCountDown;
    },
    alertVariant() {
      return this.$store.state.alertVariant;
    },
    alertContent() {
      return this.$store.state.alertContent;
    },
  },
  methods: {
    ...mapMutations('auth', {
      clearUser: 'CLEAR_USER',
    }),
    countDownChanged(dismissCountDown = 0) {
      this.$store.commit('SET_ALERT_COUNT_DOWN', dismissCountDown);
    },
  },
};
</script>
