<template lang="pug">
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapMutations, mapActions } from 'vuex';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      //
    };
  },
  created() {
    // Parse user data into store
    this.logout();
  },
  methods: {
    ...mapMutations('auth', {
      clearUser: 'CLEAR_USER',
      setOutlet: 'SET_SELECTED_OUTLET_ID',
      setRegion: 'SET_SELECTED_REGION_ID',
    }),
    ...mapActions('auth', ['updateUser']),
    logout() {
      this.clearUser();
      this.$auth.logout();
      this.$router.go();
    },
  },
};
</script>
