<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form
      class="comment-form"
      @submit.stop.prevent="handleSubmit(sendComment)"
      novalidate="novalidate"
    >
      <div class="user-avatar">
        <img v-if="user.profile_image" alt="" :src="user.profile_image" /><template v-else>{{
          createLetterAvatar(user.first_name, user.last_name)
        }}</template>
      </div>
      <div class="form-holder" :class="{ active: isSubmitActive }">
        <b-input-group
          ><template v-slot:description>{{ commentDescription }}</template>
          <b-form-textarea
            id="textarea"
            v-model="form.comment"
            name="comment"
            placeholder="Write a note..."
            rows="4"
            @keydown="handleSubmit(() =&gt; handleCmdEnter($event))"
            aria-describedby="comment-feedback"
          ></b-form-textarea>
          <ValidationProvider
            name="File"
            rules="ext:jpeg,jpg,png,bmp,pdf,doc,docx|fileSize:10024"
            ref="fileValidator"
          >
            <b-form-file
              class="d-none"
              :value="form.file"
              @change="handleFileChange"
              plain="plain"
              ref="fileInput"
            ></b-form-file>
          </ValidationProvider>
          <b-input-group-append v-show="parent !== 'customer'">
            <b-button size="sm" variant="light" @click="$refs.fileInput.$el.click()">
              <svgicon name="paperclip"></svgicon>
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <!-- <div style="margin-top: 10px" v-if="isOnlineOrder">
          <b-form-checkbox class="custom-checkbox-button" v-model="showInReceipt" name="status">
            Display this note in the receipt.
          </b-form-checkbox>
        </div> -->

        <div class="form-actions">
          <FilePreview :file="form.file" :error="fileError"></FilePreview>
          <b-button
            class="button-with-loader"
            type="submit"
            variant="primary"
            v-wait:disabled="'Comment Form'"
          >
            <v-wait for="Comment Form"
              ><template slot="waiting">
                <div>
                  <b-spinner small=""></b-spinner>
                </div> </template
              >Submit</v-wait
            >
          </b-button>
        </div>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import FilePreview from './FilePreview.vue';
import '@/icons/paperclip';

export default {
  name: 'CommentForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    FilePreview,
  },
  props: {
    commentItem: Object,
    parent: String,
  },
  data() {
    return {
      form: {
        comment: '',
        file: null,
      },
      fileError: null,
      showInReceipt: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      createCommentsCallback: state => state.commentModal.createCommentsCallback,
      isOnlineOrder: state => state.commentModal.isOnlineOrder,
    }),
    commentDescription() {
      const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
      return isMac ? 'Press Command + Enter to submit' : 'Press Ctrl + Enter to submit';
    },
    isSubmitActive() {
      return this.form.comment.length > 1 || this.form.file || this.fileError;
    },
  },
  methods: {
    // TODO error when enter
    handleCmdEnter(e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
        e.preventDefault();
        this.sendComment();
      }
    },
    sendComment() {
      const showInReceiptPrefix = 'Receipt Note:';

      if (this.showInReceipt) {
        this.form.comment = `${showInReceiptPrefix} ${this.form.comment}`;
      }
      this.$wait.start('Comment Form');
      this.createCommentsCallback(this.form)
        .then(() => {
          this.$store.dispatch('showSuccessAlert', 'New comment successfully created!');
        })
        .catch(error => {
          this.$store.dispatch('showErrorAlert', error);
        })
        .finally(() => {
          this.$wait.end('Comment Form');
          this.form = {
            comment: '',
            file: null,
          };
          this.$root.$emit('commentsRefreshed');
        });
    },
    async handleFileChange(e) {
      this.fileError = '';
      this.form.file = null;
      const { valid } = await this.$refs.fileValidator.validate(e);

      if (valid) {
        [this.form.file] = e.target.files;
      } else {
        this.fileError = 'File must be an image, pdf or doc/docx';
      }
    },
  },
};
</script>
