// eslint-disable-next-line import/prefer-default-export
export const SIZES = [
  {
    text: 'Large',
    value: 'l',
    readableName: 'Large',
  },
  {
    text: 'Medium',
    value: 'm',
    readableName: 'Medium',
  },
  {
    text: 'Small',
    value: 's',
    readableName: 'Small',
  },
  {
    text: 'Extra Small',
    value: 'extra-small',
    readableName: 'Extra Small',
  },
  {
    text: 'Non-Dresden',
    value: 'nd',
    readableName: 'Non-Dresden',
  },
];
