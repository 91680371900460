<template>
  <b-container>
    <b-row>
      <b-col class="mb-3" cols="4" v-for="file in files" :key="file.id">
        <div class="customer-file-preview" @click="() => handleClickFile(file)">
          <b-img :src="getFileThumbnail(file)" />
          <div class="file-info">
            <div class="basics">
              <span class="name">
                {{ file.file_name }}
              </span>
              <span class="date">
                {{ (file.updated_at || file.created_at) | moment('Do MMM YYYY') }}
              </span>
            </div>
            <div class="options">
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-if="selectedFile"
      v-model="showModal"
      size="xl"
      modal-class="modal-file"
      header-class="header"
      footer-class="footer"
      centered
    >
      <template #modal-title>
        <span class="file-name">
          {{ selectedFile.file_name }}
        </span>
        <span class="file-date text-muted">
          {{ (selectedFile.updated_at || selectedFile.created_at) | moment('from', 'now') }}
        </span>
      </template>
      <template #default>
        <div class="image-container">
          <b-img :src="getFileImage(selectedFile)" fluid />
          <div class="my-3">{{ selectedFile.caption }}</div>
        </div>
      </template>
      <template #modal-footer>
        <a
          :href="selectedFile.full_path"
          :download="selectedFile.file_name"
          ref="downloadLink"
          target="_blank"
          class="d-none"
        />
        <b-button variant="outline-secondary" disabled>Share</b-button>
        <b-button variant="outline-secondary" @click="downloadFile"> Download </b-button>
        <b-button variant="danger" @click="remove">
          <b-spinner class="text-center" v-if="isLoading" small />
          <span v-else>Remove File</span>
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import apiFiles from '@/api/files';

export default {
  name: 'CustomerFileCard',
  props: {
    customer: Object,
  },
  data() {
    return {
      showModal: false,
      selectedFile: null,
      isLoading: false,
      defaultPdfThumbnail: require('../assets/document.png'), // eslint-disable-line
    };
  },
  computed: {
    ...mapGetters('customer', ['getCustomerAllFiles']),
    files() {
      return this.getCustomerAllFiles(this.customer.id);
    },
  },
  methods: {
    ...mapActions({
      getCustomerFiles: 'customer/getCustomerFiles',
    }),
    ...mapMutations({
      setSelectedFile: 'SET_SELECTED_FILE',
      setSelectedCustomer: 'customer/SET_SELECTED_CUSTOMER',
    }),
    isImage(file) {
      return ['jpg', 'jpeg', 'png', 'bmp'].includes(file.file_name.split('.').pop().toLowerCase());
    },
    getFileThumbnail() {
      return this.defaultPdfThumbnail;

      // if (this.isImage(file)) {
      //   return file.thumbnail_path || file.full_path;
      // }
      // return file.full_thumbnail_path || this.defaultPdfThumbnail;
    },
    getFileImage(file) {
      if (this.isImage(file)) {
        return file.full_path;
      }
      return file.full_thumbnail_path || this.defaultPdfThumbnail;
    },
    remove() {
      this.$bvModal
        .msgBoxConfirm(`Are you sure to remove ${this.selectedFile.file_name}?`, {
          title: 'Please confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelVariant: 'outline-secondary',
          cancelTitle: 'No',
          hideHeaderClose: false,
          centered: true,
          headerClass: 'border-bottom p-3',
          bodyClass: 'px-4 py-2',
          footerClass: 'bg-white py-2 justify-content-center',
        })
        .then(isConfirmed => {
          if (isConfirmed) {
            this.isLoading = true;
            apiFiles
              .deleteFile(this.selectedFile.id)
              .then(() => {
                this.$store.dispatch('showSuccessAlert', 'New comment successfully created!');
                this.removeFile({ customerId: this.customer.id, fileId: this.selectedFile.id });
                this.showModal = false;
              })
              .catch(error => {
                this.$store.dispatch('showErrorAlert', error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        });
    },
    handleClickFile(file) {
      this.setSelectedFile(file);
      this.setSelectedCustomer(this.customer);
      this.$bvModal.show('file-modal');
    },
  },
  created() {
    // getting customer files from old api
    this.getCustomerFiles(this.customer.id);
  },
};
</script>

<style lang="scss">
.customer-file-preview {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 170px;
  width: 210px;
  overflow: hidden;
  justify-content: center;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    object-fit: cover;
    height: 100%;
    max-width: 100%;
  }
}
.modal-file {
  .header {
    border-bottom: 1px solid #dee2e6 !important;
    padding: 1.5rem 1rem !important;
  }
  .footer {
    padding-right: 1.5rem !important;
  }
  .image-container {
    width: 750px;
    display: block;
    text-align: center;
    margin: 1rem auto;
  }
  .file-name {
    font-size: 16px;
    font-weight: 500;
  }
  .file-date {
    margin-left: 18px;
    font-size: 12px;
  }
}

.file-info {
  position: absolute;
  height: 120px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
  mix-blend-mode: normal;
  opacity: 0.8;
  border-radius: 0px 0px 4px 4px;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  padding: 0 10px;

  .basics {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    bottom: 10px;

    span {
      display: block;
    }
    .name {
      font-weight: 500;
    }
    .date {
      font-weight: 400;
      opacity: 0.6;
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 20px;
    height: 10px;
    position: absolute;
    right: 10px;
    bottom: 16px;

    &:hover {
      cursor: pointer;
    }

    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: white;
    }
  }
}
</style>
