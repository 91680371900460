<template>
  <b-modal id="comments-modal" size="xl" hide-footer>
    <template v-slot:modal-header="{ close }">
      <b-container>
        <div class="modal-title">
          <h3 class="text-bold text-center">Notes</h3>
          <h6 class="text-uppercase text-center">{{ commentModalTitle }}</h6>
          <button type="button" aria-label="Close" class="close" @click="close">
            <i class="di-remove-10"></i>
          </button>
        </div>
      </b-container>
    </template>
    <div class="container">
      <CommentForm :parent="commentModalParent" />
      <hr class="my-4" />
      <CommentsList />
      <CommentUpdateModal />
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import CommentForm from './CommentForm.vue';
import CommentsList from './CommentsList.vue';
import CommentUpdateModal from './CommentUpdateModal.vue';

export default {
  name: 'CommentsModal',
  components: {
    CommentForm,
    CommentsList,
    CommentUpdateModal,
  },
  computed: {
    ...mapState({
      commentModalTitle: state => state.commentModal.commentModalTitle,
      commentModalParent: state => state.commentModal.commentModalParent,
    }),
  },
  data() {
    return {};
  },
};
</script>
