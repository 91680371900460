<template>
  <b-modal
    header-class="modal-header--dialog"
    body-class="modal-body--dialog-form text-left"
    footer-class="modal-footer--dialog justify-content-center"
    title="Assign New Status"
    ok-title="Assign"
    size="sm"
    centered
    id="jobs-status-selector-modal"
    @ok="onSubmit"
  >
    <b-form>
      <b-form-group label="Choose new status" label-for="status" class="mb-0">
        <b-form-select v-model="statusId" name="status">
          <b-form-select-option v-for="status in statuses" :key="status.slug" :value="status.slug">
            {{ status.display_name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import apiOrders from '@/api/orders';

export default {
  name: 'JobsStatusSelectorModal',
  data() {
    return {
      statusId: null,
    };
  },
  computed: {
    status() {
      if (!this.statusId) {
        return null;
      }
      return this.$store.getters.orderItemStatuses.find(status => status.id === this.statusId);
    },
    statuses() {
      const notDisplay = ['refunded-transaction', 'cancelled'];
      return this.$store.getters.orderItemStatuses.filter(
        status => !notDisplay.includes(status.slug),
      );
    },
  },
  methods: {
    onSubmit() {
      apiOrders
        .updateOrderItemsDispenseStatusId(this.$store.getters.selectedOrderItemIds, this.statusId)
        .then(({ data: { data: jobs } }) => {
          jobs.forEach(job => {
            this.$store.commit('UPDATE_JOB_IN_JOBS', job);
          });
          this.$bvModal.hide('jobs-status-selector-modal');
          this.$store.commit('CLEAR_SELECTED_ORDER_ITEMS');
        });
    },
  },
};
</script>
