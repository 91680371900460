<template>
  <div class="d-flex order-item">
    <div id="order-item-detail" class="flex-grow-1">
      <div
        class="rd-deep-1"
        style="margin-bottom: 10px"
        v-for="coupon in coupons"
        :key="coupon.code"
      >
        <b-container fluid>
          <b-row>
            <b-col cols="4" class="d-flex align-items-center">
              <div class="rdd1-part__content d-flex align-items-center">
                <div class="font-weight-medium">{{ coupon.code }}</div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderCoupons',
  props: {
    order: Object,
  },
  computed: {
    coupons() {
      if (this.order.coupons) {
        return this.order.coupons.data;
      }
      return null;
    },
  },
  methods: {
    //
  },
};
</script>
