<template>
  <div>
    <h5 class="mb-4">Choose Shipping Partner</h5>
    <section>
      <b-row>
        <b-col cols="4">
          <h6 class="text-uppercase mb-3">Recipient</h6>
          <p class="lh-md">
            <span>{{ recipient.contact.name }}</span
            ><br /><span>{{ recipient.address.address1 }}</span
            ><br /><span v-if="recipient.address.address2"
              >{{ recipient.address.address2 }}<br /></span
            ><span
              >{{ `${recipient.address.suburb} ${recipient.address.city} ` }}
              {{ `${recipient.address.state} ${recipient.address.postcode}` }}</span
            ><br /><span>{{ recipient.address.country }}</span>
          </p>
        </b-col>
        <b-col cols="4">
          <h6 class="text-uppercase mb-3">Customer Reference</h6>
          <p class="lh-md">
            <span v-if="orderInvoice">Your order no. {{ orderInvoice }}</span
            ><span v-else>There is no invoice for this order</span>
          </p>
        </b-col>
        <b-col cols="4">
          <h6 class="text-uppercase mb-3">Description</h6>
          <p class="lh-md">
            <span>{{ parcel.description }}</span
            ><br /><span>Volume {{ `${parcel.volume.value} ${parcel.volume.units}` }}</span
            ><br /><span>Weight {{ `${parcel.weight.value} ${parcel.weight.units}` }}</span
            ><br /><span>Value: ${{ `${parcel.contents.value}` }}</span>
          </p>
        </b-col>
      </b-row>
    </section>
    <section class="bg-danger text-white p-4 rounded mb-3" v-if="!!courierErrors.length">
      <div v-for="courierError in courierErrors" :key="courierError.courier">
        <div class="text-white">
          <span class="text-bold">{{ courierError.courier }} </span
          ><span class="ml-2">{{ courierError.message }}</span>
        </div>
        <div>
          <ul class="mb-0 my-1">
            <li v-for="(errorMessage, index) in courierError.errors" :key="index">
              {{ errorMessage }}
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section>
      <RadioCard
        v-for="courierOption in selectableCouriers"
        :key="courierOption.product_id"
        v-model="selectedCourier"
        name="courierPicker"
        :disabled="!!courierOption.errors"
        :value="courierOption.product_id"
        :label="courierOption.message || courierOption.product_name"
        :headerLabel="`$${courierOption.price}`"
        ><template>
          <b-row v-if="courierOption.product_type === 'aupost'">
            <b-col cols="4">
              <h6 class="text-uppercase mb-3">Schedule</h6>
              <p class="lh-md">
                <span>Drop off nearest Australia Post</span><br /><span
                  >Standard Post Box (Red)</span
                >
              </p>
            </b-col>
          </b-row>
          <b-row v-if="courierOption.product_type === 'sendle'">
            <b-col cols="4">
              <h6 class="text-uppercase mb-3">Schedule</h6>
              <p class="lh-md">
                <span>Pick up from {{ selectedOutletAddress }}</span
                ><br /><span>between</span><br />{{ courierOption.delivery_estimate[0] }} /
                {{ courierOption.delivery_estimate[1] }}
              </p>
            </b-col>
            <b-col cols="4">
              <h6 class="text-uppercase mb-3">Delivery Instructions</h6>
              <p class="lh-md">
                <span>{{ courierOption.delivery_instructions }}</span>
              </p>
            </b-col>
            <b-col cols="4">
              <h6 class="text-uppercase mb-3">Delivery Estimate</h6>
              <p class="lh-md">
                <span>{{ courierOption.delivery_estimate | quoteDeliveryEstimate }}</span>
              </p>
            </b-col>
          </b-row>
        </template>
      </RadioCard>
    </section>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import RadioCard from '@/components/RadioCard.vue';
import _ from 'lodash';

const COURIER_ERROR_DELIMITERS = {
  SENDLE: ' - ',
  AUPOST: '.',
};

export default {
  name: 'CourierPicker',
  components: {
    RadioCard,
  },
  props: {
    recipient: {
      type: Object,
      required: true,
    },
    orderInvoice: String,
    parcel: Object,
    selectedOutletAddress: String,
    couriers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedCourier: this.value,
    };
  },
  watch: {
    selectedCourier(newValue) {
      this.$emit('input', newValue);
    },
  },
  computed: {
    courierErrors() {
      return this.couriers
        .filter(courier => courier.errors && courier.message)
        .map(courier => {
          let [courierName, courierErrorTitle] = courier.message.split('-');
          courierName = courierName.trim().toUpperCase();
          courierErrorTitle = courierErrorTitle.trim();

          const errors = COURIER_ERROR_DELIMITERS[courierName]
            ? courier.errors
                .split(COURIER_ERROR_DELIMITERS[courierName])
                .filter(message => message.length)
                .map(message => _.capitalize(_.toLower(message)).trim())
            : [courier.errors];
          return {
            courier: courierName,
            message: courierErrorTitle,
            errors,
          };
        });
    },
    selectableCouriers() {
      return this.couriers.filter(courier => !courier.errors && !courier.message);
    },
  },
  filters: {
    quoteDeliveryEstimate(est) {
      return est?.length > 0 ? `${dayjs(est[1]).diff(dayjs(est[0]), 'day')} business days` : '';
    },
  },
};
</script>
