<template>
  <div class="file-card" v-if="files && files.length">
    <div v-if="removable" class="remove" @click="removeFile"></div>
    <silent-box v-if="isImage" :gallery="gallery" />
    <b-button v-else variant="text" class="p-0" @click="handleClickFile">
      <svgicon name="document" width="36" height="36" />
      <span>{{ type }}</span>
    </b-button>
  </div>
</template>

<script>
import '@/icons/document';

export default {
  name: 'FileCard',
  props: {
    files: [Array, Object],
    removable: Boolean,
  },
  computed: {
    gallery() {
      return this.files.map(f => ({
        src: f.full_path,
        thumbnailWidth: '200px',
      }));
    },
    type() {
      return this.files[0].file_name?.split('.').pop();
    },
    isImage() {
      return ['jpg', 'jpeg', 'png', 'bmp'].includes(this.type);
    },
  },
  methods: {
    handleClickFile() {
      window.open(this.files[0].full_path, '_blank');
    },
    removeFile() {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete file.', {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelVariant: 'outline-secondary',
          cancelTitle: 'No',
          hideHeaderClose: false,
          centered: true,
          headerClass: 'border-bottom p-3',
          bodyClass: 'p-4',
          footerClass: 'bg-white p-2 justify-content-center',
        })
        .then(value => {
          if (value) {
            this.$emit('removeFile', this.files[0].id);
          }
        });
    },
  },
};
</script>

<style scoped>
.file-card {
  display: inline-block;
  width: auto;
  position: relative;
  padding-right: 20px;
  padding-top: 5px;
}
.remove {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: 1px solid #000;
  background-color: #efefef;
  z-index: 5;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAiElEQVR42r2RsQrDMAxEBRdl8SDcX8lQPGg1GBI6lvz/h7QyRRXV0qUULwfvwZ1tenw5PxToRPWMC52eA9+WDnlh3HFQ/xBQl86NFYJqeGflkiogrOvVlIFhqURFVho3x1moGAa3deMs+LS30CAhBN5nNxeT5hbJ1zwmji2k+aF6NENIPf/hs54f0sZFUVAMigAAAABJRU5ErkJggg==)
    no-repeat;
  text-align: right;
  border: 0;
  cursor: pointer;
}

.file-card:hover .remove {
  display: inline;
}
.remove:hover {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAqklEQVR4XqWRMQ6DMAxF/1Fyilyj2SmIBUG5QcTCyJA5Z8jGhlBPgRi4TmoDraVmKFJlWYrlp/g5QfwRlwEVNWVa4WzfH9jK6kCkEkBjwxOhLghheMWMELUAqqwQ4OCbnE4LJnhr5IYdqQt4DJQjhe9u4vBBmnxHHNzRFkDGjHDo0VuTAqy2vAG4NkvXXDHxbGsIGlj3e835VFNtdugma/Jk0eXq0lP//5svi4PtO01oFfYAAAAASUVORK5CYII=');
}
</style>
