import _ from 'lodash';
import apiOrders from '@/api/orders';

const initialState = {
  shippingAddress: null,
  addressCustomer: undefined,
  selectedBillingAddressId: undefined,
  addressFormCallback: null,
  newAddressFormData: undefined,
};

const mutations = {
  SET_SHIPPING_ADDRESS(state, addresses) {
    state.shippingAddress = addresses;
  },
  SET_ADDRESS_CUSTOMER(state, customer) {
    state.addressCustomer = customer;
  },
  SET_SELECTED_BILLING_ADDRESS_ID(state, billingAddressId) {
    state.selectedBillingAddressId = billingAddressId;
  },
  SET_ADDRESS_FORM_CALLBACK(state, { callback }) {
    state.addressFormCallback = callback;
  },
  SHOW_NEW_ADDRESS_FORM(state, data) {
    state.newAddressFormData = data;
    data.vm.$bvModal.show('address-form-modal');
  },
  HIDE_NEW_ADDRESS_FORM(state) {
    state.newAddressFormData = undefined;
  },
};

const actions = {
  openCreateShippingOrderModal({ commit }, { order, vm }) {
    commit('SET_SELECTED_ORDER_ID', order.id, { root: true });
    const { item_groups: itemGroups } = order;
    const [itemGroup] = itemGroups || [];
    const [orderItem] = itemGroup?.order_items || [];

    const [defaultAddress] = _.orderBy(
      order?.customer?.billing_addresses,
      ['updated_at'],
      ['desc'],
    );

    let billingAddress = _.get(order, 'order_billing.billing_address');
    const deliveryAddressId = orderItem?.delivery_address_id;

    // DW-661
    if (!billingAddress?.address) {
      billingAddress = defaultAddress;
    }

    let deliveryAddress;
    if (deliveryAddressId) {
      deliveryAddress = order?.customer?.billing_addresses.find(
        bAddress => bAddress.id === deliveryAddressId,
      );
    }

    commit(
      'SET_SELECTED_BILLING_ADDRESS_ID',
      deliveryAddress?.id || billingAddress?.id || defaultAddress?.id,
    );

    commit('SET_ADDRESS_FORM_CALLBACK', {
      callback: () => {
        apiOrders
          .getOrder(order.id)
          .then(({ data: { data: job } }) => {
            commit('UPDATE_JOB_IN_JOBS', job, { root: true });
          })
          .then(this.hideAddAddressModal);
      },
    });

    commit('SET_SHIPPING_ADDRESS', deliveryAddress || billingAddress || defaultAddress);
    commit('SET_ADDRESS_CUSTOMER', _.get(order, 'customer'));
    vm.$bvModal.show('create-shipping-order-modal');
  },
};

const moduleGetters = {
  allCurrentAddresses(state, getters) {
    const allAddresses = [state.shippingAddress, ...getters.currentBillingAddresses];
    const allValidAddresses = allAddresses.filter(address => !!_.get(address, 'address.id'));
    return _.uniqBy(allValidAddresses, 'address.id').map(address => ({
      ...address,
      address: {
        ...address.address,
        id: address.address.id.toString(),
      },
    }));
  },
  currentBillingAddresses(state, getters, rootState, rootGetters) {
    return rootGetters.selectedOrder?.customer?.billing_addresses || [];
  },
  selectedBillingAddress(state, getters) {
    return getters.allCurrentAddresses.find(
      address => address.id === state.selectedBillingAddressId,
    );
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
