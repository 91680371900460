import axios from 'axios';

export default {
  getLensAddonCombination(params = {}) {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/product-lens-addons`, {
      params,
    });
  },
  getAllSuppliers() {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/suppliers`);
  },
  getLensType(regionProductId) {
    return axios.get(
      `${process.env.VUE_APP_NEW_API_URL}/staff/region-products/${regionProductId}/lens-type`,
    );
  },
};
