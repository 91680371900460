import axios from 'axios';

export default {
  createFile(file) {
    if (!file) return null;

    const formData = new FormData();
    formData.append('file', file);

    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/staff/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  attachFile(files, prescriptionId, customerId) {
    return axios.put(
      `${process.env.VUE_APP_API_V4_URL}/v4/users/${customerId}/prescriptions/${prescriptionId}/files`,
      {
        files,
      },
    );
  },
  getFileById(id) {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/files/${id}`);
  },
  deleteFile(id) {
    return axios.delete(`${process.env.VUE_APP_NEW_API_URL}/staff/files/${id}`);
  },
};
