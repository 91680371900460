<template>
  <li>
    <div class="rd-deep-1" :class="visible ? 'expanded' : ''">
      <b-container fluid>
        <b-row>
          <b-col cols="6">
            <div class="rdd1-part--with-icon">
              <div class="rdd1-part rdd1-part__icon" v-if="collapsible">
                <b-button
                  variant="icon"
                  @click="visible = !visible"
                  class="btn-icon--collapse"
                  :class="visible ? '' : 'collapsed'"
                >
                  <i class="di-arrow-down-12 when-closed"></i>
                  <i class="di-arrow-up-12 when-opened"></i>
                </b-button>
              </div>
              <div class="rdd1-part__content">
                <div class="font-weight-medium">Prescription</div>
                <small v-if="!showProvider" class="text-muted">
                  {{ prescription.created_at | moment('from', 'now') }}
                </small>
                <b-row v-else no-gutters class="text-muted">
                  <small class="col-3">
                    {{ prescription.created_at | moment('from', 'now') }}
                  </small>
                  <small class="col-1">
                    <span class="dot dot--xs dot--secondary" />
                  </small>
                  <small class="col-8" v-html="provider" />
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col cols="1">
            <b-badge pill variant="purple" v-if="prescription.source.data.slug">
              {{ prescription.source.data.slug.toUpperCase() }}
            </b-badge>
          </b-col>
          <b-col cols="5" class="flex-column d-flex">
            <span>{{ prescription.type_id == 1 ? 'Spectacle' : 'Contact Lens' }}</span>
            <span class="text-muted small"> Expiry Date: {{ formattedExpiryDate }} </span>
          </b-col>
        </b-row>
      </b-container>
      <div class="rdd1-part rdd1-part__icon rdd1-part__radio" v-if="selectable">
        <b-form-radio
          v-model="$store.state.jobsModule.selectedPrescription"
          :value="prescription"
          @change="onChangePrescription"
        >
        </b-form-radio>
      </div>
    </div>
    <b-collapse :visible="visible || !collapsible">
      <div class="rd-deep-2">
        <b-container fluid>
          <b-row>
            <b-col cols="6">
              <h6 class="text-uppercase">Prescription</h6>
              <p v-if="prescription.standardised_prescription_display_array">
                <PrescriptionDetail
                  :prescription="prescription"
                  :editOnPagePrescription="editOnPagePrescription"
                />
              </p>
            </b-col>
            <b-col cols="6">
              <h6 class="text-uppercase">Category</h6>
              <b-badge pill variant="blue" class="text-uppercase">{{ category }}</b-badge>
            </b-col>
          </b-row>
          <PrismDisplay :prescription="prescription" />
          <b-row>
            <b-col cols="12">
              <div v-if="prescription.note" class="mb-3">
                <h6 class="text-uppercase">Notes</h6>
                <div>{{ prescription.note }}</div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="rdd2__footer d-flex justify-content-end">
                <div v-if="!isCG">
                  <b-button class="mr-3" variant="icon-text-transparent" @click="edit">
                    <span class="icon">
                      <svgicon name="edit" width="16" height="16" />
                    </span>
                    <span class="text">Edit</span>
                  </b-button>
                  <b-button variant="icon-text-transparent" @click="remove">
                    <span class="icon">
                      <svgicon name="remove" width="12" height="12" />
                    </span>
                    <span class="text">Remove</span>
                  </b-button>
                </div>
                <div v-else>
                  <b-button
                    class="mr-3"
                    variant="icon-text-transparent"
                    @click="sendByEmail"
                    :disabled="isEmailSending"
                  >
                    <span class="icon">
                      <svgicon name="send" width="16" height="16" />
                    </span>
                    <span class="text">Send By Email</span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-collapse>
    <ErrorMessage v-if="isSelected && !!prescription.error" type="tooltip">
      {{ prescription.error }}
    </ErrorMessage>
  </li>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import ErrorMessage from '@/components/ErrorMessage.vue';
import prescriptionsApi from '@/api/prescriptions';
import PrismDisplay from '@/components/PrismDisplay.vue';
import PrescriptionDetail from './PrescriptionDetail.vue';
import '@/icons/remove';
import '@/icons/edit';
import '@/icons/send';
import '@/icons/paperclip';

export default {
  name: 'PrescriptionListItem',
  components: {
    PrescriptionDetail,
    PrismDisplay,
    ErrorMessage,
  },
  props: {
    prescription: Object,
    customer: Object,
    selectable: Boolean,
    collapsible: Boolean,
    expanded: Boolean,
    showProvider: Boolean,
    showFile: Boolean,
    editOnPagePrescription: Function,
  },
  data() {
    return {
      visible: false,
      isEmailSending: false,
    };
  },
  mounted() {
    this.visible = this.expanded;
  },
  computed: {
    ...mapGetters(['getStaffUsername', 'getStaffOutletName']),
    ...mapState('jobsModule', ['selectedPrescription']),
    ...mapGetters('jobsModule', ['selectedLensesType']),
    category() {
      return this.prescription.category.replace('class-', '');
    },
    isCG() {
      return this.prescription.source.data.slug === 'cg';
    },
    provider() {
      if (this.prescription.source.data.origin === 'external') {
        return `Provided by ${
          this.customer.full_name ? this.customer.full_name.split(' ').shift() : this.customer.name
        }`;
      }
      const { staff_user_id: staffId } = this.prescription;
      return `Created by 
        <span class="badge badge-pill badge-light">
          ${this.getStaffUsername(staffId) || ''}
        </span> 
        at ${this.getStaffOutletName(staffId) || ''}
      `;
    },
    file() {
      if (this.prescription.files) {
        return this.prescription.files[0];
      }
      return null;
    },
    isSelected() {
      return this.prescription.id === this.selectedPrescription?.id;
    },
    formattedExpiryDate() {
      if (!this.prescription.expiry_date_stamp) return 'Unspecified';
      return this.$moment(this.prescription.expiry_date_stamp, 'YYYY-MM-DD').format('Do MMM YYYY');
    },
  },
  methods: {
    ...mapMutations({
      setSelectedPrescription: 'SET_SELECTED_PRESCRIPTION',
      setSelectedFile: 'SET_SELECTED_FILE',
    }),
    onChangePrescription(prescription) {
      // validation
      if (
        this.selectedLensesType === 'multifocal' &&
        prescription.add_near === null &&
        (prescription.left_near_add === null || prescription.right_near_add === null)
      ) {
        this.$set(this.prescription, 'error', 'Multifocal glasses requires `add near` data');
      } else if (
        (this.selectedLensesType === 'desk' || this.selectedLensesType.includes('work')) &&
        prescription.int_add === null
      ) {
        this.$set(this.prescription, 'error', 'Desk or Work glasses requires `int add` data');
      } else {
        this.$delete(this.prescription, 'error');
      }
    },
    edit() {
      this.$emit('edit', {
        prescription: this.prescription,
      });
    },
    remove() {
      const h = this.$createElement;
      const message = h('span', {
        domProps: {
          innerHTML: `Are you sure want to delete <b>${this.customer.full_name}</b>’s prescription? This action can not be undone.`,
        },
      });
      this.$dialog('Delete Prescription', [message]).then(value => {
        if (value) {
          prescriptionsApi
            .deletePrescription(this.prescription.id)
            .then(() => {
              this.$emit('deleted', this.prescription.id);
              this.$store.dispatch('showSuccessAlert', 'Deleted Successfully');
            })
            .catch(({ response }) => {
              this.$store.dispatch('showErrorAlert', this._.get(response, 'data.error', 'Error'));
            });
        }
      });
    },
    handleViewFile() {
      this.setSelectedPrescription(this.prescription);
      this.setSelectedFile(this.file);
      this.$bvModal.show('file-modal');
    },
    sendByEmail() {
      const message = this.$createElement('div', null, [
        `Would you like to send this prescription to ${this.customer.full_name}, by email to `,
        this.$createElement('span', { class: 'text-medium' }, `${this.customer.email}`),
        '?',
      ]);
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Send prescription by email',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Send',
          cancelVariant: 'outline-secondary',
          cancelTitle: 'Cancel',
          hideHeaderClose: false,
          centered: true,
          headerClass: 'border-bottom p-3',
          bodyClass: 'p-4',
          footerClass: 'bg-white p-2 justify-content-center',
        })
        .then(confirm => {
          if (confirm) {
            this.isEmailSending = true;
            prescriptionsApi
              .sendEmail(this.prescription.id)
              .then(res => {
                if (!res.error) {
                  this.$store.dispatch('showSuccessAlert', 'Email Sent Successfully');
                } else {
                  this.$store.dispatch('showErrorAlert', "Email Couldn't Send");
                }
              })
              .catch(({ response }) => {
                this.$store.dispatch('showErrorAlert', this._.get(response, 'data.error', 'Error'));
              })
              .finally(() => {
                this.isEmailSending = false;
              });
          }
        });
    },
  },
};
</script>
