<template>
  <b-modal
    header-class="modal-header--dialog"
    body-class="modal-body--dialog-form text-left"
    footer-class=""
    title="Receipt Note"
    size="xl"
    hide-footer=""
    centered
    id="receipt-note-modal"
    :busy="isLoading"
    @show="onShow"
  >
    <b-form>
      <b-form-group
        label="The customer will see this note on their receipt."
        label-for="note"
        class="mb-0"
      >
        <b-form-textarea
          id="note"
          v-model="noteText"
          placeholder="Enter note"
          rows="3"
          max-rows="12"
          :disabled="isLoading"
        ></b-form-textarea>
      </b-form-group>
      <b-button
        variant="primary"
        type="submit"
        class="mt-3"
        @click.prevent="updateComment"
        :disabled="isLoading"
      >
        {{ note ? 'Update' : 'Create' }}
      </b-button>
    </b-form>
    <div v-if="isLoading" class="text-center mt-3">
      <b-spinner />
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import apiNotes from '@/api/notes';
import apiOrders from '@/api/orders';

export default {
  name: 'ReceiptNoteModal',
  data() {
    return {
      note: null,
      noteText: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('commentModal', {
      selectedUpdateNote: state => state.selectedUpdateNote,
      selectedNoteOrder: state => state.selectedNoteOrder,
    }),
  },
  methods: {
    onShow() {
      if (this.selectedUpdateNote) {
        this.note = this.selectedUpdateNote;
        this.noteText = this.selectedUpdateNote.content.replace('Receipt Note:', '').trim();
      } else {
        this.note = null;
        this.noteText = null;
      }
    },
    updateComment() {
      if (this.note == null) {
        this.isLoading = true;

        apiNotes
          .createOrderNote(this.selectedNoteOrder, {
            content: `Receipt Note: ${this.noteText}`,
          })
          .then(() => {
            apiOrders.getOrder(this.selectedNoteOrder).then(({ data: { data: job } }) => {
              this.$store.commit('UPDATE_JOB_IN_JOBS', job);
              this.isLoading = false;
              this.$bvModal.hide('receipt-note-modal');
              this.$store.dispatch('showSuccessAlert', 'Receipt Note Created Successfully');
            });
          })
          .catch(e => {
            this.isLoading = false;
            this.$store.dispatch('showErrorAlert', e.response.data.message);
          });
      } else {
        this.isLoading = true;
        apiNotes
          .updateOrderNoteReceipt(this.selectedNoteOrder, this.note.id, {
            content: `Receipt Note: ${this.noteText}`,
          })
          .then(() => {
            apiOrders.getOrder(this.selectedNoteOrder).then(({ data: { data: job } }) => {
              this.$store.commit('UPDATE_JOB_IN_JOBS', job);
              this.isLoading = false;
              this.$bvModal.hide('receipt-note-modal');
              this.$store.dispatch('showSuccessAlert', 'Receipt Note Created Successfully');
            });
          })
          .catch(e => {
            this.isLoading = false;
            this.$store.dispatch('showErrorAlert', e.response.data.message);
          });
      }
    },
  },
};
</script>

<style scoped>
.refund-warning {
  font-weight: 500;
}
</style>
