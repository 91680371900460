<template>
  <div>
    <h3 class="text-bold text-center text-white mb-4-5">Enter New Password</h3>
    <ValidationObserver v-slot="{ invalid }">
      <b-form @submit.stop.prevent="handleSubmit">
        <ValidationProvider rules="required|email" name="email" v-slot="validationContext">
          <b-form-group class="mb-2-5">
            <b-form-input
              v-model="form.email"
              type="email"
              autocomplete="off"
              size="lg"
              class="form-control--dark"
              placeholder="Email"
              autofocus
              :state="getValidationState(validationContext)"
            >
            </b-form-input>

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          :rules="{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/ }"
          v-slot="validationContext"
          vid="password"
        >
          <b-input-group class="mb-2-5">
            <b-form-input
              v-model="form.password"
              :type="passwordInput.type"
              autocomplete="off"
              size="lg"
              class="form-control--dark"
              placeholder="Password"
              autofocus
              :state="getValidationState(validationContext)"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button size="sm" @click="handleShowPassword">
                <svgicon :name="passwordInput.icon" width="16" height="16" color="#ffffff" />
              </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback>
              Password should be <b>minimum eight</b> characters, at least
              <b>one uppercase letter</b>, one <b>lowercase letter</b> and <b>one number</b>
            </b-form-invalid-feedback>
          </b-input-group>
        </ValidationProvider>
        <ValidationProvider rules="required|confirmed:password" v-slot="validationContext">
          <b-input-group class="mb-2-5">
            <b-form-input
              v-model="form.password_confirmation"
              :type="passwordConfirmInput.type"
              autocomplete="off"
              size="lg"
              class="form-control--dark"
              placeholder="Confirm Password"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-input-group-append>
              <b-button size="sm" @click="handleShowPasswordConfirm">
                <svgicon :name="passwordConfirmInput.icon" width="16" height="16" color="#ffffff" />
              </b-button>
            </b-input-group-append>
            <b-form-invalid-feedback>
              Confirm password didn't match the password above
            </b-form-invalid-feedback>
          </b-input-group>
        </ValidationProvider>

        <b-button type="submit" block variant="primary" size="lg" :disabled="isLoading || invalid">
          <b-spinner v-if="isLoading" small></b-spinner>
          <span v-else>Confirm</span>
        </b-button>
      </b-form>
    </ValidationObserver>
    <div class="d-block mt-5 text-center">
      <b-link to="/login">Back to login</b-link>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import authApi from '../api/auth';
import '@/icons/eye';
import '@/icons/eye-slash';

export default {
  name: 'ForgotPassword',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      passwordInput: {
        type: 'password',
        icon: 'eye',
      },
      passwordConfirmInput: {
        type: 'password',
        icon: 'eye',
      },
      form: {
        email: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  methods: {
    handleSubmit() {
      this.isLoading = true;
      const { token } = this.$route.params;

      authApi
        .updatePassword({ ...this.form, token })
        .then(({ data }) => {
          this.$store.dispatch('showSuccessAlert', data.message);
          this.$router.push({ name: 'login' });
        })
        .catch(({ response }) => {
          this.$store.dispatch('showErrorAlert', response.data.error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleShowPassword() {
      const { type } = this.passwordInput;
      this.passwordInput =
        type === 'text' ? { type: 'password', icon: 'eye' } : { type: 'text', icon: 'eye-slash' };
    },
    handleShowPasswordConfirm() {
      const { type } = this.passwordConfirmInput;
      this.passwordConfirmInput =
        type === 'text' ? { type: 'password', icon: 'eye' } : { type: 'text', icon: 'eye-slash' };
    },
  },
};
</script>
