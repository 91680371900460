<template lang="pug">
  div
    h3.text-bold.text-center.text-white.mb-4-5 Welcome back
    p.text-danger.text-center(v-if='error') {{ error }}
    ValidationObserver(v-slot="{ handleSubmit, invalid }")
      b-form(@submit.stop.prevent='handleSubmit(onSubmit)' novalidate)
        ValidationProvider(name="E-mail address"
        rules="required|email"
        v-slot="validationContext")
          b-form-group.mb-2-5
            b-form-input.form-control--dark(name='email'
            v-model='email' type='email' placeholder='E-mail address'
            :state="getValidationState(validationContext)"
            size='lg' autofocus="")
            b-form-invalid-feedback
              |{{ validationContext.errors[0] }}
        ValidationProvider(name="Password"
        rules="required|minLength:3"
        v-slot="validationContext")
          b-form-group.mb-2-5
            b-form-input.form-control--dark(name='password'
            v-model='password' type='password' placeholder='Password'
            :state="getValidationState(validationContext)"
            size='lg')
            b-form-invalid-feedback
              |{{ validationContext.errors[0] }}
        b-button(type='submit' block='' variant='primary' size='lg'
        :disabled='isLoading || invalid')
          b-spinner(v-if='isLoading' small='')
          span(v-if='!isLoading') Sign in
    .d-block.mt-5.text-center
      b-link(to='forgot-password') Forgot password?
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState, mapMutations } from 'vuex';
import storage from '../support/storage';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      email: null,
      password: null,
      error: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('auth', {
      currentUser: state => state.user,
    }),
  },
  created() {
    // Parse user data into store
    storage.del('me');
    this.$auth.ready(() => {
      if (this.$auth.check()) {
        this.$router.push({ name: 'customers' });
      }
    });
  },
  methods: {
    ...mapMutations('auth', {
      setUser: 'SET_USER',
    }),
    onSubmit() {
      this.isLoading = true;
      this.error = null;
      this.$auth.login({
        data: {
          email: this.email,
          password: this.password,
          domain: document.domain,
        },
        success: response => {
          this.setUser(response.data.data.user);
          this.$auth.user(this.currentUser);
          this.isLoading = false;
        },
        error: () => {
          this.error = 'Unauthorized';
          this.isLoading = false;
        },
        redirect: {
          name: 'customers',
        },
      });
    },
  },
};
</script>
