<template>
  <ValidationProvider
    :name="name"
    :rules="{ required }"
    mode="passive"
    v-slot="{ errors, failed }"
    ref="telValidator"
  >
    <vue-tel-input
      :id="name"
      :value="value"
      :dropdownOptions="telDropdownOptions"
      :inputOptions="telInputOptions"
      :autoDefaultCountry="false"
      mode="international"
      :styleClasses="{ 'vue-tel-input--custom': true, invalid: failed }"
      invalidMsg="Invalid Phone Number"
      autoFormat
      validCharactersOnly
      :defaultCountry="defaultCountry"
      :preferredCountries="preferredCountries"
      @input="telCountryCodeInput"
    />
    <div class="invalid-feedback d-block">
      {{ errors[0] }}
    </div>
  </ValidationProvider>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: String,
    name: String,
    required: Boolean,
  },
  data() {
    return {
      defaultCountry: '',
      telDropdownOptions: {
        showFlags: true,
        showDialCodeInList: true,
        showDialCodeInSelection: false,
        tabindex: 0,
      },
      telInputOptions: {
        autocomplete: 'off',
        placeholder: 'Please enter a phone number',
      },
    };
  },
  computed: {
    ...mapGetters(['locationSelectorRegions']),
    ...mapGetters('auth', ['currentRegion']),
    preferredCountries() {
      // empty string is for unselect the field for those numbers that has not include country code
      return [' ', ...new Set(this.locationSelectorRegions.map(r => r.countryCode))];
    },
  },
  created() {
    if (!this.value) {
      this.defaultCountry = this.currentRegion.country_code;
    }
  },
  methods: {
    telCountryCodeInput(_, { valid, formatted }) {
      if (formatted) {
        if (valid) {
          this.$refs.telValidator.reset();
        } else {
          this.$refs.telValidator.setErrors(['Please enter a valid phone number']);
        }
        this.$emit('input', formatted);
      } else if (!this.required) {
        this.$refs.telValidator.reset();
      }
    },
  },
};
</script>

<style scoped>
.invalid {
  border: 1px solid red !important;
}
</style>

<style lang="scss">
.vue-tel-input--custom {
  .vti__dropdown-item {
    &:not(.preferred) {
      strong {
        font-weight: 500;
      }
    }
    &.last-preferred {
      border-bottom: none !important;
    }
  }
}
</style>
