<template>
  <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="paymentForm">
    <b-modal id="payment-form-modal" size="xl" @show="onShow" @hide="onHide" centered="centered">
      <template v-slot:modal-header="{ close }">
        <b-container>
          <div class="modal-title">
            <h3 class="text-bold text-center">New Payment</h3>
            <h6 class="text-uppercase text-center">For {{ customerName }}</h6>
            <button class="close" type="button" aria-label="Close" @click="close">
              <i class="di-remove-10"></i>
            </button>
          </div>
        </b-container>
      </template>
      <b-container class="modal-content-container">
        <b-form novalidate="novalidate">
          <section>
            <h5 class="mb-4">Transaction Information</h5>
            <h6 class="text-uppercase mb-3">Personal details</h6>
            <b-row>
              <b-col cols="12" sm="6">
                <b-form-group label="Name" label-for="name">
                  <b-form-input v-model="form.name" type="text"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group label="E-mail Address" label-for="email">
                  <b-form-input v-model="form.email" type="email"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group label="Reference / Description" label-for="desc">
                  <b-form-input
                    v-model="form.desc"
                    type="text"
                    placeholder="e.g. for invoice no. AU-1234-ROZ"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </section>
          <div class="divider mt-3 mb-4"></div>
          <section>
            <h6 class="text-uppercase mb-3">Payment details</h6>
            <b-row>
              <b-col cols="6">
                <ValidationProvider
                  name="Amount"
                  vid="amount"
                  rules="required|min_value:1"
                  v-slot="validationContext"
                >
                  <b-form-group label="Amount" label-for="amount">
                    <b-form-input
                      v-model="form.amount"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="e.g. 59"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="auto">
                <ValidationProvider
                  name="Currency"
                  vid="currency"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-group label="Currency" label-for="currency">
                    <b-form-select
                      v-model="form.currency"
                      :options="currencies"
                      :state="getValidationState(validationContext)"
                    ></b-form-select>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="12">
                <b-form-group label="Credit Card Details" label-for="stripe">
                  <Stripe ref="stripe" :currency="form.currency"></Stripe>
                  <b-form-invalid-feedback :force-show="!!stripeError">{{
                    stripeError
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </section>
        </b-form>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <div class="flex-fill m-0">
          <div class="container modal-footer-content">
            <div class="d-flex justify-content-end">
              <b-button class="mr-3" variant="outline-light" @click="cancel">Cancel</b-button>
              <b-button
                type="submit"
                variant="primary"
                @click="handleSubmit(purchase)"
                :disabled="isLoading || invalid"
              >
                <b-spinner v-if="isLoading" small="small"></b-spinner>Pay Now
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import paymentAPI from '@/api/payment';
import noteAPI from '@/api/notes';
import Stripe from '@/components/Stripe.vue';

export default {
  name: 'PaymentForm',
  components: {
    Stripe,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      stripeError: null,
      form: {
        name: '',
        email: '',
        desc: '',
        amount: null,
        currency: '',
      },
    };
  },
  computed: {
    ...mapState({
      paymentFormData: state => state.paymentModal.paymentFormData,
      currentUser: state => state.auth.user,
    }),
    ...mapGetters(['currencies']),
    ...mapGetters('auth', ['currentRegion']),
    customer() {
      let customerData = {};
      if (this.isOrderData) {
        customerData = this._.get(this.paymentFormData, 'customer.customer', {});
        if (customerData) {
          const firstName = this._.get(this.paymentFormData, 'customer.customer.first_name') || '';
          const lastName = this._.get(this.paymentFormData, 'customer.customer.last_name') || '';
          customerData.full_name = firstName + ' ' + lastName;
          customerData.first_name = firstName;
          customerData.last_name = lastName;
        }
        console.log(customerData);
      } else {
        customerData = this._.get(this.paymentFormData, 'customer', {});
      }

      return customerData;
    },
    customerName() {
      if (this.customer) {
        return this._.get(this.customer, 'full_name');
      }
      return 'Guest';
    },
    isOrderData() {
      let data = this._.get(this.paymentFormData, 'customer');
      let checkData = this._.get(data, 'dispense_status_id');
      if (checkData) {
        return true;
      }
      return false;
    },
    orderInvoice() {
      if (this.isOrderData) {
        return this._.get(this.paymentFormData, 'customer.vend_invoice_number');
      }
      return '';
    },
    orderId() {
      if (this.isOrderData) {
        return this._.get(this.paymentFormData, 'customer.id');
      }
      return '';
    },
  },
  methods: {
    onShow() {
      this.populateData();
    },
    getRegion() {
      const customerRegions = this._.get(this.customer, 'regions.data');
      if (customerRegions) {
        const regions = customerRegions.map(item => item.country_code);
        const uniqueRegions = [...new Set(regions)];

        return uniqueRegions[0];
      }
      return 'AU';
    },
    getCurrencyByRegion() {
      const region = this.getRegion();
      switch (region) {
        case 'AU':
          return 'AUD';
        case 'CA':
          return 'CAD';
        case 'NZ':
          return 'NZD';
        default:
          return 'AUD';
      }
    },
    onHide() {
      this.$store.commit('paymentModal/CLEAR_DATA_PAYMENT_FORM');
    },
    populateData() {
      this.isLoading = false;
      this.stripeError = null;
      this.form.name = this.customerName;
      this.form.email = this._.get(this.customer, 'email');
      this.form.currency = this.getCurrencyByRegion();
      this.form.amount = null;
      this.form.desc = this.orderInvoice;
    },
    purchase() {
      this.isLoading = true;
      const stripeComponent = this.$refs.stripe;
      paymentAPI
        .createPaymentIntent({
          amount: this.form.amount,
          currency: this.form.currency,
          desc: this.form.desc,
          source: 'iO Go',
        })
        .then(({ data: { client_secret: clientSecret } }) =>
          stripeComponent.stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: stripeComponent.card,
              billing_details: {
                name: this.form.name,
                email: this.form.email || null,
              },
              metadata: {
                reference: this.form.desc,
              },
            },
          }),
        )
        .then(response => {
          if (response.error) {
            this.stripeError = response.error.message;
            this.$store.dispatch('showErrorAlert', this.stripeError);
            return null;
          }

          let noteContent = `New payment made with ${this.form.currency} ${this.form.amount}`;

          if (this.form.desc) {
            noteContent += `\nReference: ${this.form.desc}`;
          }

          noteContent += `\nPayment intent id: ${response.paymentIntent.id}`;
          noteContent += `\n${process.env.VUE_APP_STRIPE_URL}/payments/${response.paymentIntent.id}`;
          const fullName = this.getFullName(
            this.currentUser.first_name,
            this.currentUser.last_name,
          );
          const outletName = this._.get(this.currentUser, 'outlet.name');
          const outletText = outletName ? `@ ${outletName}` : '';
          noteContent += `\nPayment created by: ${fullName} ${outletText}`;

          if (!this.isOrderData && this.customer && this.customer.id) {
            return noteAPI.createCustomerNote(this.customer.id, null, noteContent);
          } else {
            const form = { content: noteContent };
            return noteAPI.createOrderNote(this.orderId, form);
          }
        })
        .then(result => {
          if (!result) return;
          this.hideCreatePaymentModal();
          this.$store.dispatch('showSuccessAlert', 'Payment successful!');
          this.$root.$emit('commentsRefreshed');
        })
        .catch(result => {
          if (result.response) {
            // Handle new and old API backend error
            const newApiErrorMessage = this._.get(result.response, 'data.message');
            const oldApiErrorMessage = this._.get(result.response, 'data.error');
            const message = newApiErrorMessage || oldApiErrorMessage;
            this.$store.dispatch('showErrorAlert', message);
            const errors = this._.get(result.response, 'data.errors');
            if (errors) {
              this.$refs.paymentForm.setErrors(errors);
            }
          } else {
            // Handle client exception
            const error = result.message;
            this.$store.dispatch('showErrorAlert', `Exception: ${error}`, 'Error');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
