<template>
  <div>
    <h3 class="text-bold text-center text-white mb-4-5">Forgot password?</h3>
    <b-form @submit.stop.prevent="handleSubmit">
      <b-form-group class="mb-2-5">
        <b-form-input
          v-model="form.email"
          type="email"
          size="lg"
          class="form-control--dark"
          placeholder="E-mail address"
          autofocus
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" block variant="primary" size="lg" :disabled="isLoading">
        <b-spinner v-if="isLoading" small></b-spinner>
        <span v-else>Confirm</span>
      </b-button>
    </b-form>
    <div class="d-block mt-5 text-center">
      <b-link to="login">Back to login</b-link>
    </div>
  </div>
</template>

<script>
import authApi from '../api/auth';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      isLoading: false,
      form: {
        email: '',
      },
    };
  },
  methods: {
    handleSubmit() {
      this.isLoading = true;
      authApi
        .resetPassword(this.form)
        .then(({ data }) => {
          this.$store.dispatch('showSuccessAlert', data.message);
        })
        .catch(({ response }) => {
          this.$store.dispatch('showErrorAlert', response.data.error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
