<template>
  <b-modal
    header-class="modal-header--dialog"
    body-class="modal-body--dialog-form text-left"
    footer-class="modal-footer--dialog justify-content-center"
    title="Assign New Location"
    ok-title="Assign"
    size="sm"
    centered
    id="jobs-location-selector-modal"
    @ok="onSubmit"
  >
    <b-form>
      <b-form-group label="Choose new location" label-for="location" class="mb-0">
        <b-form-select v-model="outletId" name="location">
          <b-form-select-option
            v-for="location in locationSelectorOutlets"
            :key="location.id"
            :value="location.id"
          >
            {{ location.abbreviation }} - {{ location.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import apiOrders from '@/api/orders';

export default {
  name: 'JobsLocationSelectorModal',
  data() {
    return {
      outletId: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['locationSelectorOutlets']),
  },
  methods: {
    onSubmit() {
      apiOrders
        .updateOrderItemsDispenseOutletId(this.$store.getters.selectedOrderItemIds, this.outletId)
        .then(() => {
          this.$bvModal.hide('jobs-location-selector-modal');
          this.$store.commit('CLEAR_SELECTED_ORDER_ITEMS');
          this.$emit('submitted');
        });
    },
  },
};
</script>
