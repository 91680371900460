<template>
  <div class="customers">
    <div class="main-content-wrapper">
      <div class="page-header d-flex flex-row">
        <h1 class="m-0 flex-fill font-weight-bold">Customers MEILI</h1>
        <b-button variant="primary" @click="handleClickNewCustomer" size="lg">
          <svgicon name="plus" width="14" height="14" color="#ffffff" class="mr-2"></svgicon>
          <span>New Customer</span>
        </b-button>
      </div>
    </div>
    <div class="main-content-wrapper">
      <CustomersFilter :total-all="totalRows" />
    </div>
    <div class="main-content-wrapper table-holder">
      <CustomersMeiliTable
        :customers="customers"
        :is-loading="isLoading"
        @show-prescription-modal="onShowPrescriptionModal"
        @show-prescription-edit-modal="onShowPrescriptionEditModal"
        @show-pd-modal="onShowPdModal"
        @show-pd-edit-modal="onShowPdEditModal"
        @deleted="onDeleted"
      />
    </div>
    <TablePagination ref="tablePagination" :meta="paginationMeta" :total-rows="totalRows" />
    <TablePaginationInfo :meta="paginationMeta" description="customers" />
    <NewCustomerModal @customer-added="onOk" />
    <NewPdModal :customer="selectedCustomer" :pd="selectedPd" @ok="onOk" />
    <NewPrescriptionModal
      :customer="selectedCustomer"
      :prescription="selectedPrescription"
      @ok="onOk"
    />
    <CommentsModal />
    <FileModal />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import CommentsModal from '@/components/CommentsModal.vue';
import FileModal from '@/components/modals/FileModal.vue';
import CustomersMeiliTable from '@/components/CustomersMeiliTable.vue';
import NewCustomerModal from '../components/NewCustomerModal.vue';
import NewPrescriptionModal from '../components/NewPrescriptionModal.vue';
import NewPdModal from '../components/NewPdModal.vue';
import TablePagination from '../components/TablePagination.vue';
import CustomersFilter from '../components/CustomersFilter.vue';
import apiCustomers from '../api/customers';
import TablePaginationInfo from '../components/TablePaginationInfo.vue';
import '@/icons/plus';

export default {
  name: 'CustomersMeili',
  components: {
    TablePaginationInfo,
    CustomersFilter,
    TablePagination,
    NewPdModal,
    NewPrescriptionModal,
    NewCustomerModal,
    CustomersMeiliTable,
    CommentsModal,
    FileModal,
  },
  data() {
    return {
      totalRows: 0,
      paginationMeta: {},
      selectedCustomer: {},
      selectedPrescription: null,
      selectedPd: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('customer', ['customers']),
  },
  created() {
    const query = this.getQuery();
    this.getCustomers(query);
    this.setStaffs();
    this.$root.$on('commentsRefreshed', () => {
      this.getCustomers(query);
    });
  },
  methods: {
    ...mapMutations('customer', {
      setCustomers: 'SET_CUSTOMERS',
      setSelectedCustomer: 'SET_SELECTED_CUSTOMER',
    }),
    ...mapActions(['setStaffs']),
    getCustomers(params) {
      this.isLoading = true;
      apiCustomers
        .getMeiliCustomers(params)
        .then(response => {
          const data = response.data.data.hits;
          const pagination = response.data;
          this.paginationMeta = response.data;
          this.totalRows = pagination.total;
          this.setCustomers(data);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.$refs.tablePagination) {
            this.$refs.tablePagination.refreshPage();
          }
        });
    },
    onShowPrescriptionEditModal(e) {
      this.selectedCustomer = {
        id: e.customer.id,
        full_name: e.customer.full_name,
        isOnPageEdit: e.customer.isOnPageEdit || false,
      };
      this.selectedPrescription = e.prescription;
      this.$bvModal.show('new-prescription-modal');
    },
    onShowPdModal(e) {
      this.selectedCustomer = {
        id: e.customer.id,
        full_name: e.customer.full_name,
      };
      this.selectedPd = {};
      this.$bvModal.show('new-pd-modal');
    },
    onShowPdEditModal(e) {
      this.selectedCustomer = {
        id: e.customer.id,
        full_name: e.customer.full_name,
      };
      this.selectedPd = e.pd;
      this.$bvModal.show('new-pd-modal');
    },
    onOk() {
      this.getCustomers(this.getQuery());
    },
    onDeleted() {
      this.getCustomers(this.getQuery());
    },
    getQuery(toRoute) {
      const route = toRoute || this.$route;
      const { query } = route;
      return query;
    },
    handleClickNewCustomer() {
      this.setSelectedCustomer(null);
      this.$bvModal.show('new-customer-modal');
    },
  },
  watch: {
    $route(to) {
      const query = this.getQuery(to);
      this.getCustomers(query);
    },
  },
};
</script>
