<template>
  <div class="staffs">
    <h2>Staffs</h2>
  </div>
</template>

<script>
export default {
  name: 'Staffs',
};
</script>
