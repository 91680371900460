import axios from 'axios';

export default {
  getOutlets() {
    return axios.get('/outlet', {
      params: { with: 'address' },
    });
  },
  getOutlet(outletId) {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/outlets/${outletId}`);
  },
};
