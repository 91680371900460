import axios from 'axios';

export default {
  getMeiliOrders(params = {}) {
    if (params.customFilter) return this.handleCustomFilteredOrders(params);
    return axios.get(`${process.env.VUE_APP_API_V4_URL}/v4/get-orders`, {
      params,
    });
  },
  getOrders(params = {}) {
    if (params.customFilter) return this.handleCustomFilteredOrders(params);
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/orders`, {
      params,
    });
  },
  getDresdenGoOrders(params = {}) {
    return axios.get(`${process.env.VUE_APP_WC_API_URL}/dresdengo/getOrders`, {
      params,
    });
  },
  getOrder(id) {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/orders/${id}`);
  },
  handleCustomFilteredOrders(params) {
    if (params.customFilter === 'late-orders') return this.getLateOrders(params);
    if (params.customFilter === 'dresdengo') {
      Object.assign(params, { items_name_like: 'dresdengo' });
      return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/orders`, {
        params,
      });
    }

    throw new Error(`${params.customFilter} custom filter is not implemented`);
  },
  async getLateOrders(params) {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/orders/late-orders`, {
      params,
    });
  },
  async getTotalOrders() {
    const [totalLateOrdersCount, totalOrders] = await Promise.all([
      this.getTotalLateOrdersCount(),
      axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/total-orders`),
    ]);

    let dresdenGoCount = 0;
    let goFilters = [];
    if (totalOrders?.data?.data) {
      if (totalOrders?.data?.data.filter(b => b.slug === 'dresdenGO').length > 0) {
        dresdenGoCount = totalOrders?.data?.data.filter(b => b.slug === 'dresdenGO')[0].total;
      }
      goFilters = totalOrders?.data?.data.filter(b => b.slug !== 'dresdenGO');
    }

    const availableFilters = [
      ...goFilters,
      {
        slug: 'dresdengo',
        display_name: 'Go Orders',
        total: dresdenGoCount,
        customFilter: true,
        defaultQuery: {
          sort_by: 'created_at',
          sort_direction: 'desc',
        },
      },
      {
        slug: 'late-orders',
        display_name: 'Late Orders',
        total: totalLateOrdersCount,
        customFilter: true,
        defaultQuery: {
          sort_by: 'created_at',
          sort_direction: 'asc',
        },
      },
    ];
    return availableFilters;
  },
  async getTotalLateOrdersCount() {
    const { data } = await axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/orders/late-orders`);
    return data?.meta?.total || 0;
  },
  getOrderItemStatuses() {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/order-item-statuses`);
  },
  getOrderPaymentStatuses() {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/order-payment-statuses`);
  },
  getOrderDispenseStatuses() {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/order-dispense-statuses`);
  },
  updateOrderDispenseStatus(orderId, data) {
    return axios.put(
      `${process.env.VUE_APP_NEW_API_URL}/staff/orders/${orderId}/dispense-status`,
      data,
    );
  },
  updateOrderItemDispenseStatusId($itemId, $statusId) {
    return axios.put(
      `${process.env.VUE_APP_NEW_API_URL}/staff/order-items/${$itemId}/dispense-status-id`,
      {
        id: $statusId,
      },
    );
  },
  updateOrderItemsDispenseOutletId($ids, $outletId) {
    return axios.put(`${process.env.VUE_APP_NEW_API_URL}/staff/order-items/dispense-outlet-id`, {
      item_ids: $ids,
      outlet_id: $outletId,
    });
  },
  updateOrderItemsDispenseStatusId($ids, $statusId) {
    return axios.put(`${process.env.VUE_APP_NEW_API_URL}/staff/order-items/dispense-status-id`, {
      item_ids: $ids,
      status_id: $statusId,
    });
  },
  createSendleOrder(payload) {
    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/staff/sendle-orders`, payload);
  },
  createAuPostOrder(payload) {
    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/staff/au-post-orders`, payload);
  },
  getQuote(payload) {
    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/staff/shipments/getquote`, payload);
  },
  getQuoteManuelEntry(payload) {
    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/staff/shipments/manuel-entry`, payload);
  },
  getShipmentLabel(orderId) {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/shipments/get-label/${orderId}`);
  },
  getSupplier(supplierId, order_item_id) {
    return axios.get(
      `${process.env.VUE_APP_NEW_API_URL}/staff/suppliers/${supplierId}?order_item_id=${order_item_id}`,
    );
  },
  listContactsBasedOnOutlet(outletId) {
    return axios.get(
      `${process.env.VUE_APP_API_URL}/supplier_contacts_by_outlet/outlet/${outletId}`,
    );
  },
  orderLens(payload) {
    return axios.post(`${process.env.VUE_APP_API_URL}/order_lens`, payload);
  },
  postOrderNote(data = {}) {
    return axios.post(`/orders/${data.orderId}/note`, data);
  },
  getOrderLensCount(orderId, orderItemId, productId, supplierId) {
    return axios.get(
      `${process.env.VUE_APP_API_URL}/order_lens/count?order_id=${orderId}&order_item_id=${orderItemId}&product_id=${productId}&supplier_id=${supplierId}`,
    );
  },
  getOrderHoyaStatus(orderItemId) {
    return axios.get(
      `${process.env.VUE_APP_API_URL}/order_lens_status_check?order_item_id=${orderItemId}`,
    );
  },

  updateOrderPriority(orderId, orderPriority) {
    return axios.put(`${process.env.VUE_APP_NEW_API_URL}/staff/orders/${orderId}/priority`, {
      priority: orderPriority,
    });
  },
  updateOrderItemAdditionalInfo(orderId, additionalInfoId, data) {
    return axios.put(
      `${process.env.VUE_APP_NEW_API_URL}/staff/order-items/${orderId}/info/${additionalInfoId}`,
      data,
    );
  },
  createOrderItemAdditionalInfo(orderId, data) {
    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/staff/order-items/${orderId}/info`, data);
  },
  getRefundReasons() {
    return axios.get('/refund-reasons');
  },
  requestRefund(orderId, data) {
    return axios.post(`orders/${orderId}/refund`, data);
  },
  sendMessage(orderId, data) {
    return axios.post(`${process.env.VUE_APP_NEW_API_URL}/staff/orders/${orderId}/messages`, data);
  },
  cancelShamirOrder(orderId) {
    return axios.post(`${process.env.VUE_APP_API_URL}/shamir/order/cancel/${orderId}`);
  },
  cancelHoyaOrder(orderId) {
    return axios.get(`${process.env.VUE_APP_API_URL}/order_lens/cancel/hoya?order_id=${orderId}`);
  },
  sendReceipt(orderId, orderVerifyHash, data) {
    return axios.post(
      `${process.env.VUE_APP_WC_API_URL}/receipt/email/${orderId}/${orderVerifyHash}`,
      data,
    );
  },
  updateOrderCustomer(data) {
    return axios.post(`${process.env.VUE_APP_WC_API_URL}/io-api/order/updateOrder`, data);
  },
  orderLink(orderId, data) {
    return axios.post(`${process.env.VUE_APP_API_URL}/orders/${orderId}/relate_order`, data);
  },
  getContactLensPrescriptionFile(fileId) {
    return axios.get(`${process.env.VUE_APP_WC_API_URL}/io-api/getContactLensPrescriptionFile/${fileId}`);
  },
};
