<template>
  <div class="navbar-wrapper">
    <div class="container-fluid">
      <b-navbar type="dark" variant="dark">
        <b-navbar-brand to="/" class="mr-3">
          <img src="../assets/logo.svg" alt="Logo" />
        </b-navbar-brand>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown
            no-caret
            right
            class="d-flex align-items-center"
            toggle-class="dropdown-toggle--location-selector"
            menu-class="dropdown-menu--location-selector"
            ref="locationSelector"
          >
            <template v-slot:button-content>
              <i class="di-location-pin-24" />
              <span>{{ selectedOutlet.abbreviation }}</span>
              <i class="di-arrow-down-12" />
            </template>
            <b-dropdown-group header="Outlet" header-classes="text-uppercase text-semi-bold">
              <div class="b-dropdown-form">
                <b-form-group>
                  <b-form-select v-model="selectedRegionId">
                    <option
                      v-for="region in $store.getters.locationSelectorRegions"
                      :key="region.id"
                      :value="region.id"
                    >
                      <flag :iso="region.countryCode" />{{ region.name }}
                    </option>
                  </b-form-select>
                </b-form-group>
              </div>
              <b-form-radio-group
                buttons
                stacked
                button-variant="white"
                class="d-block custom-radio-button custom-radio-button--location-selector"
                :checked="selectedOutletId"
              >
                <b-form-radio
                  v-for="outlet in locationSelectorOutlets"
                  :key="outlet.id"
                  :value="outlet.id"
                  @change="onOutletChange"
                >
                  <b-container fluid>
                    <b-row>
                      <b-col cols="3" class="text-right text-uppercase">
                        <span>{{ outlet.abbreviation }}</span>
                      </b-col>
                      <b-col cols="9" class="text-left">
                        <span class="text-muted">{{ outlet.name }}</span>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-form-radio>
              </b-form-radio-group>
            </b-dropdown-group>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right no-caret toggle-class="pr-0 ml-3">
            <template v-slot:button-content>
              <b-img
                rounded="circle"
                :src="profileImage"
                class="navbar-profile-img"
                alt="Profile"
              />
            </template>
            <b-dropdown-group header="Account" header-classes="text-uppercase text-semi-bold">
              <b-dropdown-item href="#" disabled>My profile</b-dropdown-item>
              <b-dropdown-item href="#" disabled>Onboarding</b-dropdown-item>
              <b-dropdown-item href="#" disabled>News</b-dropdown-item>
              <b-dropdown-item href="#" disabled>Switch users</b-dropdown-item>
              <b-dropdown-item href="#" disabled>Help</b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-item-btn class="mt-3" @click="logout"> Logout </b-dropdown-item-btn>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  name: 'Navbar',
  methods: {
    ...mapMutations('auth', {
      clearUser: 'CLEAR_USER',
      setOutlet: 'SET_SELECTED_OUTLET_ID',
      setRegion: 'SET_SELECTED_REGION_ID',
    }),
    ...mapActions('auth', ['updateUser']),
    logout() {
      this.clearUser();
      this.$auth.logout();
      this.$router.go();
    },
    onOutletChange(outletId) {
      this.updateUser({
        id: this.currentUser.id,
        email: this.currentUser.email,
        region_id: this.selectedRegionId,
        outlet_id: outletId,
      })
        .then(() => {
          this.setOutlet(outletId);
          this.$store.dispatch('showSuccessAlert', 'Outlet changed');
        })
        .catch(() => {
          this.$store.dispatch('showErrorAlert', "Outlet couldn't change");
        });
      this.$refs.locationSelector.hide();
    },
  },
  computed: {
    ...mapState('auth', {
      currentUser: state => state.user,
      selectedOutletId: state => state.selectedOutletId,
      stateSelectedRegionId: state => state.selectedRegionId,
    }),
    ...mapGetters('auth', ['selectedOutlet', 'locationSelectorOutlets']),
    profileImage() {
      return this.currentUser.profile_image || require('../assets/no-profile.jpg'); // eslint-disable-line
    },
    selectedRegionId: {
      get() {
        return this.stateSelectedRegionId;
      },
      set(value) {
        this.setRegion(value);
      },
    },
  },
};
</script>
