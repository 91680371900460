<template>
  <b-input-group size="lg">
    <template v-slot:prepend>
      <slot name="prepend"></slot>
    </template>

    <b-form-input
      :name="name"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event)"
      @keyup.enter.native="$emit('enter', $event)"
      ref="input"
      class="border-left-0 pl-0"
      size="lg"
    ></b-form-input>

    <template v-slot:append>
      <div class="clear-input">
        <button v-show="value" type="button" @click="clearInput">
          <i class="di-remove-10"></i>
        </button>
      </div>
      <slot name="append"></slot>
    </template>
  </b-input-group>
</template>

<script>
export default {
  name: 'ClearableInput',
  data() {
    return {};
  },
  props: {
    name: String,
    placeholder: String,
    value: String,
  },
  methods: {
    clearInput() {
      this.$emit('input', '');
      this.$emit('input-cleared');
      this.$refs.input.$el.focus();
    },
  },
};
</script>

<style scoped>
.input-group-append {
  margin-left: -3px;
}

.clear-input {
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  border-radius: 0.3rem;
  background: white;
  z-index: 3;
}

.clear-input button {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  margin-right: 18px;
  background-color: #ebeef4;
  outline: none;
  border: none;
  border-radius: 50%;
}

.clear-input .di-remove-10 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: #414347;
}
</style>
