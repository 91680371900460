<template>
  <div class="step-holder" v-bind:class="{ completed: activeJobEditStep > 1 }">
    <div class="title-bar">
      <div style="width: 100%">
        <div style="display: flex; justify-content: space-between">
          <h3><span>1. Frame</span></h3>
          <div>
            <a @click="editThisStep" v-if="activeJobEditStep > 1">Change</a>
            <a class="ml-4" @click="resetThisStep" v-if="activeJobEditStep > 1">Reset</a>
          </div>
        </div>

        <div class="selected-features" v-if="activeJobEditStep > 1 && selectedFrameData.frameSize">
          <div>
            <b>Frame</b>{{ getSizeName(selectedFrameData.frameSize) }}
            {{
              $store.state.jobsModule.selectedFrameData.frameSize != 'nd'
                ? ' / ' + getColorName(selectedFrameData.frameColor, colors)
                : ''
            }}
          </div>
          <div>
            <b>Right Arm</b>{{ getSizeName(selectedFrameData.rightArmSize) }}
            {{
              $store.state.jobsModule.selectedFrameData.frameSize != 'nd'
                ? ' / ' + getColorName(selectedFrameData.rightArmColor, colors)
                : ''
            }}
          </div>
          <div>
            <b>Left Arm</b>{{ getSizeName(selectedFrameData.leftArmSize) }}
            {{
              $store.state.jobsModule.selectedFrameData.frameSize != 'nd'
                ? ' / ' + getColorName(selectedFrameData.leftArmColor, colors)
                : ''
            }}
          </div>
        </div>
      </div>
    </div>
    <v-wait for="Color List">
      <template slot="waiting">
        <div class="text-center mb-3">
          <b-spinner></b-spinner>
        </div>
      </template>
      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <b-form
          class="mt-4"
          @submit.stop.prevent="handleSubmit(sendFrameForm)"
          v-if="activeJobEditStep === 1"
          novalidate="novalidate"
        >
          <div class="table-title-wrapper">
            <h5>Size</h5>
          </div>
          <div class="selection-table">
            <div class="selection-type">
              <h6>Size</h6>
              <div class="options-holder">
                <div
                  class="single-option"
                  v-for="frameSize in frameSizeOptions"
                  :key="frameSize.text"
                >
                  <div class="option-label" @click="onSetAllSizes(frameSize)">
                    {{ frameSize.text }}
                  </div>
                </div>
              </div>
            </div>
            <div class="selection-item">
              <h6>Frame</h6>
              <div class="selection-holder">
                <ValidationProvider name="Frame size" rules="required" v-slot="validationContext">
                  <b-form-group>
                    <b-form-radio-group
                      v-model="$store.state.jobsModule.selectedFrameData.frameSize"
                      :options="frameSizeOptions"
                      :state="getValidationState(validationContext)"
                    ></b-form-radio-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
            <div class="selection-item">
              <h6>Right Arm</h6>
              <div class="selection-holder">
                <ValidationProvider
                  name="Right Arm size"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-group>
                    <b-form-radio-group
                      :checked="$store.state.jobsModule.selectedFrameData.rightArmSize"
                      @change="onChangeArmRightSize"
                      :options="frameSizeOptions"
                      :state="getValidationState(validationContext)"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </ValidationProvider>
              </div>
            </div>
            <div class="selection-item">
              <h6>Left Arm</h6>
              <div class="selection-holder">
                <ValidationProvider
                  name="Left Arm size"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-group>
                    <b-form-radio-group
                      :checked="$store.state.jobsModule.selectedFrameData.leftArmSize"
                      @change="onChangeArmLeftSize"
                      :options="frameSizeOptions"
                      :state="getValidationState(validationContext)"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div
            v-if="$store.state.jobsModule.selectedFrameData.frameSize != 'nd' && !isDresdenGoOrder"
          >
            <div class="table-title-wrapper">
              <h5>Options</h5>
            </div>
            <div class="selection-table" style="padding: 10px">
              <b-form-checkbox
                v-model="isExistingDresden"
                class="custom-checkbox-button"
                name="check-button"
                style="margin-top: 8px"
                @change="onChangeIsExistingDresden"
              >
                Existing Dresden
              </b-form-checkbox>
            </div>
          </div>

          <div v-if="$store.state.jobsModule.selectedFrameData.frameSize != 'nd'">
            <div class="table-title-wrapper">
              <h5>Colour</h5>
            </div>
            <div class="selection-table">
              <div class="selection-type">
                <h6>Colour</h6>
                <h5>Standard</h5>
                <div class="options-holder">
                  <div
                    class="single-option"
                    v-for="color in standardColorOptions"
                    :key="color.hex_code"
                  >
                    <div class="option-label" @click="setAllcolour(color, 'normal')">
                      <span class="color" v-bind:style="{ backgroundColor: color.hex_code }"></span
                      >{{ color.name }}
                    </div>
                  </div>
                </div>
                <h5>Special</h5>
                <div class="options-holder">
                  <div
                    class="single-option"
                    v-for="color in specialColorOptions"
                    :key="color.hex_code"
                  >
                    <div class="option-label" @click="setAllcolour(color, 'special')">
                      <span class="color" v-bind:style="{ backgroundColor: color.hex_code }"></span
                      >{{ color.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="selection-item">
                <h6>Frame</h6>
                <div class="selection-holder with-title">
                  <ValidationProvider
                    name="Frame color"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-group>
                      <b-form-radio-group
                        v-model="$store.state.jobsModule.selectedFrameData.frameColor"
                        :options="standardColorOptions"
                        @change="onChangeFrameColor"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Frame color"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-group class="title-spacer">
                      <b-form-radio-group
                        v-model="$store.state.jobsModule.selectedFrameData.frameColor"
                        :options="specialColorOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
              <div class="selection-item">
                <h6>Right Arm</h6>
                <div class="selection-holder with-title">
                  <ValidationProvider name="Arm color" rules="required" v-slot="validationContext">
                    <b-form-group>
                      <b-form-radio-group
                        :checked="$store.state.jobsModule.selectedFrameData.rightArmColor"
                        @change="onChangeArmRightColor"
                        :options="armColorOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                  <ValidationProvider name="Arm color" rules="required" v-slot="validationContext">
                    <b-form-group class="title-spacer">
                      <b-form-radio-group
                        :checked="$store.state.jobsModule.selectedFrameData.rightArmColor"
                        @change="onChangeArmRightColor"
                        :options="armColorSpecialOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>

              <div class="selection-item">
                <h6>Left Arm</h6>
                <div class="selection-holder with-title">
                  <ValidationProvider name="Arm color" rules="required" v-slot="validationContext">
                    <b-form-group>
                      <b-form-radio-group
                        :checked="$store.state.jobsModule.selectedFrameData.leftArmColor"
                        @change="onChangeArmLeftColor"
                        :options="armColorOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                  <ValidationProvider name="Arm color" rules="required" v-slot="validationContext">
                    <b-form-group class="title-spacer">
                      <b-form-radio-group
                        :checked="$store.state.jobsModule.selectedFrameData.leftArmColor"
                        @change="onChangeArmLeftColor"
                        :options="armColorSpecialOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="confirmation-wrapper" ref="confirmationWrapper">
            <b-button class="mr-3" variant="outline-secondary" @click="cancel()" type="button"
              >Cancel</b-button
            >
            <b-button class="button-with-loader" type="submit" :disabled="invalid" variant="primary"
              >Confirm</b-button
            >
          </div>
        </b-form>
      </ValidationObserver>
    </v-wait>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import colorsApi from '@/api/colors';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { SIZES } from '@/support/constants';

export default {
  name: 'Frame',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      frameData: {
        frameSize: null,
        leftArmSize: null,
        rightArmSize: null,
        frameColor: null,
        leftArmColor: null,
        rightArmColor: null,
      },
      showSelectedColors: false,
      isExistingDresden: false,
    };
  },
  props: {
    customer: Object,
  },
  computed: {
    ...mapState({
      job: state => state.jobsModule.selectedJobData,
      activeJobEditStep: state => state.jobsModule.activeJobEditStep,
      selectedFrameData: state => state.jobsModule.selectedFrameData,
      defaultFrameData: state => state.jobsModule.defaultFrameData,
      colors: state => state.jobsModule.colors,
    }),
    ...mapGetters('jobsModule', ['isPrescriptionRequired']),
    frameSizeOptions() {
      if (this.isDresdenGoOrder) {
        return SIZES.filter(size => size.value !== 'nd');
      }
      return SIZES;
    },
    isDv4Order() {
      return this._.get(this.job, 'order.channel') === 'dv4';
    },
    isDresdenGoOrder() {
      const dresdenGoTypes = ['dg-reading-glass', 'dresdengo'];
      return dresdenGoTypes.includes(this._.get(this.job, 'item.region_product.product.type.key'));
    },
    frameColors() {
      // eslint-disable-next-line func-names
      return this._.filter(this.colors, function (o) {
        return o.type === 'Frame' && o.visible_online === 1 && o.name !== 'Existing Dresden';
      });
    },
    armColors() {
      // eslint-disable-next-line func-names
      return this._.filter(this.colors, function (o) {
        return o.type === 'Temple' && o.visible_online === 1 && o.name !== 'Existing Dresden';
      });
    },
    standardColorOptions() {
      const options = this._.filter(this.frameColors, { special: 0 });
      return this._.sortBy(
        options.filter(o => o.name !== 'Existing Dresden'),
        ['name'],
      );
    },
    standardColorExistingOptions() {
      const options = this._.filter(this.frameColors, { special: 0 });
      return this._.sortBy(
        options.filter(o => o.name === 'Existing Dresden'),
        ['name'],
      );
    },
    specialColorOptions() {
      const options = this._.filter(this.frameColors, { special: 1 });
      return this._.sortBy(options, ['name']);
    },
    armColorOptions() {
      const options = this._.filter(this.armColors, { special: 0 });
      return this._.sortBy(
        options.filter(o => o.name !== 'Existing Dresden'),
        ['name'],
      );
    },
    armColorExistingOptions() {
      const options = this._.filter(this.armColors, { special: 0 });
      return this._.sortBy(
        options.filter(o => o.name === 'Existing Dresden'),
        ['name'],
      );
    },
    armColorSpecialOptions() {
      const options = this._.filter(this.armColors, { special: 1 });
      return this._.sortBy(options, ['name']);
    },
  },
  mounted() {
    const isExistingDresden = this._.get(this.job, 'frame.existingdresden');
    if (isExistingDresden !== null && typeof isExistingDresden !== 'undefined') {
      this.isExistingDresden = isExistingDresden;
      this.setIsExistingDresden(isExistingDresden);
    } else {
      const isOldExistingDresden = this._.get(this.job, 'frame.color.name') === 'Existing Dresden';
      if (isOldExistingDresden) {
        this.isExistingDresden = true;
        this.setIsExistingDresden(true);
        setTimeout(() => {
          const frameColors = this._.filter(this.frameColors, { special: 0 });
          const frame = frameColors.filter(color => color.name === 'Black')[0];
          this.setFrameColor(frame.id);
          const arm = this.armColorOptions.filter(armc => armc.name === 'Black')[0];
          this.setArmLeftColor(arm.id);
          this.setArmRightColor(arm.id);
        }, 1000);
        this.setIsExistingDresden(true);
      } else {
        this.isExistingDresden = false;
        this.setIsExistingDresden(false);
      }
    }
  },
  watch: {
    'frameData.frameColor': 'scrollToConfirmation',
    'frameData.leftArmColor': 'scrollToConfirmation',
    'frameData.rightArmColor': 'scrollToConfirmation',
  },
  methods: {
    ...mapMutations('jobsModule', {
      setArmSize: 'SET_ARM_SIZE',
      setArmRightSize: 'SET_ARM_RIGHT_SIZE',
      setArmLeftSize: 'SET_ARM_LEFT_SIZE',
      setArmColor: 'SET_ARM_COLOR',
      setArmRightColor: 'SET_ARM_RIGHT_COLOR',
      setArmLeftColor: 'SET_ARM_LEFT_COLOR',
      setIsExistingDresden: 'SET_IS_EXISTING_DRESDEN',

      setAllSizes: 'SET_ALL_SIZES',
      setAllColors: 'SET_ALL_COLORS',
      setActiveJobStep: 'SET_ACTIVE_JOB_EDIT_STEP',
      setFrameColor: 'SET_FRAME_COLOR',
    }),
    ...mapActions('jobsModule', {
      resetAllSizes: 'resetAllSizes',
      resetAllColors: 'resetAllColors',
      resetSizesAndColors: 'resetSizesAndColors',
    }),
    sendFrameForm() {
      if (this.customer) {
        this.setActiveJobEditStep(2);
      } else {
        this.setActiveJobEditStep(5);
      }
    },
    getColors() {
      this.$wait.start('Color List');
      colorsApi.getAllColors().then(({ data }) => {
        const frameColors = this._.filter(data.data, { type: 'Frame', visible_online: 1 });
        const standardFrameColors = this._.filter(frameColors, { special: 0 });
        const specialFrameColors = this._.filter(frameColors, { special: 1 });
        this.colors = frameColors;
        this.standardColorOptions = this._.sortBy(standardFrameColors, ['name']);
        this.specialColorOptions = this._.sortBy(specialFrameColors, ['name']);
        this.$wait.end('Color List');
        this.showSelectedColors = true;
      });
    },
    setAllcolour(colour, type = 'normal') {
      const frame = colour;
      if (type === 'normal') {
        const arm = this.armColorOptions.filter(armc => armc.name === colour.name)[0];
        this.setArmColor(arm.id);
      } else if (type === 'special') {
        const arm = this.armColorSpecialOptions.filter(armc => armc.name === colour.name)[0];
        this.setArmColor(arm.id);
      } else if (type === 'existing') {
        const arm = this.armColorExistingOptions.filter(armc => armc.name === colour.name)[0];
        this.setArmColor(arm.id);
      }
      this.setFrameColor(frame.id);
      this.$refs.confirmationWrapper.scrollIntoView({ behavior: 'smooth' });
    },
    onSetAllSizes(size) {
      this.setAllSizes(size.value);
      if (size.value === 'nd') {
        this.onChangeIsExistingDresden(false);
        this.isExistingDresden = false;
      }
    },
    editThisStep() {
      this.setActiveJobStep(1);
    },
    resetThisStep() {
      this.setActiveJobStep(1);
      this.resetSizesAndColors();
    },
    onChangeArmRightSize(val) {
      this.setArmRightSize(val);
    },
    onChangeArmLeftSize(val) {
      this.setArmLeftSize(val);
    },
    onChangeArmColor(val) {
      this.setArmColor(val);
      this.frameData.leftArmColor = val;
      this.frameData.rightArmColor = val;
    },
    onChangeArmRightColor(val) {
      this.setArmRightColor(val);
      this.frameData.rightArmColor = val;
    },
    onChangeArmLeftColor(val) {
      this.setArmLeftColor(val);
      this.frameData.leftArmColor = val;
    },
    onChangeFrameColor(val) {
      //this.setFrameColor(val);
      this.frameData.frameColor = val;
    },
    onChangeIsExistingDresden(val) {
      this.setIsExistingDresden(val);
    },
    cancel() {
      this.resetSizesAndColors();
      this.setActiveJobEditStep(5);
    },
    scrollToConfirmation() {
      if (
        this.frameData.frameColor !== null &&
        this.frameData.leftArmColor !== null &&
        this.frameData.rightArmColor !== null
      ) {
        this.$refs.confirmationWrapper.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>
