var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-holder",class:{ completed: _vm.activeJobEditStep > 2, active: _vm.activeJobEditStep === 2 }},[_c('div',{staticClass:"title-bar"},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_vm._m(0),_c('div',[(_vm.activeJobEditStep > 2 && _vm.prescriptions.length > 0)?_c('a',{on:{"click":function($event){return _vm.editOnPagePrescription(_vm.selectedPrescription.id)}}},[_vm._v("Edit")]):_vm._e(),(_vm.activeJobEditStep > 2 && !_vm.customer && _vm.selectedPrescription)?_c('a',{on:{"click":function($event){return _vm.editOnPagePrescription(_vm.selectedPrescription.id)}}},[_vm._v("Guest Edit")]):_vm._e(),(_vm.activeJobEditStep > 2 && !_vm.customer && !_vm.selectedPrescription)?_c('a',{on:{"click":function($event){return _vm.showPrescriptionModal({
                customer: {
                  id: null,
                  full_name: null,
                },
              })}}},[_vm._v("Add Prescription")]):_vm._e(),(_vm.activeJobEditStep > 2 && _vm.customer)?_c('a',{staticClass:"ml-4",on:{"click":_vm.editThisStep}},[_vm._v("Change")]):_vm._e(),(_vm.activeJobEditStep > 2 && _vm.customer)?_c('a',{staticClass:"ml-4",on:{"click":_vm.resetThisStep}},[_vm._v("Reset")]):_vm._e()])]),_c('v-wait',{attrs:{"for":"Prescription List"}},[_c('template',{slot:"waiting"},[_c('div',{staticClass:"text-center mb-3"},[_c('b-spinner')],1)]),(_vm.activeJobEditStep > 2)?_c('PrescriptionDetail',{staticClass:"prescription-detail-box",attrs:{"prescription":_vm.selectedPrescription,"editOnPagePrescription":_vm.editOnPagePrescription}}):_vm._e(),(
            (_vm.productType.lenses_type.includes('single-vision') ||
              _vm.productType.key.includes('single-vision')) &&
            _vm.activeJobEditStep > 2 &&
            _vm.selectedPrescription
          )?_c('div',[_c('h6',{staticClass:"text-uppercase mt-2-5"},[_vm._v("Purpose")]),_c('b-form-group',{staticClass:"flex-fill"},[_c('b-form-radio-group',{staticClass:"custom-radio-button",attrs:{"id":"type","buttons":"buttons","button-variant":"text"},on:{"input":_vm.sendPrescriptionForm},model:{value:(_vm.selectedPrescriptionTypePurpose),callback:function ($$v) {_vm.selectedPrescriptionTypePurpose=$$v},expression:"selectedPrescriptionTypePurpose"}},[_c('b-form-radio',{attrs:{"value":"reading"}},[_vm._v("Reading")]),(
                  _vm.selectedPrescription &&
                  (_vm.selectedPrescription.add_int ||
                    (_vm.selectedPrescription.right_int_add && _vm.selectedPrescription.left_int_add))
                )?_c('b-form-radio',{attrs:{"value":"intermediate"}},[_vm._v("Intermediate")]):_vm._e(),_c('b-form-radio',{attrs:{"value":"distance"}},[_vm._v("Distance")])],1)],1)],1):_vm._e()],2)],1)]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [(_vm.activeJobEditStep === 2)?_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.sendPrescriptionForm)}}},[_c('v-wait',{attrs:{"for":"Prescription List"}},[_c('template',{slot:"waiting"},[_c('div',{staticClass:"text-center mb-3"},[_c('b-spinner')],1)]),_c('ValidationProvider',{attrs:{"name":"Prescription","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('PrescriptionList',{attrs:{"customer":_vm.customerPrescriptions,"showTitle":true,"selectable":true,"collapsible":true,"expanded":true,"state":_vm.getValidationState(validationContext),"editOnPagePrescription":_vm.editOnPagePrescription},on:{"new":_vm.showPrescriptionModal,"edit":_vm.showPrescriptionEditModal},model:{value:(_vm.$store.state.jobsModule.selectedPrescription),callback:function ($$v) {_vm.$set(_vm.$store.state.jobsModule, "selectedPrescription", $$v)},expression:"$store.state.jobsModule.selectedPrescription"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)})],2),(
          _vm.productType.lenses_type.includes('single-vision') ||
          _vm.productType.key.includes('single-vision')
        )?_c('ValidationProvider',{attrs:{"name":"Prescription type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"flex-fill",attrs:{"label":"Type","label-for":"type"}},[_c('b-form-radio-group',{staticClass:"custom-radio-button",attrs:{"id":"type","state":_vm.getValidationState(validationContext),"buttons":"buttons","button-variant":"text"},model:{value:(_vm.selectedPrescriptionTypePurpose),callback:function ($$v) {_vm.selectedPrescriptionTypePurpose=$$v},expression:"selectedPrescriptionTypePurpose"}},[_c('b-form-radio',{attrs:{"value":"reading"}},[_vm._v("Reading")]),(
                _vm.selectedPrescription &&
                (_vm.selectedPrescription.add_int ||
                  (_vm.selectedPrescription.right_int_add && _vm.selectedPrescription.left_int_add))
              )?_c('b-form-radio',{attrs:{"value":"intermediate"}},[_vm._v("Intermediate")]):_vm._e(),_c('b-form-radio',{attrs:{"value":"distance"}},[_vm._v("Distance")])],1)],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"confirmation-wrapper"},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":"outline-secondary","type":"button"},on:{"click":function($event){return _vm.setActiveJobEditStep(5)}}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"button-with-loader",attrs:{"type":"submit","disabled":invalid || !!(_vm.selectedPrescription && _vm.selectedPrescription.error),"variant":"primary"}},[_vm._v("Confirm")])],1)],1):_vm._e()]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('span',[_vm._v("2. Prescription")])])
}]

export { render, staticRenderFns }