<template>
  <div class="nav-wrapper">
    <div class="container-fluid">
      <b-nav class="nav--root">
        <!--<b-nav-item :active="currentPage === 'dashboard'" to="/">Dashboard</b-nav-item>-->
        <b-nav-item :active="currentPage === 'customers'" to="customers"> Customers </b-nav-item>
        <b-nav-item :active="currentPage === 'jobs' && !isOctopos" to="jobs">Jobs</b-nav-item>
        <b-nav-item :active="currentPage === 'jobs-dresdengo'" to="jobs-dresdengo"
          >DresdenGO Jobs</b-nav-item
        >
        <b-nav-item :active="currentPage === 'promos'" to="promos">Promos & Campaigns</b-nav-item>
        <b-nav-item :active="currentPage === 'newsletter-users'" to="newsletter-users"
          >Newsletter Subscribers</b-nav-item
        >
        <b-nav-item :active="currentPage === 'settings'" to="settings"> Settings </b-nav-item>
      </b-nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Nav',
  computed: {
    currentPage() {
      return this.$route.name;
    },
    ...mapGetters('auth', ['selectedOutlet']),
    isAU() {
      const regionId = this._.get(this.selectedOutlet, 'region_id', '');
      if (regionId === 6) {
        return true;
      }
      return false;
    },
  },
};
</script>
