<template>
  <div class="file-preview">
    <figure v-if="file">
      <img :width="img.width" class="img-fluid" :src="img.src" />
      <figcaption class="text-center">{{ img.name }}</figcaption>
    </figure>
    <b-alert variant="danger" dismissible :show="!!error">
      {{ error }}
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'FilePreview',
  props: {
    file: File,
    error: String,
  },
  data() {
    return {
      fileReader: new FileReader(),
      img: {
        src: '',
        width: 0,
        name: '',
      },
    };
  },
  created() {
    this.fileReader.onload = e => {
      const fileExtension = this.file.name.split('.').pop().toLowerCase();

      if (['jpg', 'jpeg', 'png', 'bmp'].includes(fileExtension)) {
        this.img.width = 180;
        this.img.src = e.target.result;
      } else {
        this.img.width = 50;
        this.img.src = require('../assets/document.png'); // eslint-disable-line
      }
      this.img.name = this.file.name;
    };
  },
  watch: {
    file: {
      handler(data) {
        if (data) {
          this.fileReader.readAsDataURL(data);
        }
      },
    },
  },
};
</script>

<style scoped>
.file-preview {
  display: flex;
  align-items: flex-start;
}
</style>
