import { SIZES } from '@/support/constants';
import Vue from 'vue';
import VueAuth from '@websanova/vue-auth';
import BootstrapVue from 'bootstrap-vue';
import VueAxios from 'vue-axios';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueWait from 'vue-wait';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import FlagIcon from 'vue-flag-icon';
import axios from 'axios';
import _ from 'lodash';
import createValidation from '@/support/validation';
import SvgIcon from 'vue-svgicon';
import VueSilentbox from 'vue-silentbox';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/styles.scss';
import VCalendar from 'v-calendar';

createValidation();
Object.defineProperty(Vue.prototype, '_', { value: _ });
Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.use(VueMoment, {
  moment,
});
Vue.moment.tz.setDefault('Australia/Sydney');
Vue.use(VueTelInput);
Vue.use(VueWait);
Vue.use(vueNumeralFilterInstaller, { locale: 'en-au' });
Vue.use(FlagIcon);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.use(VueSilentbox);
Vue.use(SvgIcon, {
  tagName: 'svgicon',
});

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    if (
      response?.status === 401 ||
      response?.data?.message === 'Unauthenticated.' ||
      response?.data?.error === 'token_not_provided'
    ) {
      return window.location.replace('/logout');
    }
    return Promise.reject(error);
  },
);

Vue.router = router;

Vue.use(VueAuth, {
  auth: {
    request(req, token) {
      this.options.http._setHeaders.call(this, req, { Authorization: `Bearer ${token}` }); // eslint-disable-line
    },
    response(res) {
      return _.get(res, 'data.data.token');
    },
  },
  http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'), // eslint-disable-line
  router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'), // eslint-disable-line
  loginData: {
    url: `${process.env.VUE_APP_API_V4_URL}/user/login`,
  },
  fetchData: {
    url: 'user/authenticated',
    enabled: false,
  },
  refreshData: {
    enabled: false,
  },
});

Vue.filter('studly', value => {
  let segments = value.split('-');
  segments = segments.map(segment => segment.charAt(0).toUpperCase() + segment.slice(1));
  return segments.join(' ');
});

Vue.filter('titlecase', value => {
  if (!value) return '';
  return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
});

Vue.mixin({
  data() {
    return {
      ecomUrl: process.env.VUE_APP_ECOMMERCE_URL,
      sendleUrl: process.env.VUE_APP_SENDLE_API_URL,
      appApiUrl: process.env.VUE_APP_API_URL,
      newAppApiUrl: process.env.VUE_APP_NEW_API_URL,
      timezone: this.$moment.tz.guess(),
      ioUrl: process.env.VUE_APP_IO_URL,
    };
  },
  methods: {
    getValidationState({ validated, valid = null }) {
      return validated ? valid : null;
    },
    getImageUrl(path) {
      return `${process.env.ASSETS_S3_URL}/${process.env.ASSETS_S3_BUCKET}/${path}`;
    },
    $dialog(title, message) {
      return this.$bvModal.msgBoxConfirm(message, {
        headerClass: 'modal-header--dialog',
        bodyClass: 'modal-body--dialog',
        footerClass: 'modal-footer--dialog justify-content-center',
        title,
        size: 'sm',
        okTitle: 'Yes, Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      });
    },
    createLetterAvatar(firstName = '', lastName = '') {
      const first = firstName || '';
      const last = lastName || '';
      return first.charAt(0) + last.charAt(0);
    },
    getFullName(firstName = '', lastName = '') {
      const first = firstName || '';
      const last = lastName || '';
      return !first && !last ? '' : `${first} ${last}`;
    },
    getCustomerName(customer) {
      const name = customer ? `${customer.first_name} ${customer.last_name}` : 'Guest';
      return name;
    },
    getStatusDisplayName(slug, statusList) {
      const status = [
        ...statusList,
        { slug: 'refunded', display_name: 'Refunded', selectable: false },
        { slug: 'voided', display_name: 'Voided', selectable: false },
        { slug: 'refunded-transaction', display_name: 'Refund Transaction', selectable: false },
      ].find(dispenseStatus => dispenseStatus.slug === slug);
      return this._.get(status, 'display_name');
    },
    getStatusVariant(status) {
      switch (status) {
        case 'paid':
          return 'orange';
        case 'new-order':
          return 'orange';
        case 'received':
        case 'dispatched':
        case 'collected-by-customer':
        case 'completed':
        case 'created':
          return 'green';
        case 'in-progress':
        case 'ready-to-pick':
        case 'lenses-ordered':
        case 'waiting-on-information':
        case 'wait-on-info':
        case 'ready-to-edge':
        case 'ready-to-go':
        case 'payment-due':
        case 'pending':
          return 'blue';
        default:
          return 'secondary';
      }
    },
    showAddAddressModal(address = {}) {
      this.$store.commit('addressModal/SHOW_NEW_ADDRESS_FORM', {
        ...address,
        vm: this,
      });
    },
    hideAddAddressModal() {
      this.$bvModal.hide('address-form-modal');
    },
    // TODO refactor this with add address modal
    showCreatePaymentModal(customer = {}) {
      this.$store.commit('paymentModal/SHOW_PAYMENT_FORM', {
        customer,
        vm: this,
      });
    },
    hideCreatePaymentModal() {
      this.$bvModal.hide('payment-form-modal');
    },
    onShowPrescriptionModal(e) {
      this.selectedCustomer = {
        id: e.customer.id,
        full_name: e.customer.full_name,
      };
      this.selectedPrescription = {};
      this.$bvModal.show('new-prescription-modal');
    },
    onShowPdModal() {
      this.$bvModal.show('new-pd-modal');
    },
    getSizeName(size) {
      const foundSize = this._.find(SIZES, { value: size });
      return foundSize.readableName;
    },
    getColorName(colorId, colors) {
      const foundColor = this._.find(colors, { id: colorId });
      return foundColor?.name;
    },
    setActiveJobEditStep(stepNumber) {
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', stepNumber);
    },
    getLensSupplierName(supplierId) {
      const foundSupplier = this._.find(this.$store.state.lensSuppliers, { id: supplierId });
      return foundSupplier.name;
    },
    calculateAge(birthDate) {
      const age = moment().year() - moment(birthDate, 'YYYY-MM-DD').year();
      return age;
    },
  },
  filters: {
    uppercase(value) {
      return value.toUpperCase();
    },
    address(obj) {
      if (typeof obj === 'object' && !obj) return '';
      const address = [
        obj.address1 ? `${obj.address1}, ` : '',
        obj.address2 ? `${obj.address2}, ` : '',
        obj.suburb ? `${obj.suburb}, ` : '',
        obj.city ? `${obj.city}, ` : '',
        obj.state ? `${obj.state}, ` : '',
        obj.postcode ? `${obj.postcode}, ` : '',
        obj.country ? `${obj.country}` : '',
      ];
      return address.join('');
    },
  },
});

Sentry.init({
  Vue,
  logErrors: true,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  enabled: true,
});

Vue.config.productionTip = process.env.NODE_ENV !== 'production';

new Vue({
  router,
  store,
  wait: new VueWait(),
  render: h => h(App),
}).$mount('#app');
