<template>
  <div class="container settings">
    <h2>Settings</h2>
    <b-button class="mt-3" variant="primary" @click="clearCache">Clear Cache</b-button>
  </div>
</template>

<script>
import storage from '../support/storage';

export default {
  name: 'Settings',
  methods: {
    clearCache() {
      storage.clear();
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.settings {
  padding: 3rem;
}
</style>
