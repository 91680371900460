import axios from 'axios';

export default {
  putBillingAddress(data = {}) {
    return axios.put(`${process.env.VUE_APP_NEW_API_URL}/staff/billing-addresses/${data.id}`, data);
  },
  postBillingAddress(data = {}) {
    return axios.post(
      `${process.env.VUE_APP_NEW_API_URL}/staff/customers/${data.customer_id}/billing-addresses`,
      data,
    );
  },
};
