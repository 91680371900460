<template>
  <b-popover :show.sync="show" triggers="click" :target="target" placement="auto">
    <template #title>
      <b-button @click="onClose" class="close" aria-label="Close">
        <span class="d-inline-block" aria-hidden="true">&times;</span>
      </b-button>
      Enter Filter Name
    </template>

    <b-form-input v-model="filterName" class="mb-2" placeholder="Filter Name" />
    <div class="d-flex justify-content-around">
      <b-button @click="onClose" variant="outline-light">Cancel</b-button>
      <b-button @click="onOk" variant="primary" :disabled="!filterName.trim()"> Ok </b-button>
    </div>
  </b-popover>
</template>

<script>
export default {
  name: 'SaveFilterPopover',
  props: {
    target: String,
    query: Object,
    addFilter: Function,
  },
  data() {
    return {
      filterName: '',
      show: false,
    };
  },
  methods: {
    onClose() {
      this.filterName = '';
      this.show = false;
    },
    onOk() {
      if (this.query) {
        this.saveFilter();
        this.$store.dispatch('showSuccessAlert', 'Filter Saved!');
        this.onClose();
      } else {
        this.$store.dispatch('showErrorAlert', 'There is no filter to save!');
      }
    },
    saveFilter() {
      this.addFilter({ name: this.filterName, query: this.query });
    },
  },
};
</script>
