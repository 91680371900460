import colorsApi from '@/api/colors';
import { SIZES } from '@/support/constants';
import dayjs from 'dayjs';
import storage from '@/support/storage';

const initialState = {
  showJobEditModal: false,
  selectedJobData: null,
  activeJobEditStep: 1,
  selectedFrameData: {
    frameSize: null,
    leftArmSize: null,
    rightArmSize: null,
    frameColor: null,
    leftArmColor: null,
    rightArmColor: null,
    isExistingFrame: false,
  },
  selectedPrescription: null,
  selectedPrescriptionType: null,
  selectedPd: null,
  selectedLens: null,
  selectedLensCoating: null,
  colors: null,

  defaultFrameData: {
    frameSize: null,
    leftArmSize: null,
    rightArmSize: null,
    frameColor: null,
    leftArmColor: null,
    rightArmColor: null,
  },
};

const getters = {
  isPrescriptionRequired: state =>
    !!state.selectedJobData?.item?.region_product?.requires_prescription,
  selectedLensesType: state =>
    state.selectedJobData?.item?.region_product?.product?.type?.lenses_type,
  selectedPrescriptionCategory: state => state.selectedPrescription?.category,
  selectedPrescriptionType: state => state.selectedPrescriptionType,
};

const actions = {
  resetAllSizes({ commit }) {
    commit('SET_ALL_SIZES', null);
  },
  resetAllColors({ commit }) {
    commit('SET_ALL_COLORS', null);
  },
  resetSizesAndColors({ commit }) {
    commit('defaultSizeAndColor');
  },
  resetPrescription({ commit }) {
    commit('SET_SELECTED_PRESCRIPTION', null);
    commit('SET_SELECTED_PRESCRIPTION_TYPE', null);
  },
  resetJobEditModule({ commit, dispatch }) {
    dispatch('resetSizesAndColors');
    dispatch('resetPrescription');
    commit('SET_SELECTED_PD', null);
    commit('SET_SELECTED_LENS', null);
  },
};

const mutations = {
  HIDE_JOB_EDIT_MODAL(state) {
    state.showJobEditModal = false;
    document.body.classList.remove('block-scroll');
  },
  SET_SELECTED_JOB(state, payload) {
    state.selectedJobData = payload;
    state.showJobEditModal = true;
    document.body.classList.add('block-scroll');
  },
  SET_ACTIVE_JOB_EDIT_STEP(state, payload) {
    state.activeJobEditStep = payload;
  },
  SET_ALL_SIZES(state, payload) {
    state.selectedFrameData.frameSize = payload;
    state.selectedFrameData.leftArmSize = payload;
    state.selectedFrameData.rightArmSize = payload;
  },
  async GET_ALL_COLORS(state) {
    let colors = storage.get('colors');
    if (!colors) {
      const response = await colorsApi.getAllColors();
      colors = response.data.data;
      storage.set('colors', colors, dayjs().add(1, 'week').valueOf());
    }
    state.colors = colors;
  },
  SET_ALL_COLORS(state, payload) {
    state.selectedFrameData.frameColor = payload;
    state.selectedFrameData.leftArmColor = payload;
    state.selectedFrameData.rightArmColor = payload;
  },
  SET_ARM_SIZE(state, payload) {
    state.selectedFrameData.leftArmSize = payload;
    state.selectedFrameData.rightArmSize = payload;
  },
  SET_ARM_LEFT_SIZE(state, payload) {
    state.selectedFrameData.leftArmSize = payload;
  },
  SET_ARM_RIGHT_SIZE(state, payload) {
    state.selectedFrameData.rightArmSize = payload;
  },
  SET_ARM_COLOR(state, payload) {
    state.selectedFrameData.leftArmColor = payload;
    state.selectedFrameData.rightArmColor = payload;
  },
  SET_ARM_RIGHT_COLOR(state, payload) {
    state.selectedFrameData.rightArmColor = payload;
  },
  SET_ARM_LEFT_COLOR(state, payload) {
    state.selectedFrameData.leftArmColor = payload;
  },
  SET_FRAME_COLOR(state, payload) {
    state.selectedFrameData.frameColor = payload;
  },
  SET_SELECTED_PRESCRIPTION(state, payload) {
    state.selectedPrescription = payload;
  },
  SET_SELECTED_PRESCRIPTION_TYPE(state, payload) {
    state.selectedPrescriptionType = payload;
  },
  SET_SELECTED_PD(state, payload) {
    state.selectedPd = payload;
  },
  SET_SELECTED_LENS(state, payload) {
    state.selectedLens = payload;
  },
  SET_SELECTED_LENSCOATING(state, payload) {
    state.selectedLensCoating = payload;
  },
  SET_IS_EXISTING_DRESDEN(state, payload) {
    state.selectedFrameData.isExistingDresden = payload;
  },
  SET_JOB_EDIT_MODAL_DATA(state, payload) {
    state.selectedFrameData.frameColor = payload.frame?.color?.id || null;
    state.selectedFrameData.rightArmColor = payload.rightArm?.color?.id || null;
    state.selectedFrameData.leftArmColor = payload.leftArm?.color?.id || null;

    state.defaultFrameData.frameColor = payload.frame?.color?.id || null;
    state.defaultFrameData.rightArmColor = payload.rightArm?.color?.id || null;
    state.defaultFrameData.leftArmColor = payload.leftArm?.color?.id || null;

    state.defaultFrameData.isExistingDresden = payload.frame?.existingdresden || false;

    const selectedFrameSize = SIZES.find(
      s =>
        s.readableName?.toLowerCase?.() === payload.frame.sizeId?.toLowerCase?.() ||
        s.value?.toLowerCase?.() === payload.frame.sizeId?.toLowerCase?.(),
    );
    const selectedleftArmSize = SIZES.find(
      s =>
        s.readableName?.toLowerCase?.() === payload.leftArm.sizeId?.toLowerCase?.() ||
        s.value?.toLowerCase?.() === payload.leftArm.sizeId?.toLowerCase?.(),
    );
    const selectedRightArmSize = SIZES.find(
      s =>
        s.readableName?.toLowerCase?.() === payload.rightArm.sizeId?.toLowerCase?.() ||
        s.value?.toLowerCase?.() === payload.rightArm.sizeId?.toLowerCase?.(),
    );

    state.selectedFrameData.frameSize = payload.frame ? selectedFrameSize.value : null;
    state.selectedFrameData.leftArmSize = payload.leftArm ? selectedleftArmSize.value : null;
    state.selectedFrameData.rightArmSize = payload.rightArm ? selectedRightArmSize.value : null;

    state.defaultFrameData.frameSize = payload.frame ? selectedFrameSize.value : null;
    state.defaultFrameData.leftArmSize = payload.leftArm ? selectedleftArmSize.value : null;
    state.defaultFrameData.rightArmSize = payload.rightArm ? selectedRightArmSize.value : null;

    state.selectedPrescription = payload.prescription ? payload.prescription.detail : null;
    state.selectedPd = payload.item.additional_info.data.pd
      ? {
          detail: payload.item.additional_info.data.pd.detail,
          pdPresenter: payload.item.additional_info.pdPresenter,
        }
      : null;
    state.selectedLens = payload.lenses ? payload.lenses : null;
    state.selectedPrescriptionType = payload.prescription?.typeId
      ? payload.prescription.typeId
      : null;
  },
  defaultSizeAndColor(state) {
    state.selectedFrameData.frameSize = state.defaultFrameData.frameSize;
    state.selectedFrameData.leftArmSize = state.defaultFrameData.leftArmSize;
    state.selectedFrameData.rightArmSize = state.defaultFrameData.rightArmSize;
    state.selectedFrameData.frameColor = state.defaultFrameData.frameColor;
    state.selectedFrameData.leftArmColor = state.defaultFrameData.leftArmColor;
    state.selectedFrameData.rightArmColor = state.defaultFrameData.rightArmColor;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
