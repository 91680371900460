const initialState = {
  paymentFormData: undefined,
};

const mutations = {
  SHOW_PAYMENT_FORM(state, data) {
    state.paymentFormData = data;
    data.vm.$bvModal.show('payment-form-modal');
  },
  CLEAR_DATA_PAYMENT_FORM(state) {
    state.paymentFormData = undefined;
  },
};

const actions = {};

const moduleGetters = {};

export default {
  namespaced: true,
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
