<template>
  <b-modal
    header-class="modal-header--dialog"
    body-class="modal-body--dialog-form text-left"
    footer-class=""
    title="Update Note"
    size="xl"
    hide-footer=""
    centered
    id="comment-update-modal"
    :busy="isLoading"
    @show="onShow"
  >
    <b-form>
      <b-form-group label="Note" label-for="note" class="mb-0">
        <b-form-textarea
          id="note"
          v-model="noteText"
          placeholder="Enter note"
          rows="3"
          max-rows="12"
          :disabled="isLoading"
        ></b-form-textarea>
      </b-form-group>
      <b-button
        variant="primary"
        type="submit"
        class="mt-3"
        @click.prevent="updateComment"
        :disabled="isLoading"
      >
        Update
      </b-button>
    </b-form>
    <div v-if="isLoading" class="text-center mt-3">
      <b-spinner />
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import apiNotes from '@/api/notes';

export default {
  name: 'CommentUpdateModal',
  data() {
    return {
      note: null,
      noteText: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('commentModal', {
      selectedUpdateNote: state => state.selectedUpdateNote,
    }),
  },
  methods: {
    onShow() {
      this.note = this.selectedUpdateNote;
      this.noteText = this.selectedUpdateNote.content;
    },
    updateComment() {
      const orderId = this._.get(this.note, 'orders[0].id');
      if (!orderId) {
        this.$store.dispatch('showErrorAlert', 'Order not found');
        return;
      }
      this.isLoading = true;
      apiNotes
        .updateOrderNote(orderId, this.note.id, { content: this.noteText })
        .then(() => {
          this.isLoading = false;
          this.$bvModal.hide('comment-update-modal');
          this.$root.$emit('commentsRefreshed');
          this.$store.dispatch('showSuccessAlert', 'Note Updated Successfully');
        })
        .catch(e => {
          this.isLoading = false;
          this.$store.dispatch('showErrorAlert', e.response.data.message);
        });
    },
  },
};
</script>

<style scoped>
.refund-warning {
  font-weight: 500;
}
</style>
