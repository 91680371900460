<template>
  <b-modal
    header-class="modal-header--dialog"
    body-class="text-left"
    footer-class="modal-footer--dialog justify-content-center"
    id="send-email-receipt-modal"
    title="Email Receipt"
    size="sm"
    hide-footer=""
    @show="onShow"
    centered
  >
    <div style="padding: 10px">
      <b-form v-if="!isLoading">
        <b-form-group label="Email" label-for="status" class="mb-0">
          <b-form-input v-model="customerEmail" size="lg"></b-form-input>
        </b-form-group>
        <b-button @click="onSubmit" class="mt-3 mb-3">Send Email</b-button>
      </b-form>
      <div v-if="isLoading" class="text-center mt-3">
        <b-spinner />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import apiOrders from '@/api/orders';
import sha256 from 'crypto-js/sha256';

export default {
  name: 'SendEmailReceiptModal',
  data() {
    return {
      isLoading: false,
      customerEmail: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedOrder: 'selectedOrder',
    }),
  },
  methods: {
    onShow() {
      let email = this._.get(this.selectedOrder, 'customer.email');
      if (!email) {
        email = this._.get(this.selectedOrder, 'order_billing.customer_email');
      }
      this.customerEmail = email;
    },
    createHash() {
      const orderId = this._.get(this.selectedOrder, 'id');
      const customerId = this._.get(this.selectedOrder, 'customer_id');
      if (orderId === null) {
        this.$store.dispatch('showErrorAlert', 'Order ID is null');
        return null;
      }
      const inputString = customerId
        ? `DRESDEN_${orderId}-${customerId}_l5jIveEz5QrSWwEM5uKm`
        : `DRESDEN_${orderId}-_l5jIveEz5QrSWwEM5uKm`;

      return sha256(inputString).toString();
    },
    onSubmit(e) {
      e.preventDefault();
      this.isLoading = true;
      const getHash = this.createHash();
      const getOrderId = this._.get(this.selectedOrder, 'id');
      if (getOrderId === null) {
        this.$store.dispatch('showErrorAlert', 'Order ID is null');
        return;
      }
      apiOrders
        .sendReceipt(getOrderId, getHash, { email: this.customerEmail })
        .then(() => {
          this.$bvModal.hide('send-email-receipt-modal');
          this.$store.dispatch(
            'showSuccessAlert',
            `The receipt sent to ${this.customerEmail} successfully.`,
          );
        })
        .catch(error => {
          this.$store.dispatch('showErrorAlert', error.response.data.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
