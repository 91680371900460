<template lang="pug">
.card-holder
  .stripe-card
    div(ref='card')
    b-form-invalid-feedback(:force-show='!!error')
      | {{ error }}
</template>

<script>
export default {
  name: 'Stripe',
  data() {
    return {
      card: null,
      stripe: null,
      error: null,
    };
  },
  props: {
    currency: String,
  },
  watch: {
    currency: {
      immediate: true,
      handler(newVal) {
        this.currency = newVal;
        this.$forceUpdate();
      },
    },
  },
  updated() {
    this.createStripe();
  },
  mounted() {
    this.createStripe();
  },
  methods: {
    createStripe() {
      let stripeKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
      if (this.currency === 'CAD') {
        stripeKey = process.env.VUE_APP_STRIPE_PUBLIC_CA_KEY;
      }
      // eslint-disable-next-line
      this.stripe = Stripe(stripeKey);
      const elements = this.stripe.elements();
      const style = {
        iconStyle: 'solid',
        style: {
          base: {
            color: '#878b87',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
      };
      this.card = elements.create('card', style);
      this.card.mount(this.$refs.card);
      this.card.on('change', ({ error }) => {
        this.error = error ? error.message : null;
      });
    },
  },
};
</script>
