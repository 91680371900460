<template lang="pug">
    div(:class="customClasses.split(' ')")
        .error(:class="classes")
            slot
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    type: String,
    customClasses: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      let classes = '';
      switch (this.type) {
        case 'tooltip':
          classes = 'error-tooltip';
          break;
        case 'box':
          classes = 'error-box';
          break;
        default:
          break;
      }
      return [classes];
    },
  },
};
</script>
