<template>
  <div class="auth">
    <div class="container">
      <div class="auth__logo-wrapper text-center">
        <img src="../logo.svg" alt="" class="auth__logo" />
      </div>
      <b-row>
        <b-col offset="0" cols="12" offset-md="2" md="8" offset-lg="3" lg="6">
          <div class="card card--auth">
            <div class="card-body">
              <router-view></router-view>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Auth',
};
</script>
