import axios from 'axios';

export default {
  postCustomerPd(customerId, data = {}) {
    return axios.post('/customer-pd', {
      customer_user_id: customerId,
      ...data,
    });
  },
  postGuestPd(data = {}, lensTypeId) {
    return axios.post('/customer-pd-guest', {
      lens_type: lensTypeId,
      ...data,
    });
  },
  getCustomerPd(customerId, lensTypeId, selectedPrescriptionType) {
    return axios.get(`${process.env.VUE_APP_NEW_API_URL}/staff/customers/${customerId}/pds`, {
      params: {
        with: 'source,staff',
        lens_type_id: lensTypeId,
        purpose: selectedPrescriptionType,
      },
    });
  },
  putPd(pdId, data = {}, purpose, lensType) {
    let url = `/new-customer-pd/${pdId}`;
    if (purpose !== null) {
      url += `?purpose=${purpose}`;

      if (lensType !== null) {
        url += `&lens_type=${lensType}`;
      }
    } else if (lensType !== null) {
      url += `?lens_type=${lensType}`;
    }

    return axios.put(url, data);
  },
  getSources() {
    return axios.get('/pd-source');
  },
  deletePd(pdId) {
    return axios.delete(`/customer-pd/${pdId}`);
  },
  newGetPd(pdId, purpose, lensType) {
    return axios.get(`/new-customer-pd/${pdId}?purpose=${purpose}&lens_type=${lensType}`);
  },
};
