<template>
  <div class="jobs-page-holder">
    <div class="jobs">
      <div class="main-content-wrapper-newsletter">
        <div class="page-header d-flex flex-row">
          <h1 class="m-0 flex-fill font-weight-bold">DresdenGo Jobs</h1>
        </div>
      </div>
      <div class="main-content-wrapper-newsletter">
        <div class="table-filter">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <ClearableInput
              name="filter"
              placeholder="Search"
              v-model="q"
              @input-cleared="clear"
              @enter="getJobs"
            >
              <template v-slot:prepend>
                <b-input-group-text class="px-3">
                  <i class="di-search-18" />
                </b-input-group-text>
              </template>
            </ClearableInput>
          </div>
          <div>
            <b-form-group class="form-group--floating-label mb-0" label-for="date">
              <vc-date-picker
                class="custom-date-picker custom-date-picker--lg"
                mode="range"
                v-model="createdAt"
                :attributes="datePicker.attributes"
                :masks="datePicker.masks"
                :popover="{ visibility: 'click' }"
                :input-props="{ placeholder: 'Any', class: 'placeholder--black' }"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div style="display: flex; justify-content: center; align-items: center">
                    <input
                      class="bg-white border px-2 py-1 rounded w-100"
                      :value="formattedRange(inputValue)"
                      v-on="inputEvents.start"
                      placeholder="Select Date Range"
                      readonly
                    />
                  </div>
                </template>
              </vc-date-picker>
            </b-form-group>
          </div>
          <div class="d-flex">
            <div class="d-flex align-items-center h-100 mt-2" style="flex: 1">
              <b-button
                @click="getJobs(true)"
                block
                size="lg"
                variant="primary"
                style="width: 200px"
                >Search</b-button
              >
            </div>
            <div
              class="d-flex align-items-center h-100 mt-2"
              style="margin-left: 10px"
              v-if="isAnyFilterApplied"
            >
              <button type="button" @click="clear" class="btn ml-auto btn-clear-filter">
                <i class="di-remove-10 mr-2"></i>Clear filters
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content-wrapper-newsletter table-holder">
        <b-table
          :items="jobs"
          :fields="fields"
          class="newsletter-table"
          head-variant="light"
          responsive="sm"
          show-empty
          no-local-sorting
        >
          <template v-slot:cell(customer)="data">
            <div style="width: 200px">
              {{ getCustomerName(data.item) }}
            </div>
          </template>
          <template v-slot:cell(total)="data">
            ${{ Number(data.item.total_amount).toFixed(2) }}
          </template>
          <template v-slot:cell(id)="data">
            {{ data.item.name }}
          </template>
          <template v-slot:cell(status)="data">
            <div style="width: 200px">
              {{ data.item.display_financial_status }}
            </div>
          </template>
          <template v-slot:cell(created)="data">
            <template>
              {{ data.item.created_at | moment('timezone', timezone, 'Do MMM YYYY, ha') }}
            </template>
          </template>
          <template v-slot:cell(collapse)="data">
            <b-button
              variant="icon"
              @click="() => expandJobDetail(data)"
              class="btn-icon--collapse"
              :class="data.detailsShowing ? '' : 'collapsed'"
            >
              <i class="di-arrow-up-12" v-if="data.detailsShowing" />
              <i class="di-arrow-down-12" v-if="!data.detailsShowing" />
            </b-button>
          </template>
          <template v-slot:row-details="data">
            <div class="order-details">
              <div class="order-detail" v-if="data.item.customer">
                <p style="font-size: 16px; font-weight: 600">Customer:</p>
                <p>Name: {{ getCustomerName(data.item) }}</p>
                <p>Phone: {{ getPhoneFromAddress(data.item) }}</p>
                <p>Email: {{ data.item.customer.email }}</p>

                <p style="font-size: 16px; font-weight: 600">Payments:</p>
                <p>Method: {{ getPaymentMethods(data.item) }}</p>
              </div>

              <div v-for="product in data.item.items" :key="product.id">
                <div class="order-detail">
                  <p style="font-size: 16px; font-weight: 600">{{ product.name }}</p>
                  <p><b>Order Item Id:</b> {{ product.id }}</p>
                  <p><b>Amount:</b> ${{ product.total_amount }}</p>
                  <p><b>Discount:</b> ${{ product.discount_amount }}</p>
                  <p><b>Quantity:</b> {{ product.quantity }}</p>
                </div>
              </div>

              <div class="order-detail" v-if="data.item.note !== null && data.item.note !== ''">
                <p style="font-size: 16px; font-weight: 600">Note:</p>
                <p>{{ data.item.note }}</p>
              </div>

              <div class="order-detail" v-if="data.item.customer">
                <p style="font-size: 16px; font-weight: 600">Shipping Address:</p>
                <div>{{ getAddress(data.item) }}</div>
              </div>
            </div>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          hide-goto-end-buttons
          first-number
          last-number
          limit="7"
          class="text-semi-bold mb-0"
          @change="fetchPage"
        >
          <template v-slot:prev-text>
            <span><i class="di-arrow-left-12"></i> Previous</span>
          </template>
          <template v-slot:next-text>
            <span>Next <i class="di-arrow-right-12"></i></span>
          </template>
          <template v-slot:ellipsis-text>
            <span class="seperator">..</span>
          </template>
        </b-pagination>
        <div v-if="loading" class="text-center mt-3">
          <b-spinner></b-spinner>
        </div>
        <div v-if="!loading && jobs.length === 0" class="text-center mt-3">No data available.</div>
      </div>
    </div>
    <UpdateNewsletterModal />
  </div>
</template>

<script>
/* eslint-disable */
import UpdateNewsletterModal from '@/components/modals/UpdateNewsletterModal.vue';
import ClearableInput from '@/components/ClearableInput.vue';
import apiOrders from '@/api/orders';
import { mapState } from 'vuex';

export default {
  name: 'JobsDresdenGO',
  components: {
    UpdateNewsletterModal,
    ClearableInput,
  },
  data() {
    return {
      datePicker: {
        popover: {
          placement: 'bottom-end',
        },
        masks: {
          weekdays: 'WW',
          input: ['MMM DD'],
          data: ['DD/MM/YYYY'],
        },
        attributes: [
          {
            dot: {
              color: 'blue',
            },
            contentClass: 'text-semi-bold text-gray-950',
            dates: [new Date()],
          },
          {
            highlight: {
              class: 'bg-white',
              contentClass: 'text-semi-bold text-gray-950',
            },
            dates: [new Date()],
          },
          {
            popover: {
              visibility: 'hover',
              hideIndicator: true,
            },
            dates: [new Date()],
          },
        ],
      },
      jobs: [],
      q: null,
      currentPage: 1,
      perPage: 15,
      totalRows: 0,
      loading: false,
      createdAt: {
        start: null,
        end: null,
      },
      fields: [
        { key: 'customer', label: 'Customer' },
        { key: 'id', label: 'Order Id (#)' },
        { key: 'total', label: 'Total($)' },
        { key: 'status', label: 'Status' },
        { key: 'created', label: 'Created' },
        { key: 'collapse', label: '' },
      ],
    };
  },
  mounted() {
    this.getJobs();
  },

  methods: {
    getPaymentMethods(item) {
      if (!item.payment_gateway_names) {
        return '';
      }
      let methods = JSON.parse(item.payment_gateway_names);
      return methods.join(', ');
    },
    formattedRange(inputValue) {
      if (!inputValue.start && !inputValue.end) {
        return '';
      }
      const start = inputValue.start ? inputValue.start : '';
      const end = inputValue.end ? inputValue.end : '';
      return `${start} - ${end}`;
    },
    async expandJobDetail(row) {
      row.toggleDetails();
    },
    clear() {
      this.q = null;
      this.createdAt = {
        start: null,
        end: null,
      };

      this.currentPage = 1;
      this.getJobs();
    },
    getCustomerName(item) {
      if (item.customer && item.customer.display_name) {
        return item.customer.display_name;
      }

      if (item.customer && item.customer.first_name && item.customer.last_name) {
        return `${item.customer.first_name} ${item.customer.last_name}`;
      }

      return 'N/A';
    },
    getJobs(isSearch = false) {
      if (isSearch) {
        this.currentPage = 1;
      }

      this.loading = true;
      let data = {
        page: this.currentPage,
        per_page: this.perPage,
        outlet_id: this.selectedOutletId,
      };
      if (this.q) {
        data.q = this.q;
      }
      if (this.createdAt && this.createdAt.start) {
        data.created_start_at = this.createdStartAt;
      }
      if (this.createdAt.end) {
        data.created_end_at = this.createdEndAt;
      }
      apiOrders
        .getDresdenGoOrders({
          ...data,
        })
        .then(response => {
          this.jobs = response.data.data.map(job => ({
            ...job,
            showDetails: false,
          }));
          this.totalRows = response.data.total;
          this.perPage = response.data.per_page;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching users:', error);
          this.loading = false;
        });
    },
    fetchPage(pageNumber) {
      this.currentPage = pageNumber;
      this.getJobs();
    },
    getPhoneFromAddress(item) {
      if (!item.customer || !item.display_address_id) {
        return;
      }
      let address_id = item.display_address_id;
      let address = item.customer.address.find(address => address.id === address_id);

      if (!address || !address.phone) {
        return 'N/A';
      }

      return address.phone;
    },
    getAddress(item) {
      if (!item.customer || !item.display_address_id) {
        return;
      }
      let address_id = item.display_address_id;
      let address = item.customer.address.find(address => address.id === address_id);
      return this.formatAddress(address);
    },
    formatAddress(address) {
      if (address) {
        let formatted = JSON.parse(address.formatted);
        return formatted.join(', ');
      }
    },
    converDate(date) {
      date.setDate(date.getDate());
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let day = String(date.getDate()).padStart(2, '0');
      let formattedDate = `${year}-${month}-${day}`;
      return formattedDate + 'T00:00:00.000Z';
    },
  },
  computed: {
    ...mapState('auth', {
      selectedOutletId: state => state.selectedOutletId,
      selectedPrescriptionType: state => state.jobsModule.selectedPrescriptionType,
    }),
    isAnyFilterApplied() {
      return this.q || (this.createdAt && (this.createdAt.start || this.createdAt.end));
    },
    createdStartAt() {
      if (!this.createdAt.start) {
        return null;
      }
      return this.$moment(this.createdAt.start).utc().startOf('day').toISOString();
    },
    createdEndAt() {
      if (!this.createdAt.end) {
        return null;
      }
      return this.$moment(this.createdAt.end).utc().endOf('day').toISOString();
    },
  },
  watch: {
    $route(to) {
      let createdStartAt = null;
      let createdEndAt = null;
      if (to.query.created_start_at) {
        createdStartAt = converDate(to.query.created_start_at);
      }
      if (to.query.created_end_at) {
        createdEndAt = converDate(to.query.created_end_at);
      }
      this.q = to.query.q || null;
      this.createdAt = {
        start: createdStartAt,
        end: createdEndAt,
      };
      this.priority = to.query.priority;
    },
  },
};
</script>

<style scoped>
.order-details {
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.order-detail {
  background: #fff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: background 0.3s;
}
.order-detail:hover {
  background: #f1f1f1;
}
.order-detail p {
  margin: 0;
  padding: 5px 0;
  font-size: 14px;
}
.order-detail p b {
  font-weight: 600;
}
</style>
