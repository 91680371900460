<template>
  <div class="home">
    <div v-if="isLoading" class="home-spinner-container">
      <b-spinner />
    </div>
    <div v-else>
      <Navbar />
      <Nav />
      <div class="page-wrapper">
        <b-breadcrumb v-if="$route.meta.breadcrumb" :items="$route.meta.breadcrumb"></b-breadcrumb>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Navbar from '@/components/Navbar.vue';
import Nav from '@/components/Nav.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data() {
    return {
      isLoading: true,
    };
  },
  components: {
    Navbar,
    Nav,
  },
  async created() {
    Promise.all([
      await this.$store.dispatch('setOutlets'),
      this.setUser(),
      this.$store.dispatch('setRegions'),
      this.$store.dispatch('setOrderItemStatuses'),
      this.$store.dispatch('setOrderPaymentStatuses'),
      this.$store.dispatch('setOrderDispenseStatuses'),
    ]).then(() => {
      this.isLoading = false;
    });

    this.$store.commit('SET_SPHERES');
    this.$store.commit('SET_CYLINDERS');
    this.$store.dispatch('setPdSources');
    this.$store.dispatch('setPrescriptionTypes');
    this.$store.dispatch('setPrescriptionSources');
  },
  methods: {
    ...mapActions('auth', ['setUser']),
  },
};
</script>

<style>
.home-spinner-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
