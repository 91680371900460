<template>
  <div class="jobs-page-holder">
    <div class="jobs">
      <div class="main-content-wrapper-newsletter">
        <div class="page-header d-flex flex-row">
          <h1 class="m-0 flex-fill font-weight-bold">Newsletter Subscribers</h1>
        </div>
      </div>
      <div class="main-content-wrapper-newsletter">
        <div class="table-filter">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <ClearableInput
              name="emailFilter"
              placeholder="Filter by email"
              v-model="emailFilter"
              @input-cleared="clear"
              @enter="fetchUsers"
            >
              <template v-slot:prepend>
                <b-input-group-text class="px-3">
                  <i class="di-search-18" />
                </b-input-group-text>
              </template>
            </ClearableInput>

            <div class="d-flex align-items-center h-100">
              <b-button @click="fetchUsers" block size="lg" variant="primary">Search</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content-wrapper-newsletter table-holder">
        <b-table
          :items="users"
          :fields="fields"
          class="newsletter-table"
          head-variant="light"
          responsive="sm"
          show-empty
          no-local-sorting
        >
          <template v-slot:cell(update)="data">
            <b-button @click="openUpdatePopup(data.item)">Update</b-button>
          </template>

          <template v-slot:cell(types)="data">
            <div v-if="data.item.send_email">Email</div>
            <div v-if="data.item.send_phone">Phone</div>
            <div v-if="data.item.send_sms">Sms</div>
          </template>
          <template v-slot:cell(created_at)="data">
            <template>
              {{ data.item.created_at | moment('timezone', timezone, 'Do MMM YYYY, ha') }}
            </template>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          hide-goto-end-buttons
          first-number
          last-number
          limit="7"
          class="text-semi-bold mb-0"
          @change="fetchPage"
        >
          <template v-slot:prev-text>
            <span><i class="di-arrow-left-12"></i> Previous</span>
          </template>
          <template v-slot:next-text>
            <span>Next <i class="di-arrow-right-12"></i></span>
          </template>
          <template v-slot:ellipsis-text>
            <span class="seperator">..</span>
          </template>
        </b-pagination>
        <div v-if="loading" class="text-center mt-3">
          <b-spinner></b-spinner>
        </div>
        <div v-if="!loading && users.length === 0" class="text-center mt-3">No data available.</div>
      </div>
    </div>
    <UpdateNewsletterModal />
  </div>
</template>

<script>
import apiCustomers from '../api/customers';
import UpdateNewsletterModal from '@/components/modals/UpdateNewsletterModal.vue';
import { mapMutations } from 'vuex';
import ClearableInput from '@/components/ClearableInput.vue';

export default {
  name: 'NewsletterUsers',
  components: {
    UpdateNewsletterModal,
    ClearableInput,
  },
  data() {
    return {
      users: [],
      emailFilter: '',
      currentPage: 1,
      perPage: 30,
      totalRows: 0,
      loading: false,
      fields: [
        { key: 'user_email', label: 'Email' },
        { key: 'source_country', label: 'Country' },
        { key: 'source_name', label: 'Subscribed From' },
        { key: 'send_type', label: 'Subject' },
        { key: 'types', label: 'Channels' },
        { key: 'created_at', label: 'Date/Time' },
        { key: 'update', label: 'Actions' },
      ],
    };
  },
  mounted() {
    this.fetchUsers();
    this.$root.$on('newsletterUpdated', updatedData => {
      console.log('newsletterUpdated', updatedData);
      let user = this.users.find(user => user.user_email === updatedData.email);
      if (user) {
        user.send_type = updatedData.send_type;
        user.send_phone = updatedData.send_phone;
        user.send_sms = updatedData.send_sms;
        user.send_email = updatedData.send_email;
      }
    });
  },
  methods: {
    clear() {
      this.emailFilter = null;
      this.fetchUsers();
    },
    ...mapMutations('customer', {
      setSelectedCustomerNewsletter: 'SET_SELECTED_CUSTOMER_NEWSLETTER',
    }),
    fetchUsers() {
      this.loading = true;
      let data = {
        page: this.currentPage,
        per_page: this.perPage,
      };
      if (this.emailFilter) {
        data.email = this.emailFilter;
      }
      apiCustomers
        .getNewsletterUsers(data)
        .then(response => {
          this.users = response.data.data;
          this.totalRows = response.data.total;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching users:', error);
          this.loading = false;
        });
    },
    fetchPage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchUsers();
    },
    openUpdatePopup(user) {
      this.setSelectedCustomerNewsletter(user);
      this.$bvModal.show('newsletter-update-modal');
    },
  },
};
</script>

<style>
.seperator {
  margin: 0 5px;
}
</style>
