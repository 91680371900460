<template>
  <div>
    <b-row class="mb-3" v-if="hasHorizontal">
      <b-col cols="6" v-if="rightNearHorizontal || rightDistanceHorizontal">
        <h6 class="text-uppercase">Horizontal Prism Right</h6>
        <p class="mb-0" v-if="rightNearHorizontal">
          <span class="text-primary mr-2">NEAR</span>
          {{ rightNearHorizontal }}
        </p>
        <p class="mb-0" v-if="rightDistanceHorizontal">
          <span class="text-primary mr-2">DISTANCE</span>
          {{ rightDistanceHorizontal }}
        </p>
      </b-col>
      <b-col cols="6" v-if="leftNearHorizontal || leftDistanceHorizontal">
        <h6 class="text-uppercase">Horizontal Prism Left</h6>
        <p class="mb-0" v-if="leftNearHorizontal">
          <span class="text-primary mr-2">NEAR</span>
          {{ leftNearHorizontal }}
        </p>
        <p class="mb-0" v-if="leftDistanceHorizontal">
          <span class="text-primary mr-2">DISTANCE</span>
          {{ leftDistanceHorizontal }}
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-if="hasVertical">
      <b-col cols="6" v-if="rightNearVertical || rightDistanceVertical">
        <h6 class="text-uppercase">Vertical Prism Right</h6>
        <p class="mb-0" v-if="rightNearVertical">
          <span class="text-primary mr-2">NEAR</span>
          {{ rightNearVertical }}
        </p>
        <p class="mb-0" v-if="rightDistanceVertical">
          <span class="text-primary mr-2">DISTANCE</span>
          {{ rightDistanceVertical }}
        </p>
      </b-col>
      <b-col cols="6" v-if="leftNearVertical || leftDistanceVertical">
        <h6 class="text-uppercase">Vertical Prism Left</h6>
        <p class="mb-0" v-if="leftNearVertical">
          <span class="text-primary mr-2">NEAR</span>
          {{ leftNearVertical }}
        </p>
        <p class="mb-0" v-if="leftDistanceVertical">
          <span class="text-primary mr-2">DISTANCE</span>
          {{ leftDistanceVertical }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PrismDisplay',
  props: {
    prescription: Object,
  },
  computed: {
    rightNearHorizontal() {
      return this.prescription.prism_string.near.right.horiz;
    },
    rightDistanceHorizontal() {
      return this.prescription.prism_string.distance.right.horiz;
    },
    leftNearHorizontal() {
      return this.prescription.prism_string.near.left.horiz;
    },
    leftDistanceHorizontal() {
      return this.prescription.prism_string.distance.left.horiz;
    },
    rightNearVertical() {
      return this.prescription.prism_string.near.right.vert;
    },
    rightDistanceVertical() {
      return this.prescription.prism_string.distance.right.vert;
    },
    leftNearVertical() {
      return this.prescription.prism_string.near.left.vert;
    },
    leftDistanceVertical() {
      return this.prescription.prism_string.distance.left.vert;
    },
    hasHorizontal() {
      return (
        this.rightNearHorizontal ||
        this.rightDistanceHorizontal ||
        this.leftNearHorizontal ||
        this.leftDistanceHorizontal
      );
    },
    hasVertical() {
      return (
        this.rightNearVertical ||
        this.rightDistanceVertical ||
        this.leftNearVertical ||
        this.leftDistanceVertical
      );
    },
  },
};
</script>
