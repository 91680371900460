<template>
  <div>
    <div class="table-title-wrapper">
      <h6 class="text-uppercase rd-title" v-if="showTitle">Pupil Distances</h6>
      <b-button variant="icon-text-transparent mb-2 ml-2" @click="onClick">
        <span class="icon">
          <i class="di-create-18" />
        </span>
        <span class="text">Add PD</span>
      </b-button>
      <a @click="resetPd" v-show="selectable && selectedPd">Reset</a>
    </div>
    <ul class="list-unstyled rd-list pupil-distance-list">
      <PupilDistanceListItem
        v-for="pd in orderedPds"
        :key="pd.id"
        :pd="pd"
        :customerName="customerFullName"
        :selectable="selectable"
        :collapsible="collapsible"
        :expanded="expanded"
        :showProvider="showProvider"
        @edit="onEdit"
        @deleted="onDeleted"
      />
    </ul>
    <hr />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PupilDistanceListItem from './PupilDistanceListItem.vue';

export default {
  name: 'PupilDistanceList',
  components: { PupilDistanceListItem },
  props: {
    customer: Object,
    showTitle: Boolean,
    selectable: Boolean,
    collapsible: Boolean,
    expanded: Boolean,
    showProvider: Boolean,
  },
  computed: {
    ...mapState({
      selectedPd: state => state.jobsModule.selectedPd,
    }),
    orderedPds() {
      return this.customer.pds?.data?.sort(
        (a, b) => this.$moment(b.created_at) - this.$moment(a.created_at),
      );
    },
    customerFullName() {
      return `${this.customer.first_name} ${this.customer.last_name}`;
    },
  },
  mounted() {
    if (this.selectedPd) {
      const selectedPd = this._.find(this.orderedPds, {
        id: this._.get(this.selectedPd, 'detail.id'),
      });

      if (selectedPd) {
        this.$store.commit('jobsModule/SET_SELECTED_PD', {
          detail: selectedPd,
          pdPresenter: selectedPd.pdPresenter,
        });
      }
    }
  },
  methods: {
    resetPd() {
      this.$store.commit('jobsModule/SET_SELECTED_PD', null);
    },
    onEdit(e) {
      this.$emit('edit', {
        customer: {
          id: this.customer.id,
          full_name: `${this.customer.first_name} ${this.customer.last_name}`,
        },
        pd: e.pd,
      });
    },
    onClick() {
      this.$emit('new', {
        customer: this.customer,
      });
    },
    onDeleted(e) {
      this.$root.$emit('newPdAdded');
      this.$emit('deleted', e);
    },
  },
};
</script>
