<template>
  <span v-if="standardDisplay" style="width: 100%">
    <span v-if="!isOnlyFilePrescription && !isContactLens">
      <div class="mb-2" v-if="standardDisplay.right" v-html="displaySide('right')" />
      <div class="mb-2" v-if="standardDisplay.left" v-html="displaySide('left')" />
    </span>
    <span v-if="isOnlyFilePrescription">
      <p class="error">Please edit prescription</p>
    </span>
    <b-row class="mt-3" v-if="isContactLens">
      <b-col cols="6">
        <div class="lenses-to-pick-box">
          <h6 class="text-uppercase">Right</h6>
          <div
            class="mb-2"
            v-if="standardDisplay.right"
            v-html="displaySideContactLens(standardDisplay.right)"
          />
          <div v-if="prescription.contact_lens.right_near_add">
            <span class="contact-lens-title">Near Add</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.right_near_add }}</span>
          </div>
          <div v-if="prescription.contact_lens.right_near_add_enum">
            <span class="contact-lens-title">Near Add Type</span>
            <span class="contact-lens-data">{{
              prescription.contact_lens.right_near_add_enum
            }}</span>
          </div>
          <div v-if="prescription.contact_lens.right_base_curve">
            <span class="contact-lens-title">Base Curve</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.right_base_curve }}</span>
          </div>
          <div v-if="prescription.contact_lens.right_diameter">
            <span class="contact-lens-title">Diameter</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.right_diameter }}</span>
          </div>
          <div v-if="prescription.contact_lens.right_type">
            <span class="contact-lens-title">Type</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.right_type }}</span>
          </div>
          <div v-if="prescription.contact_lens.right_supplier">
            <span class="contact-lens-title">Brand</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.right_supplier }}</span>
          </div>
          <div v-if="prescription.contact_lens.right_supplier">
            <span class="contact-lens-title">Supplier</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.right_supplier }}</span>
          </div>
          <div v-if="prescription.contact_lens.right_colour">
            <span class="contact-lens-title">Color</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.right_colour }}</span>
          </div>
          <div v-if="prescription.contact_lens.right_notes">
            <span class="contact-lens-title">Note</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.right_notes }}</span>
          </div>
        </div>
      </b-col>

      <b-col cols="6">
        <div class="lenses-to-pick-box">
          <h6 class="text-uppercase">Left</h6>
          <div
            class="mb-2"
            v-if="standardDisplay.left"
            v-html="displaySideContactLens(standardDisplay.left)"
          />
          <div v-if="prescription.contact_lens.left_near_add">
            <span class="contact-lens-title">Near Add</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.left_near_add }}</span>
          </div>
          <div v-if="prescription.contact_lens.left_near_add_enum">
            <span class="contact-lens-title">Near Add Type</span>
            <span class="contact-lens-data">{{
              prescription.contact_lens.left_near_add_enum
            }}</span>
          </div>
          <div v-if="prescription.contact_lens.left_base_curve">
            <span class="contact-lens-title">Base Curve</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.left_base_curve }}</span>
          </div>
          <div v-if="prescription.contact_lens.left_diameter">
            <span class="contact-lens-title">Diameter</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.left_diameter }}</span>
          </div>
          <div v-if="prescription.contact_lens.left_type">
            <span class="contact-lens-title">Type</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.left_type }}</span>
          </div>
          <div v-if="prescription.contact_lens.left_supplier">
            <span class="contact-lens-title">Brand</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.left_supplier }}</span>
          </div>
          <div v-if="prescription.contact_lens.left_supplier">
            <span class="contact-lens-title">Supplier</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.left_supplier }}</span>
          </div>
          <div v-if="prescription.contact_lens.left_colour">
            <span class="contact-lens-title">Color</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.left_colour }}</span>
          </div>
          <div v-if="prescription.contact_lens.left_notes">
            <span class="contact-lens-title">Note</span>
            <span class="contact-lens-data">{{ prescription.contact_lens.left_notes }}</span>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="mb-2" v-if="nearAddDetail" v-html="displayNearAdd(standardDisplay.add)" />
    <div v-if="intAddDetail" v-html="displayIntAdd(standardDisplay.add)" />
    <div v-show="file">
      <b-button variant="outline-secondary" size="sm" @click="handleViewFile">
        <svgicon name="paperclip" />
        <span class="ml-1">View File</span>
      </b-button>
      <FileModal
        :editOnPagePrescription="editOnPagePrescription"
        :prescription="prescription.detail"
      />
    </div>
  </span>
</template>

<script>
import FileModal from '@/components/modals/FileModal.vue';
import { mapMutations } from 'vuex';

export default {
  name: 'PrescriptionDetail',
  props: {
    prescription: Object,
    editOnPagePrescription: Function,
    lensesToPick: Object,
  },
  components: { FileModal },

  computed: {
    standardDisplay() {
      return this._.get(this.prescription, 'standardised_prescription_display_array');
    },
    nearAddDetail() {
      if (this.standardDisplay.add) {
        return this.standardDisplay.add.indexOf('Near Add') > -1;
      }
      return false;
    },
    isContactLens() {
      if (
        this._.get(this.prescription, 'type_id') === 2 &&
        this._.get(this.prescription, 'contact_lens')
      ) {
        return true;
      }
      return false;
    },
    intAddDetail() {
      if (this.standardDisplay.add) {
        return this.standardDisplay.add.indexOf('Int Add') > -1;
      }
      return false;
    },
    isOnlyFilePrescription() {
      if (
        this.standardDisplay.left === 'L Plano DS' &&
        this.standardDisplay.right === 'R Plano DS' &&
        this.prescription.add_int == null &&
        this.prescription.add_near == null &&
        this.prescription.left_int_add == null &&
        this.prescription.right_int_add == null &&
        this.prescription.right_near_add == null &&
        this.prescription.left_near_add == null &&
        this.prescription.files &&
        this.prescription.files.length > 0
      ) {
        return true;
      }
      return false;
    },
    file() {
      if (typeof this.prescription !== 'undefined') {
        if (this.prescription.files) {
          return this.prescription.files[0];
        }
      }
      return null;
    },
  },
  methods: {
    ...mapMutations({
      setSelectedPrescription: 'SET_SELECTED_PRESCRIPTION',
      setSelectedFile: 'SET_SELECTED_FILE',
    }),
    displaySide(side) {
      const standardDisplay = this.standardDisplay[side];
      if (!standardDisplay) return '';
      let display = standardDisplay;

      const [firstChar, ...rest] = display;
      return `<span class="text-medium mr-2">${firstChar}</span>${rest.join('')}`;
    },
    displaySideContactLens(side) {
      return `${side.slice(1)}`;
    },
    displayNearAdd(add) {
      const nearAddIndex = add.indexOf('Near Add');
      const nearAddDetailEnd = add.indexOf(',');
      return `
      <span class="text-medium mr-2">${add.slice(nearAddIndex, 8)}</span> ${add.slice(
        nearAddIndex + 8,
        nearAddDetailEnd > -1 ? nearAddDetailEnd : undefined,
      )}`;
    },
    displayIntAdd(add) {
      const intAddIndex = add.indexOf('Int Add');
      return `
      <span class="text-medium mr-2">${add.slice(intAddIndex, intAddIndex + 7)}</span> ${add.slice(
        intAddIndex + 7,
      )}`;
    },
    handleViewFile() {
      this.setSelectedPrescription(this.prescription);
      this.setSelectedFile(this.file);
      this.$bvModal.show('file-modal');
    },
  },
};
</script>
