<template>
  <b-modal
    header-class="modal-header--dialog"
    body-class="modal-body--dialog-form text-left"
    footer-class="modal-footer--dialog justify-content-center"
    :title="this.orderRefundType == 'void' ? 'Void' : 'Request a full refund'"
    :ok-title="this.orderRefundType == 'void' ? 'Void' : 'Request Refund'"
    size="sm"
    centered
    id="jobs-refund-request-modal"
    @ok="onSubmit"
    lazy
    :busy="isLoading"
  >
    <b-form v-if="shouldRenderForm">
      <p>
        This order, along with all its items, will be designated as "Refunded." If required, please
        generate a new order.
      </p>
      <p class="refund-warning">
        Any necessary refunds to Stripe and Gift Up (if applicable) must be processed manually.
      </p>
      <!-- <p class="refund-warning">
        This order will be marked cancelled, and ${{ amount }} will be refunded to
        {{ customerName }}.
      </p> -->
      <b-form-group label="Select a reason" label-for="status" class="mb-0">
        <b-form-select v-model="selectedReason" name="status">
          <b-form-select-option v-for="reason in reasons" :key="reason.slug" :value="reason.slug">
            {{ reason.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-form>
    <div v-if="isLoading" class="text-center mt-3">
      <b-spinner />
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import apiOrders from '@/api/orders';
import apiNotes from '@/api/notes';
import dayjs from 'dayjs';
import storage from '../support/storage';

export default {
  name: 'JobsRefundRequestModal',
  data() {
    return {
      reasons: [],
      selectedReason: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['selectedOrderItems']),
    ...mapGetters({
      selectedOrder: 'selectedOrder',
      refundType: 'refundType',
      userFullName: 'auth/userFullName',
      selectedOutlet: 'auth/selectedOutlet',
    }),
    shouldRenderForm() {
      return this.refundType && (this.selectedOrder || this.selectedOrderItems.length > 0);
    },
    isFullRefund() {
      return this.refundType === 'full';
    },
    orderRefundType() {
      return this._.get(this.selectedOrder, 'refund_type');
    },
    amount() {
      if (this.isFullRefund) {
        return this.selectedOrder.total_payment;
      }

      return this.selectedOrderItems.reduce(
        (total, { region_product: { price } }) => total + price,
        0,
      );
    },
    customerName() {
      let first_name = '';
      let last_name = '';

      if (this.isFullRefund) {
        if (this.selectedOrder.customer) {
          ({ first_name, last_name } = this.selectedOrder.customer);
        } else {
          // for guest
          ({ customer_first_name: first_name, customer_last_name: last_name } =
            this.selectedOrder.order_billing);
        }
      } else if (this.selectedOrderItems[0].customer) {
        ({ first_name, last_name } = this.selectedOrderItems[0].customer);
      }

      return `${first_name} ${last_name}`;
    },
  },
  created() {
    this.getReasons();
  },
  methods: {
    ...mapMutations({
      updateJobInJobs: 'UPDATE_JOB_IN_JOBS',
    }),
    async getReasons() {
      let reasons = storage.get('refundReasons');
      if (!reasons) {
        const response = await apiOrders.getRefundReasons();
        reasons = response.data.data;
        storage.set('refundReasons', reasons, dayjs().add(1, 'week').valueOf());
      }
      this.reasons = reasons;
    },
    async onSubmit(e) {
      try {
        e.preventDefault();
        this.isLoading = true;
        if (this.isFullRefund) {
          await this.fullRefundRequest();
        } else {
          await this.partialRefundRequest();
        }
        this.$bvModal.hide('jobs-refund-request-modal');
        this.$store.dispatch('showSuccessAlert', 'Successfully refunded');
      } catch (error) {
        this.$store.dispatch('showErrorAlert', error.response.data.error);
      }
      this.isLoading = false;
    },
    async fullRefundRequest() {
      const payload = { reason: this.selectedReason };
      const response = await apiOrders.requestRefund(this.selectedOrder.id, payload);
      const { data: order, error } = response.data;
      if (error) {
        this.$store.dispatch('showErrorAlert', error);
      } else {
        await this.addNoteToOrder(order);
        await this.updateOrder(order.id);
      }
    },
    partialRefundRequest() {
      // TODO: wait and implement ep
    },
    async updateOrder(id) {
      const response = await apiOrders.getOrder(id);
      this.updateJobInJobs(response.data.data);
    },
    async addNoteToOrder(order) {
      // const note = `${this.$options.filters.titlecase(this.refundType)} refund requested for ${order.vend_invoice_number}\nReference: ${this.customerName}\nRefund_id: ${refund.id}\nLink: https://dashboard.stripe.com/payments/${refund.id}\nRefund processed by: ${this.userFullName} @ ${this.selectedOutlet.name}`;
      const note = `${this.$options.filters.titlecase(this.refundType)} refund requested for ${
        order.vend_invoice_number
      }\nRefund processed by ${this.userFullName} @ ${this.selectedOutlet.name}`;
      await apiNotes.createOrderNote(order.id, { content: note });
    },
  },
};
</script>

<style scoped>
.refund-warning {
  font-weight: 500;
}
</style>
