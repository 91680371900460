<template>
  <ValidationObserver v-slot="{ passes }">
    <b-modal id="new-customer-modal" size="xl" ref="newCustomerModal" @show="onShow" @hide="onHide">
      <template v-slot:modal-header="{ close }">
        <b-container>
          <div class="modal-title">
            <h3 class="text-bold text-center">{{ isEdit ? 'Edit' : 'New' }} Customer</h3>
            <button type="button" aria-label="Close" class="close" @click="close">
              <i class="di-remove-10"></i>
            </button>
          </div>
        </b-container>
      </template>
      <div class="container">
        <ul class="text-danger" v-if="errors.length">
          <li v-for="(error, index) in errors" v-bind:key="index">{{ error }}</li>
        </ul>
        <h5 class="mb-4">Customer Informations</h5>
        <b-form ref="newCustomerForm">
          <section>
            <h6 class="text-uppercase mb-3">Personal Details</h6>
            <b-row>
              <b-col cols="6">
                <ValidationProvider
                  vid="first-name"
                  name="First Name"
                  mode="lazy"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-group label="First Name" label-for="first-name">
                    <b-form-input
                      id="first-name"
                      v-model="form.first_name"
                      :state="getValidationState(validationContext)"
                      type="text"
                      placeholder="i.e. John"
                      autofocus
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Last Name" label-for="last-name">
                  <b-form-input
                    id="last-name"
                    v-model="form.last_name"
                    type="text"
                    placeholder="i.e. Doe"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </section>
          <div class="divider mt-1 mb-4"></div>
          <section>
            <h6 class="text-uppercase mb-3">Contact Details</h6>
            <b-row>
              <b-col cols="6">
                <ValidationProvider
                  vid="email"
                  name="Email"
                  mode="lazy"
                  rules="email"
                  v-slot="validationContext"
                >
                  <b-form-group label="Email" label-for="email">
                    <b-form-input
                      v-model="form.email"
                      :state="getValidationState(validationContext)"
                      type="email"
                      placeholder="i.e. johndoe@acmemail.com"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Marketing Opt-in"
                  label-for="marketing-opt-in"
                  class="flex-fill"
                >
                  <b-form-radio-group
                    id="marketing-opt-in"
                    v-model="form.new_opt_in"
                    :options="optIns"
                    @change="listenChangeNewOptIn"
                    buttons
                    button-variant="white"
                    class="custom-radio-button"
                  />
                </b-form-group>

                <b-form-group label="Channels" label-for="marketing-opt-in" class="flex-fill">
                  <b-form-checkbox
                    v-model="form.channel_sms"
                    class="custom-checkbox-button"
                    name="check-button"
                  >
                    Sms
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="form.channel_phone"
                    class="custom-checkbox-button"
                    name="check-button"
                  >
                    Phone
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="form.channel_email"
                    class="custom-checkbox-button"
                    name="check-button"
                  >
                    Email
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Mobile phone" label-for="mobile">
                  <CustomTelInput name="mobile" v-model="form.mobile" />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Phone" label-for="phone">
                  <CustomTelInput name="phone" v-model="form.phone" />
                </b-form-group>
              </b-col>
            </b-row>
          </section>
          <div class="divider mt-1 mb-4"></div>
          <section>
            <h6 class="text-uppercase mb-3">Others</h6>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Occupation" label-for="occupation">
                  <b-form-input
                    id="occupation"
                    v-model="form.occupation"
                    type="text"
                    placeholder="i.e. Teacher"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Hobbies" label-for="hobbies">
                  <b-form-input
                    id="hobbies"
                    v-model="form.hobbies"
                    type="text"
                    placeholder="i.e. Cycling, reading, hiking"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Date of birth" label-for="dob">
                  <b-form-input id="dob" name="dob" v-model="form.dob" type="date"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Gender" label-for="gender">
                  <b-form-radio-group
                    id="gender"
                    v-model="form.gender"
                    :options="genders"
                    buttons
                    button-variant="white"
                    class="custom-radio-button"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </section>
        </b-form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <div class="flex-fill m-0">
          <div class="container">
            <div class="d-flex justify-content-end">
              <b-button variant="outline-light" @click="cancel()">Cancel</b-button>
              <b-button
                variant="primary"
                @click="passes(submit)"
                class="ml-3"
                :disabled="isLoading"
              >
                <b-spinner v-if="isLoading" small />
                <span v-else>Confirm</span>
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import apiCustomers from '../api/customers';
import CustomTelInput from './CustomTelInput.vue';

export default {
  name: 'NewCustomerModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomTelInput,
  },
  props: ['customer'],
  data() {
    return {
      genders: ['Male', 'Female'],
      optIns: [
        { text: 'All Offers, News and Health', value: 'ALL' },
        { text: 'Only Health Subjects', value: 'HEALTH' },
        { text: 'None', value: 'NONE' },
      ],
      form: {
        first_name: '',
        last_name: '',
        gender: '',
        dob: '',
        email: '',
        new_opt_in: 'ALL',
        mobile: '',
        phone: '',
        occupation: '',
        hobbies: '',
        channel_phone: true,
        channel_sms: true,
        channel_email: true,
      },
      errors: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapState('customer', ['selectedCustomer']),
    ...mapState('auth', {
      currentUser: state => state.user,
      selectedRegionId: state => state.selectedRegionId,
    }),
    isEdit() {
      return !!this.selectedCustomer;
    },
  },
  methods: {
    onShow() {
      if (this.isEdit) {
        this.populateForm(this.selectedCustomer);
      }
    },
    onHide() {
      this.isLoading = false;
      this.form = this.$options.data.apply(this).form;
      this.errors = [];
    },
    populateForm(customer) {
      this.form = Object.keys(this.form).reduce((obj, field) => {
        const val = customer[field];

        if (field === 'dob' && val) {
          Object.assign(obj, { [field]: this.$moment(val).format('YYYY-MM-DD') });
        } else {
          Object.assign(obj, { [field]: val });
        }

        if (field.includes('channel') && val) {
          Object.assign(obj, { [field]: true });
        }

        return obj;
      }, {});
    },
    async submit() {
      this.isLoading = true;
      try {
        if (this.isEdit) {
          await apiCustomers.updateCustomer(this.selectedCustomer.id, this.form);
          this.$store.dispatch('showSuccessAlert', 'Customer has been successfully updated!');
        } else {
          await apiCustomers.postCustomer({
            region_id: this.currentUser.outlet.region_id,
            outlet_id: this.currentUser.outlet.id,
            home_region: this.selectedRegionId,
            active_region: this.selectedRegionId,
            ...this.form,
          });
          this.$store.dispatch('showSuccessAlert', 'New customer has been successfully created!');
        }
        this.$nextTick(() => {
          this.$refs.newCustomerModal.hide('ok');
          this.$emit('customer-added');
        });
      } catch (error) {
        this.isLoading = false;
        this.errors = error.response.data.error;
      }
    },
    updateNewOptIn() {
      if (
        this.form.channel_sms &&
        this.form.channel_phone &&
        this.form.channel_email &&
        this.form.new_opt_in !== 'HEALTH'
      ) {
        this.form.new_opt_in = 'ALL';
      } else if (!this.form.channel_sms && !this.form.channel_phone && !this.form.channel_email) {
        this.form.new_opt_in = 'NONE';
      }
    },
    listenChangeNewOptIn() {
      if (this.form.new_opt_in === 'NONE') {
        this.form.channel_sms = false;
        this.form.channel_phone = false;
        this.form.channel_email = false;
      } else if (this.form.new_opt_in === 'ALL' || this.form.new_opt_in === 'HEALTH') {
        this.form.channel_sms = true;
        this.form.channel_phone = true;
        this.form.channel_email = true;
      }
    },
  },
  watch: {
    'form.channel_sms': function (newValue) {
      this.updateNewOptIn();
    },
    'form.channel_phone': function (newValue) {
      this.updateNewOptIn();
    },
    'form.channel_email': function (newValue) {
      this.updateNewOptIn();
    },
  },
};
</script>
