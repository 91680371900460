<template>
  <ValidationObserver v-slot="{ passes }">
    <b-modal id="address-form-modal" size="xl" @show="onShow" @hide="onHide">
      <template v-slot:modal-header="{ close }">
        <b-container>
          <div class="modal-title">
            <h3 class="text-bold text-center">{{ form.id ? 'Edit Address' : 'New Address' }}</h3>
            <button class="close" type="button" aria-label="Close" @click="close">
              <i class="di-remove-10"></i>
            </button>
          </div>
        </b-container>
      </template>
      <div>
        <b-form class="container" novalidate="novalidate">
          <h5 class="mb-4">Address Information</h5>
          <section>
            <b-row>
              <b-col cols="12" sm="6">
                <ValidationProvider
                  name="Address Type"
                  rules="required"
                  mode="lazy"
                  v-slot="validationContext"
                >
                  <b-form-group label="Address Type" label-for="type">
                    <b-form-select
                      v-model="form.address.type"
                      :state="getValidationState(validationContext)"
                      name="type"
                    >
                      <b-form-select-option v-for="option in types" :key="option" :value="option">{{
                        option
                      }}</b-form-select-option>
                    </b-form-select>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group label="Description (optional)" label-for="description">
                  <b-form-input id="description" v-model="form.address.description"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </section>
          <div class="divider mt-2 mb-4"></div>
          <section>
            <h6 class="text-uppercase mb-3">Contact details</h6>
            <b-row>
              <b-col cols="12" sm="6">
                <ValidationProvider
                  name="First Name"
                  rules="required"
                  mode="lazy"
                  v-slot="validationContext"
                >
                  <b-form-group label="First Name" label-for="first-name">
                    <b-form-input
                      id="first-name"
                      v-model="form.contact.first_name"
                      :state="getValidationState(validationContext)"
                      autofocus=""
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12" sm="6">
                <ValidationProvider
                  name="Last Name"
                  rules="required"
                  mode="lazy"
                  v-slot="validationContext"
                >
                  <b-form-group label="Last Name" label-for="last-name">
                    <b-form-input
                      id="last-name"
                      v-model="form.contact.last_name"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <ValidationProvider
                  vid="email"
                  name="Email address"
                  rules="required|email"
                  mode="lazy"
                  v-slot="validationContext"
                >
                  <b-form-group label="E-mail address" label-for="email">
                    <b-form-input
                      id="email"
                      v-model="form.contact.email"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group label="Mobile" label-for="mobile">
                  <b-form-input id="mobile" v-model="form.contact.mobile"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </section>
          <div class="divider mt-2 mb-4"></div>
          <section>
            <h6 class="text-uppercase mb-3">Address Details</h6>
            <b-row>
              <b-col cols="12" sm="6">
                <ValidationProvider
                  name="Street Address"
                  rules="required"
                  mode="lazy"
                  v-slot="validationContext"
                >
                  <b-form-group label="Street Address" label-for="address1">
                    <b-form-input
                      id="address1"
                      v-model="form.address.address1"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12" sm="6">
                <b-form-group label="Unit, house or level (optional)" label-for="address2">
                  <b-form-input id="address2" v-model="form.address.address2"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="4">
                <b-form-group label="Suburb (optional)" label-for="suburb">
                  <b-form-input id="suburb" v-model="form.address.suburb"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="4">
                <ValidationProvider
                  name="City"
                  rules="required"
                  mode="lazy"
                  v-slot="validationContext"
                >
                  <b-form-group label="City" label-for="city">
                    <b-form-input
                      id="city"
                      v-model="form.address.city"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12" sm="4">
                <b-form-group label="State or Province" label-for="state">
                  <b-form-input id="state" v-model="form.address.state"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" sm="6">
                <ValidationProvider
                  name="Postcode"
                  rules="required"
                  mode="lazy"
                  v-slot="validationContext"
                >
                  <b-form-group label="Postcode" label-for="postcode">
                    <b-form-input
                      id="postcode"
                      v-model="form.address.postcode"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12" sm="6">
                <ValidationProvider
                  name="Country"
                  rules="required"
                  mode="lazy"
                  v-slot="validationContext"
                >
                  <b-form-group label="Country or Territory" label-for="country">
                    <b-form-select
                      id="country"
                      name="country"
                      v-model="form.address.country"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-select-option
                        v-for="(countryData, countryIndex) in countries"
                        :value="countryData.abbreviation"
                        :key="countryIndex"
                        >{{ countryData.country }}</b-form-select-option
                      >
                    </b-form-select>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </section>
        </b-form>
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <div class="flex-fill m-0">
          <div class="container">
            <div class="d-flex">
              <b-button class="mr-3" variant="outline-light" @click="cancel">Cancel</b-button>
              <b-button
                class="mr-3"
                variant="primary"
                type="submit"
                @click="passes(confirm)"
                :disabled="isLoading"
              >
                <b-spinner v-if="isLoading" small=""></b-spinner> Confirm
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import countries from '@/data/countries';
import apiAddresses from '@/api/addresses';

export default {
  name: 'AddressForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      countries,
      types: ['Physical', 'Postal', 'Home', 'Work', 'Billing', 'Shipping', 'Other'],
      isLoading: false,
      errors: null,
      form: {
        id: undefined,
        contact: {
          first_name: undefined,
          last_name: undefined,
          email: undefined,
          mobile: undefined,
        },
        address: {
          type: undefined,
          description: undefined,
          address1: undefined,
          address2: undefined,
          suburb: undefined,
          city: undefined,
          state: undefined,
          postcode: undefined,
          country: undefined,
        },
      },
    };
  },
  computed: {
    ...mapState({
      addressFormData: state => state.addressModal.newAddressFormData,
      addressFormCallback: state => state.addressModal.addressFormCallback,
    }),
    ...mapGetters('auth', ['currentRegion']),
    contact() {
      return {
        first_name: this._.get(this.addressFormData, 'first_name'),
        last_name: this._.get(this.addressFormData, 'last_name'),
        email: this._.get(this.addressFormData, 'email'),
        mobile: this._.get(this.addressFormData, 'mobile'),
      };
    },
    address() {
      return this._.get(this.addressFormData, 'address');
    },
    customer() {
      return this._.get(this.addressFormData, 'customer');
    },
    isDynamic() {
      return this._.get(this.addressFormData, 'is_dynamic');
    },
  },
  methods: {
    ...mapMutations('addressModal', {
      setShippingAddress: 'SET_SHIPPING_ADDRESS',
      setSelectedBillingAddressId: 'SET_SELECTED_BILLING_ADDRESS_ID',
    }),
    onShow() {
      this.populateData();
    },
    onHide() {
      this.$store.commit('addressModal/HIDE_NEW_ADDRESS_FORM');
    },
    confirm() {
      this.isLoading = true;
      this.errors = null;
      if (this.form.id) {
        if (this.isDynamic) {
          apiAddresses
            .postBillingAddress({
              ...this.form,
              customer_id: this._.get(this.customer, 'id'),
            })
            .then(this.onSuccess)
            .catch(this.onError);
        } else {
          apiAddresses
            .putBillingAddress({
              ...this.form.contact,
              address: this.form.address,
              id: this.form.id,
            })
            .then(this.onSuccess)
            .catch(this.onError);
        }
      } else if (this.customer) {
        apiAddresses
          .postBillingAddress({
            ...this.form,
            customer_id: this._.get(this.customer, 'id'),
          })
          .then(this.onSuccess)
          .catch(this.onError);
      } else {
        this.$store.dispatch('showErrorAlert', 'New address cannot be added to guests.');
      }
    },
    populateData() {
      this.isLoading = false;
      this.errors = null;
      this.form = {
        id: this._.get(this.addressFormData, 'id'),
        contact: {
          ...this.contact,
          ...this._.pick(this.customer, Object.keys(this.form.contact)),
        },
        address: {
          type: 'Shipping',
          ...this.address,
          country: this.address?.country || this.currentRegion.country_code,
        },
      };
    },
    onSuccess(response) {
      this.setShippingAddress(response.data.data);
      this.setSelectedBillingAddressId(response.data.data.id);
      if (this.addressFormCallback) {
        this.addressFormCallback(response);
      }

      this.isLoading = false;
      this.hideAddAddressModal();
      this.$store.dispatch('showSuccessAlert', 'New address has been successfully created!');
    },
    onError({ response }) {
      const message = this._.get(response, 'data.message');
      this.$store.dispatch('showErrorAlert', message);
      this.isLoading = false;
    },
  },
};
</script>
