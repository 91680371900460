import { extend } from 'vee-validate';
/* eslint-disable */
import {
  required,
  required_if,
  email,
  min_value,
  regex,
  confirmed,
  ext,
  size as fileSize,
} from 'vee-validate/dist/rules';
/* eslint-enable */

export default () => {
  extend('required_with', {
    validate(value, { target }) {
      return value ? !!target : true;
    },
    params: ['target', 'otherField'],
    message: 'The {otherField} field is required when the {_field_} field has value',
  });
  extend('required_if', required_if);
  extend('email', email);
  extend('min_value', {
    // eslint-disable-next-line camelcase
    ...min_value,
    message: '{_field_} must be greater than {min}.',
  });
  extend('maxValue', {
    validate(value, { maxValue }) {
      return value <= Number(maxValue);
    },
    params: ['maxValue'],
    message: 'The {_field_} must be lower than  {maxValue} ',
  });
  extend('halfValue', {
    validate(value) {
      return value % 0.5 === 0;
    },
    message: 'Please enter a valid PD value',
  });
  extend('minLength', {
    validate(value, { minValue }) {
      return value.length >= minValue;
    },
    params: ['minValue'],
    message: 'The {_field_} field has to be at least {minValue} characters',
  });
  extend('maxLength', {
    validate(value, { maxValue }) {
      return value.length <= maxValue;
    },
    params: ['maxValue'],
    message: 'The {_field_} field can be maximum {maxValue} characters',
  });
  extend('between', {
    validate(value, { minVal, max }) {
      const valueNumber = Number(value);
      return Number(minVal) <= valueNumber && valueNumber <= Number(max);
    },
    params: ['minVal', 'max'],
    message: 'The {_field_} field has to be between {minVal} and {max}',
  });
  extend('maxSize', {
    validate(files, { size }) {
      if (Number.isNaN(size)) {
        return false;
      }

      const nSize = size * 1024 * 1024;
      if (!Array.isArray(files)) {
        return files.size <= nSize;
      }

      for (let i = 0; i < files.length; i += 1) {
        if (files[i].size > nSize) {
          return false;
        }
      }

      return true;
    },
    params: ['size'],
    message: 'The {_field_} field has to be smaller than {size}MB',
  });
  extend('required', {
    ...required,
    message: 'The {_field_} field is required.',
  });
  extend('regex', regex);
  extend('confirmed', confirmed);
  extend('ext', ext);
  extend('fileSize', fileSize);
  extend('no_mustache', text => {
    if (!text.match(/{{\s?([^}]*)\s?}}/g)) {
      return true;
    }
    return 'Message has unresolved variable(s).';
  });
};
