<template>
  <span class="clickable" @click="show = !show">
    <slot v-if="!show" name="normal-text"></slot>
    <slot v-if="show" name="hidden-text"></slot>
  </span>
</template>

<script>
export default {
  name: 'ToggleText',
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped></style>
