<template>
  <div class="jobs-page-holder">
    <div class="jobs">
      <div class="main-content-wrapper">
        <div class="page-header d-flex flex-row">
          <h1 class="m-0 flex-fill font-weight-bold">Promos</h1>
        </div>
        <div
          class="row"
          v-if="isLoading"
          style="display: flex; margin-top: 10px; justify-items: center; aligin-items: center"
        >
          <b-spinner></b-spinner>
        </div>

        <div class="row mobile-t" v-if="!isLoading">
          <b-table hover :items="promos">
            <template #cell(brand)="data">
              <div style="width: 120px" v-html="data.value"></div>
            </template>
            <template #cell(discountCode)="data">
              <div style="width: 120px" v-html="data.value"></div>
            </template>
            <template #cell(startDate)="data">
              <div style="width: 120px" v-html="data.value"></div>
            </template>
            <template #cell(discountRule)="data">
              <div style="width: 200px" v-html="data.value"></div>
            </template>
            <template #cell(endDate)="data">
              <div style="width: 150px" v-html="data.value"></div>
            </template>
            <template #cell(combinedWith)="data">
              <div style="width: 150px" v-html="data.value"></div>
            </template>
            <template #cell(notCombinedWith)="data">
              <div style="width: 150px" v-html="data.value"></div>
            </template>
            <template #cell(PDF)="data">
              <div style="width: 350px" v-html="data.value"></div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Promos',
  data() {
    return {
      isLoading: false,
      promos: [],
      fields: [
        'discountCode',
        'brand',
        'discountRule',
        'regionNote',
        'startDate',
        'endDate',
        'combinedWith',
        'notCombinedWith',
        'PDF',
      ],
    };
  },
  computed: {},
  created() {
    this.getPromos();
  },
  methods: {
    getPromos() {
      this.isLoading = true;
      axios
        .post(`${process.env.VUE_APP_WC_API_URL}/api/db/7d0bf0c38d3a42218256f103ecda5216?clear`)
        .then(response => {
          const getPromo = [];
          response.data.map(promo => {
            getPromo.push({
              discountCode: promo.discountCode,
              brand: promo.brand,
              startDate: new Date(promo.startDate).toDateString(),
              startDateTs: new Date(promo.startDate).getTime(),
              endDate: promo.endDate,
              regionNote: promo.regionNote,
              discountRule: promo.discountRule,
              combinedWith: promo.combinedWith,
              notCombinedWith: promo.notCombinedWith,
              PDF: promo['pDFs&Copies'],
            });
            return getPromo;
          });

          getPromo.sort((a, b) => {
            const keyA = a.startDateTs;
            const keyB = b.startDateTs;
            if (keyA >= keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          });

          getPromo.map(promo => {
            return this.promos.push({
              discountCode: promo.discountCode,
              brand: promo.brand,
              startDate: promo.startDate,
              endDate: promo.endDate,
              regionNote: promo.regionNote,
              discountRule: promo.discountRule,
              combinedWith: promo.combinedWith,
              notCombinedWith: promo.notCombinedWith,
              PDF: promo.PDF.replace('<a', '<a target="__blank" '),
            });
          });

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {},
};
</script>

<style>
@media only screen and (max-width: 600px) {
  .mobile-t {
    width: 100%;
    overflow-x: auto;
  }
}
</style>
