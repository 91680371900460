<template>
  <ValidationObserver v-slot="{ passes, failed }" ref="validator">
    <b-modal
      id="send-message-modal"
      size="xl"
      footer-class="mt-4"
      @show="handleShowModal"
      @hidden="handleHiddenModal"
    >
      <template v-slot:modal-header="{ close }">
        <b-container>
          <div class="modal-title">
            <h3 class="text-bold text-center">Send a Message</h3>
            <h6 class="text-muted text-center" v-show="customer.first_name || customer.last_name">
              TO {{ customerFullName | uppercase }}
            </h6>
            <button type="button" aria-label="Close" class="close" @click="close">
              <i class="di-remove-10"></i>
            </button>
          </div>
        </b-container>
      </template>
      <b-container class="px-5">
        <h5 class="mb-4">New Message</h5>
        <b-form>
          <section>
            <b-form-group label="Send message via" label-for="type" class="flex-fill">
              <ValidationProvider name="Delivery Type" rules="required" v-slot="validationContext">
                <b-form-radio-group
                  id="type"
                  v-model="form.type"
                  :options="deliveryTypes"
                  @change="handleDeliveryTypeChange"
                  buttons
                  text-field="name"
                  value-field="slug"
                  button-variant="white"
                  class="custom-radio-button radio-button-gray"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </b-form-group>
          </section>
          <section>
            <b-row>
              <b-col cols="6">
                <b-form-group v-if="isEmail" label="E-mail address" label-for="email">
                  <ValidationProvider
                    name="E-mail address"
                    rules="required|email"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="email"
                      type="email"
                      v-model="form.email"
                      placeholder="Please enter an e-mail address"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group v-if="isSms" label="Mobile phone" label-for="mobile">
                  <CustomTelInput name="Mobile" v-model="form.mobile" required />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Message template" label-for="templates">
                  <b-form-select
                    id="templates"
                    :options="templatesByType"
                    v-model="selectedTemplate"
                    @input="handleTemplateChange"
                    value-field="id"
                    text-field="template_name"
                    :disabled="!form.type"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="1" class="d-flex justify-content-center align-items-center pl-0">
                <b-spinner v-if="isLoading" small />
              </b-col>
            </b-row>
            <b-row v-if="isEmail">
              <b-col cols="6">
                <b-form-group label="Subject" label-for="subject">
                  <ValidationProvider
                    name="Subject"
                    rules="required|no_mustache"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="subject"
                      type="text"
                      v-model="form.subject"
                      placeholder="Please enter the subject of e-mail"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group label="Message" label-for="message">
              <ValidationProvider
                name="Message"
                rules="required|no_mustache"
                v-slot="validationContext"
              >
                <b-form-textarea
                  id="message"
                  v-model="form.message"
                  rows="4"
                  placeholder="Please choose a template or compose a message."
                  :disabled="!form.type"
                  spellcheck="true"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </ValidationProvider>
            </b-form-group>
          </section>
        </b-form>
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <b-container>
          <div class="d-flex justify-content-end px-4">
            <b-button variant="outline-secondary" @click="cancel()">Cancel</b-button>
            <b-button
              variant="primary"
              @click="passes(submit)"
              class="ml-3"
              :disabled="failed || isLoading"
            >
              <b-spinner v-if="isLoading" small />
              <span v-else>Send {{ selectedDeliveryType }}</span>
            </b-button>
          </div>
        </b-container>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import parseMustache from '@/support/parseMustache';
import apiTemplates from '@/api/templates';
import apiOrders from '@/api/orders';
import apiCustomers from '@/api/customers';
import CustomTelInput from '@/components/CustomTelInput.vue';

export default {
  name: 'SendMessageModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomTelInput,
  },
  data() {
    return {
      deliveryTypes: [
        { slug: 'sms', name: 'SMS' },
        { slug: 'email', name: 'Email' },
      ],
      templates: [],
      selectedTemplate: null,
      form: {
        email: '',
        mobile: '',
        type: null,
        subject: '',
        message: '',
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      selectedCustomer: state => state.customer.selectedCustomer,
      user: state => state.auth.user,
    }),
    ...mapGetters({
      selectedOrder: 'selectedOrder',
      locationSelectorRegions: 'locationSelectorRegions',
      selectedOutlet: 'auth/selectedOutlet',
    }),
    toWhere() {
      return this.selectedCustomer ? 'customer' : 'order';
    },
    isSms() {
      return this.form.type === 'sms';
    },
    isEmail() {
      return this.form.type === 'email';
    },
    customer() {
      if (this.toWhere === 'customer') {
        return this.selectedCustomer;
      }
      // customer || guest (used billing address for guest orders to fill customer data)
      return (
        this.selectedOrder?.customer || this.selectedOrder?.order_billing?.billing_address || {}
      );
    },
    outlet() {
      let outlet = {};
      if (this.toWhere === 'customer') {
        outlet = this.selectedOutlet;
      } else {
        outlet = this.selectedOrder?.origin_outlet;
      }

      return { ...outlet, address: this.getAddress(outlet?.address) };
    },
    regionId() {
      if (this.toWhere === 'customer') {
        return this.selectedCustomer.region.id || this.selectedCustomer.regions?.data[0].id;
      }

      return this.outlet?.region_id;
    },
    selectedDeliveryType() {
      return this.templatesByType.find(t => t.slug === this.form.type)?.name;
    },
    customerFullName() {
      return `${this.customer.first_name || ''} ${this.customer.last_name || ''}`;
    },
    templatesByType() {
      const defaultTemplate = { id: null, template_name: 'Please select', content_template: '' };
      const typeTemplates = this.templates.filter(t => t.type.slug === this.form.type);

      if (this.isLoading) {
        defaultTemplate.template_name = 'Loading...';
      } else if (typeTemplates.length === 0) {
        defaultTemplate.template_name = 'No template available';
      }

      return [defaultTemplate, ...typeTemplates];
    },
  },
  methods: {
    ...mapMutations({
      updateOrder: 'UPDATE_JOB_IN_JOBS',
      updateCustomer: 'customer/UPDATE_CUSTOMER_IN_CUSTOMERS',
      setSelectedCustomer: 'customer/SET_SELECTED_CUSTOMER',
    }),
    handleShowModal() {
      this.form.email = this.customer.email;
      this.form.mobile = this.customer.mobile;
      this.getTemplates();
    },
    handleHiddenModal() {
      // set initial values
      this.templates = [];
      this.selectedTemplate = null;
      this.isLoading = false;
      this.setSelectedCustomer(null);
      this.form = this.$options.data.apply(this).form;
    },
    async getTemplates() {
      this.isLoading = true;
      try {
        const response = await apiTemplates.getMessageTemplates(this.regionId);
        this.templates = response.data?.data;
      } catch (error) {
        this.$store.dispatch('showErrorAlert', error.response.data.error);
      }
      this.isLoading = false;
    },
    handleDeliveryTypeChange() {
      this.selectedTemplate = null;
    },
    handleTemplateChange(id) {
      const { content_template, subject } = this.templatesByType.find(t => t.id === id);
      const mustacheData = {
        customer: this.customer,
        outlet: this.outlet,
        staff: this.user,
      };

      this.form.subject = parseMustache(subject, mustacheData);
      this.form.message = parseMustache(content_template, mustacheData);
      this.validateFields();
    },
    validateFields() {
      this.$nextTick(() => {
        this.$refs.validator.validate();
      });
    },
    getAddress(address) {
      if (!address) return null;
      return [
        address.address1,
        address.address2,
        address.suburb,
        address.city,
        address.state,
        address.country,
      ]
        .filter(segment => segment)
        .join(', ');
    },
    async submit() {
      this.isLoading = true;

      const formData = {
        type: this.form.type,
        message: this.form.message,
        ...(this.isSms && {
          phone: this.form.mobile,
        }),
        ...(this.isEmail && {
          email: this.form.email,
          subject: this.form.subject,
          reply_to: this.outlet?.email,
        }),
      };

      try {
        if (this.toWhere === 'customer') {
          await this.sendMessageCustomer(this.selectedCustomer.id, formData);
        } else {
          await this.sendMessageOrder(this.selectedOrder.id, formData);
        }
        this.$store.dispatch('showSuccessAlert', 'Message sent successfully');
        this.$bvModal.hide('send-message-modal');
      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch('showErrorAlert', error.response.data.message);
      }
    },
    async sendMessageCustomer(id, data) {
      await apiCustomers.sendMessage(id, data);
      // increase notes count
      this.updateCustomer({
        ...this.selectedCustomer,
        notes: {
          data: [...this.selectedCustomer.notes.data, {}],
        },
      });
    },
    async sendMessageOrder(id, data) {
      await apiOrders.sendMessage(id, data);
      this.updateOrder({
        ...this.selectedOrder,
        notes_count: this.selectedOrder.notes_count + 1,
      });
    },
  },
};
</script>

<style scoped>
.form-control:disabled {
  opacity: 0.7;
}
.radio-button-gray >>> .btn {
  background-color: #f3f3f3;
}
.invalid {
  border: 1px solid red !important;
}
</style>
